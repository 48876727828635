import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_REVIEWS } from "redux/model/reviews/reviews/constants";
import { apiListReviews } from "api/review/reviewApi";
import { listReviewsFailed, listReviewsSuccess } from "redux/model/reviews/reviews/reviewsActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/allaroundmassage_frontend_common";


function* reviewsSaga() {
  yield all([
    fork(watchListReviews),
  ]);
}

// List Reviews
export function* watchListReviews() {
  yield takeEvery(LIST_REVIEWS, sagaListReviews);
}

export function* sagaListReviews() {
  try {
    const response = yield call(apiListReviews);

    yield put(listReviewsSuccess({ reviews: response.data }));

  } catch (e) {
    yield put(listReviewsFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default reviewsSaga;

import { all, call, delay, fork, put, takeLeading } from 'redux-saga/effects';
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/allaroundmassage_frontend_common";
import {
  CREATE_PRACTITIONER_REPORT,
  UPDATE_PRACTITIONER_REPORT
} from "redux/model/practitionerReports/practitionerReport/constants";
import { apiCreatePractitionerReport, apiUpdatePractitionerReport } from "api/practitionerReport/practitionerReportApi";
import {
  createPractitionerReportFailed,
  createPractitionerReportSuccess,
  initPractitionerReport,
  updatePractitionerReportFailed,
  updatePractitionerReportSuccess
} from "redux/model/practitionerReports/practitionerReport/practitionerReportActions";
import { toastError, toastSaved } from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeFirstLayerModal,
  startFirstLayerModalListening
} from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/modal/modalActions";


function* practitionerReportSaga() {
  yield all([
    fork(watchUpdatePractitionerReport),
    fork(watchCreatePractitionerReport),
  ]);
}

// Create PractitionerReport
export function* watchCreatePractitionerReport() {
  yield takeLeading(CREATE_PRACTITIONER_REPORT, sagaCreatePractitionerReport);
}

export function* sagaCreatePractitionerReport({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreatePractitionerReport, payload);

    yield put(createPractitionerReportSuccess({ current: response.data }));
    yield put(toastSaved({ name: 'Report' }));
    yield put(closeFirstLayerModal());
    yield put(startFirstLayerModalListening());
    yield put(initPractitionerReport());

  } catch (e) {
    yield put(createPractitionerReportFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update PractitionerReport
export function* watchUpdatePractitionerReport() {
  yield takeLeading(UPDATE_PRACTITIONER_REPORT, sagaUpdatePractitionerReport);
}

export function* sagaUpdatePractitionerReport({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdatePractitionerReport, payload);

    yield put(updatePractitionerReportSuccess({ current: response.data }));
    yield put(toastSaved({ name: 'Report' }));
    yield put(closeFirstLayerModal());
    yield put(startFirstLayerModalListening());
    yield put(initPractitionerReport());

  } catch (e) {
    yield put(updatePractitionerReportFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

export default practitionerReportSaga;


export const CREATE_INVOICE_API = {
  url: `/api/invoices`,
  method: 'post'
};

export const LIST_INVOICE_API = {
  url: `/api/invoices`,
  method: 'get',
}

export const FETCH_INVOICE_API = {
  url: `/api/invoices/:id`,
  method: 'get',
}

export const UPDATE_INVOICE_API = {
  url: `/api/invoices/:id`,
  method: 'put'
}

export const DELETE_INVOICE_API = {
  url: `/api/invoices/:id`,
  method: 'delete'
}

export const FETCH_NEW_INVOICE_API = {
  url: `/api/invoice/new`,
  method: 'get'
};

export const NOTIFY_PATIENT_INVOICE_API = {
  url: `/api/invoice/notify_patient/:id`,
  method: 'post'
};

export const PREVIEW_INVOICE_PDF_API = {
  url: `/api/invoice/pdf/:id`,
  method: 'get',
}

export const LIST_INVOICE_PATIENTS_API = {
  url: `/api/invoice/search_patients`,
  method: 'get',
};

export const CREATE_PRACTITIONER_REPORT_API = {
  url: `/api/practitioner_reports`,
  method: 'post'
};

export const LIST_PRACTITIONER_REPORT_API = {
  url: `/api/practitioner_reports`,
  method: 'get',
}

export const UPDATE_PRACTITIONER_REPORT_API = {
  url: `/api/practitioner_reports/:id`,
  method: 'put'
}

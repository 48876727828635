
export const CREATE_REVIEW_API = {
  url: `/api/reviews`,
  method: 'post'
};

export const LIST_REVIEW_API = {
  url: `/api/reviews`,
  method: 'get',
}

export const FETCH_REVIEW_API = {
  url: `/api/reviews/:id`,
  method: 'get',
}

export const FETCH_NEW_REVIEW_API = {
  url: `/api/review/new`,
  method: 'get',
}

export const UPDATE_REVIEW_API = {
  url: `/api/reviews/:id`,
  method: 'put'
}

export const DELETE_REVIEW_API = {
  url: `/api/reviews/:id`,
  method: 'delete'
}

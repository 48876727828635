import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import {
  generateErrorMessage,
  generateRoute,
  history,
  throwFrontError
} from "@tmatt-tech/allaroundmassage_frontend_common";
import {
  ASSIGN_CONSULTATION_AREAS,
  ASSIGN_CONSULTATION_CONDITIONS,
  CREATE_CONSULTATION,
  DELETE_CONSULTATION,
  FETCH_CONSULTATION,
  FETCH_NEW_CONSULTATION,
  LINK_CONSULTATION,
  LIST_CONSULTATION_PATIENTS,
  UPDATE_CONSULTATION
} from "redux/model/consultations/consultation/constants";
import {
  apiCreateConsultation,
  apiDeleteConsultation,
  apiFetchConsultation,
  apiFetchNewConsultation,
  apiLinkConsultation,
  apiListConsultationPatient,
  apiUpdateConsultation
} from "api/consultation/consultationApi";
import {
  assignConsultationAfterTreatment,
  assignConsultationConditions,
  assignConsultationTreatments,
  createConsultationFailed,
  createConsultationSuccess,
  deleteConsultationFailed,
  deleteConsultationSuccess,
  fetchConsultationFailed,
  fetchConsultationSuccess,
  initConsultation,
  linkConsultationFailed,
  linkConsultationSuccess,
  listConsultationPatientsFailed,
  listConsultationPatientsSuccess,
  updateConsultationFailed,
  updateConsultationSuccess
} from "redux/model/consultations/consultation/consultationActions";
import {
  toastDefault,
  toastDeleted,
  toastError,
  toastSaved
} from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeFirstLayerModal,
  closeSecondLayerModal,
  startFirstLayerModalListening,
  startSecondLayerModalListening
} from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/modal/modalActions";
import { EDIT_CONSULTATION_PATH } from "routes/consultation/constants";
import { listPatientsSuccess } from "redux/model/patients/patients/patientsActions";
import _ from 'lodash';
import { MASSAGE_REFERENCE } from "pages/consultations/constants/constants";
import { listPractitionersSuccess } from "redux/model/practitioners/practitioners/practitionersActions";
import { listProvidersSuccess } from "redux/model/providers/providers/providersActions";
import { listProviderNumbersSuccess } from "redux/model/providerNumbers/providerNumbers/providerNumbersActions";


function* consultationSaga() {
  yield all([
    fork(watchFetchConsultation),
    fork(watchFetchNewConsultation),
    fork(watchUpdateConsultation),
    fork(watchCreateConsultation),
    fork(watchDeleteConsultation),
    fork(watchAssignConsultationAreas),
    fork(watchAssignConsultationConditions),
    fork(watchLinkConsultation),
    fork(watchListConsultationPatients),
  ]);
}

// list consultation patients
export function* watchListConsultationPatients() {
  yield takeEvery(LIST_CONSULTATION_PATIENTS, sagaListConsultationPatients);
}

export function *sagaListConsultationPatients({ payload }) {
  try {
    const response = yield call(apiListConsultationPatient, payload);
    const { patients } = response.data;
    yield put(listPatientsSuccess({ patients }));
    yield put(listConsultationPatientsSuccess({}));

    // 使用模板字符串插入 patients 数量
    yield put(toastDefault({ message: `${patients.length} patients loaded.` }));

  } catch (e) {
    yield put(listConsultationPatientsFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Fetch Consultation
export function* watchFetchConsultation() {
  yield takeEvery(FETCH_CONSULTATION, sagaFetchConsultation);
}

export function* sagaFetchConsultation({ payload }) {
  try {
    const response = yield call(apiFetchConsultation, payload);

    const { patients, consultation, practitioners, providers, providerNumbers } = response.data;
    yield put(listPatientsSuccess({ patients }));
    yield put(listPractitionersSuccess({ practitioners }));
    yield put(fetchConsultationSuccess({ consultation }));
    yield put(listProvidersSuccess({ providers }));
    yield put(listProviderNumbersSuccess({ providerNumbers }));

  } catch (e) {
    yield put(fetchConsultationFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Fetch New Consultation
export function* watchFetchNewConsultation() {
  yield takeEvery(FETCH_NEW_CONSULTATION, sagaFetchNewConsultation);
}

export function* sagaFetchNewConsultation({ payload }) {
  try {
    // yield delay(SAGA_WAITING_TIME * 4);
    const response = yield call(apiFetchNewConsultation, payload);

    const { patients, consultation, practitioners, providers, providerNumbers } = response.data;
    // const { practitioners, consultation, providers, providerNumbers } = response.data;
    yield put(listPatientsSuccess({ patients }));
    yield put(listPractitionersSuccess({ practitioners }));
    yield put(fetchConsultationSuccess({ consultation }));
    yield put(listProvidersSuccess({ providers }));
    yield put(listProviderNumbersSuccess({ providerNumbers }));


  } catch (e) {
    yield put(fetchConsultationFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create Consultation
export function* watchCreateConsultation() {
  yield takeLeading(CREATE_CONSULTATION, sagaCreateConsultation);
}

export function* sagaCreateConsultation({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateConsultation, payload);

    yield put(createConsultationSuccess({ consultation: response.data }));
    yield put(toastSaved({ name: 'consultation' }));
    history.push(generateRoute(EDIT_CONSULTATION_PATH, response.data.id));
    yield put(initConsultation());


  } catch (e) {
    yield put(createConsultationFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update Consultation
export function* watchUpdateConsultation() {
  yield takeLeading(UPDATE_CONSULTATION, sagaUpdateConsultation);
}

export function* sagaUpdateConsultation({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateConsultation, payload);

    yield put(updateConsultationSuccess({ consultation: response.data }));
    yield put(toastSaved({ name: 'consultation' }));

  } catch (e) {
    yield put(updateConsultationFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete Consultation
export function* watchDeleteConsultation() {
  yield takeLeading(DELETE_CONSULTATION, sagaDeleteConsultation);
}

export function* sagaDeleteConsultation({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeleteConsultation, payload);

    yield put(deleteConsultationSuccess({ consultation: response.data }));
    yield put(toastDeleted({ name: 'consultation' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deleteConsultationFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Assign Consultation Areas
export function* watchAssignConsultationAreas() {
  yield takeEvery(ASSIGN_CONSULTATION_AREAS, sagaAssignConsultationAreas);
}

export function* sagaAssignConsultationAreas({ payload }) {
  try {
    yield put(assignConsultationConditions({ conditions: [] }))

  } catch (e) {
  }
}

// Assign Consultation Conditions
export function* watchAssignConsultationConditions() {
  yield takeEvery(ASSIGN_CONSULTATION_CONDITIONS, sagaAssignConsultationConditions);
}

export function* sagaAssignConsultationConditions({ payload }) {
  try {
    const { conditions } = payload;

    const references = _.chain(Object.values(MASSAGE_REFERENCE))
      .flatten()
      .filter((reference) => {
        return _.includes(conditions, reference.conditions)
      })
      .value()

    const treatments = _.chain(references)
      .map('treatment')
      .flatten()
      .uniq()
      .value()

    const afterTreatment = _.chain(references)
      .map('afterTreatment')
      .flatten()
      .uniq()
      .value()


    yield put(assignConsultationTreatments({ treatments }))
    yield put(assignConsultationAfterTreatment({ afterTreatment }))

  } catch (e) {
    console.log('error: ', e);
  }
}

// Link Consultation
export function* watchLinkConsultation() {
  yield takeLeading(LINK_CONSULTATION, sagaLinkConsultation);
}

export function* sagaLinkConsultation({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiLinkConsultation, payload);

    yield put(linkConsultationSuccess(response.data));
    yield put(toastDefault({ message: 'Consultation has been linked' }));

    yield put(startFirstLayerModalListening());
    yield put(closeFirstLayerModal());

  } catch (e) {
    yield put(linkConsultationFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

export default consultationSaga;

import _ from 'lodash';
import {
  CREATE_CONSULTATION_SUCCESS,
  DELETE_CONSULTATION_SUCCESS, LINK_CONSULTATION_SUCCESS,
  UPDATE_CONSULTATION_SUCCESS
} from "redux/model/consultations/consultation/constants";
import {
  LIST_CONSULTATIONS,
  LIST_CONSULTATIONS_FAILED,
  LIST_CONSULTATIONS_SUCCESS
} from "redux/model/consultations/consultations/constants";

const INIT_STATE = {
  consultations: {},
  totalSize: 0,
  loading: true,
  error: null,
};

const consultationsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_CONSULTATIONS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_CONSULTATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        consultations: _.mapKeys(action.payload.consultations, 'id'),
        totalSize: action.payload.totalSize || 0,
      };

    case LIST_CONSULTATIONS_FAILED:
      return {
        ...state,
        consultations: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_CONSULTATION_SUCCESS:
      return {
        ...state,
        consultations: {
          [action.payload.consultation.id]: action.payload.consultation,
          ...state.consultations,
        },
      };

    case UPDATE_CONSULTATION_SUCCESS:
      return {
        ...state,
        consultations: {
          ...state.consultations,
          [action.payload.consultation.id]: action.payload.consultation,
        },
      };

    case DELETE_CONSULTATION_SUCCESS:
      return {
        ...state,
        consultations: _.omit(state.consultations, action.payload.consultation.id)
      };

    case LINK_CONSULTATION_SUCCESS:
      return {
        ...state,
        consultations: _.mapKeys(
          _.map(Object.values(state.consultations), (consultation) => {
            if (consultation.id === action.payload.linkedConsultationId) {
              return { ...consultation, practitionerReportItemId: action.payload.practitionerReportItemId };
            }

            if (_.includes(action.payload.unlinkedConsultationIds, consultation.id)) {
              return { ...consultation, practitionerReportItemId: null }
            }

            return consultation;
          }),
          'id'
        ),
      }

    default:
      return { ...state };
  }
};

export default consultationsReducer;

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_INVOICES } from "redux/model/invoices/invoices/constants";
import { apiListInvoices } from "api/invoice/invoiceApi";
import { listInvoicesFailed, listInvoicesSuccess } from "redux/model/invoices/invoices/invoicesActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/allaroundmassage_frontend_common";


function* invoicesSaga() {
  yield all([
    fork(watchListInvoices),
  ]);
}

// List Invoices
export function* watchListInvoices() {
  yield takeEvery(LIST_INVOICES, sagaListInvoices);
}

export function* sagaListInvoices() {
  try {
    const response = yield call(apiListInvoices);

    yield put(listInvoicesSuccess({ invoices: response.data }));

  } catch (e) {
    yield put(listInvoicesFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default invoicesSaga;

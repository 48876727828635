import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_NOTICES } from "redux/model/notices/notices/constants";
import { apiListNotices } from "api/notice/noticeApi";
import { listNoticesFailed, listNoticesSuccess } from "redux/model/notices/notices/noticesActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/allaroundmassage_frontend_common";


function* noticesSaga() {
  yield all([
    fork(watchListNotices),
  ]);
}

// List Notices
export function* watchListNotices() {
  yield takeEvery(LIST_NOTICES, sagaListNotices);
}

export function* sagaListNotices() {
  try {
    const response = yield call(apiListNotices);

    yield put(listNoticesSuccess({ notices: response.data }));

  } catch (e) {
    yield put(listNoticesFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default noticesSaga;

import {
  FETCH_REVIEW,
  FETCH_REVIEW_FAILED,
  FETCH_REVIEW_SUCCESS,
  INIT_REVIEW
} from "redux/model/reviews/review/constants";

const INIT_STATE = {
  review: {
    emergency: {},
    privateHealthFund: {},
  },
  loading: true,
  error: null,
};

const reviewReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_REVIEW:
      return INIT_STATE;

    case FETCH_REVIEW:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        review: action.payload.review,
      };

    case FETCH_REVIEW_FAILED:
      return {
        ...state,
        review: {},
        loading: false,
        error: action.payload.error,
      };

    default:
      return { ...state };
  }
};

export default reviewReducer;

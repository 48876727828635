
export const CREATE_CONSULTATION_API = {
  url: `/api/consultations`,
  method: 'post'
};

export const LIST_CONSULTATION_API = {
  url: `/api/clinic/list_consultations`,
  method: 'get',
}

export const FETCH_CONSULTATION_API = {
  url: `/api/consultations/:id`,
  method: 'get',
}

export const UPDATE_CONSULTATION_API = {
  url: `/api/consultations/:id`,
  method: 'put'
}

export const DELETE_CONSULTATION_API = {
  url: `/api/consultations/:id`,
  method: 'delete'
}

export const FETCH_NEW_CONSULTATION_API = {
  url: `/api/consultation/new`,
  method: 'get'
};

export const PREVIEW_CONSULTATION_PDF_API = {
  url: `/api/consultation/pdf/:id`,
  method: 'get',
};

export const LINK_CONSULTATION_API = {
  url: `/api/consultation/link/:id`,
  method: 'patch',
};


export const LIST_CONSULTATION_PATIENTS_API = {
  url: `/api/consultation/search_patients`,
  method: 'get',
};
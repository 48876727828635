import { ERROR_FORM_SURVEY, LOADING_FORM_SURVEY, RESET_FORM_SURVEY } from "redux/ui/form/survey/constants";
import {
  CREATE_SURVEY,
  CREATE_SURVEY_FAILED,
  CREATE_SURVEY_SUCCESS,
  UPDATE_SURVEY, UPDATE_SURVEY_FAILED, UPDATE_SURVEY_SUCCESS
} from "redux/model/surveys/survey/constants";


const INIT_STATE = {
  loading: false,
  error: null,
};

const formSurveyReducer = (state = INIT_STATE, action) => {
  switch (action.type) {

    case LOADING_FORM_SURVEY:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ERROR_FORM_SURVEY:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case RESET_FORM_SURVEY:
      return {
        ...state,
        loading: false,
        error: null
      };

    case CREATE_SURVEY:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_SURVEY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_SURVEY_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_SURVEY:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_SURVEY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case UPDATE_SURVEY_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    default:
      return { ...state };
  }
};

export default formSurveyReducer;

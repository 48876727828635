import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import {
  generateErrorMessage,
  generateRoute,
  history,
  throwFrontError
} from "@tmatt-tech/allaroundmassage_frontend_common";
import {
  CREATE_SURVEY,
  DELETE_SURVEY,
  FETCH_NEW_SURVEY,
  FETCH_SURVEY,
  UPDATE_SURVEY,
  LINK_SURVEY,
} from "redux/model/surveys/survey/constants";
import {
  apiCreateSurvey,
  apiDeleteSurvey,
  apiFetchNewSurvey,
  apiFetchSurvey,
  apiUpdateSurvey,
  apiLinkSurvey
} from "api/survey/surveyApi";
import {
  createSurveyFailed, createSurveySuccess,
  deleteSurveyFailed, deleteSurveySuccess,
  fetchSurveyFailed, fetchSurveySuccess,
  initSurvey,
  updateSurveyFailed, updateSurveySuccess,
  linkSurveySuccess, linkSurveyFailed,
} from "redux/model/surveys/survey/surveyActions";
import {
  toastDefault,
  toastDeleted,
  toastError,
  toastSaved
} from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/toast/toastActions";

import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeFirstLayerModal,
  closeSecondLayerModal,
  startFirstLayerModalListening, startSecondLayerModalListening
} from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/modal/modalActions";
import { INDEX_SURVEY_PATH } from "routes/survey/constants";
import { listPractitionersSuccess } from "redux/model/practitioners/practitioners/practitionersActions";


function* surveySaga() {
  yield all([
    fork(watchFetchSurvey),
    fork(watchFetchNewSurvey),
    fork(watchUpdateSurvey),
    fork(watchCreateSurvey),
    fork(watchDeleteSurvey),
    fork(watchLinkSurvey),
  ]);
}

// Fetch Survey
export function* watchFetchSurvey() {
  yield takeEvery(FETCH_SURVEY, sagaFetchSurvey);
}

export function* sagaFetchSurvey({ payload }) {
  try {
    const response = yield call(apiFetchSurvey, payload);
    const { practitioners, survey } = response.data;

    yield put(listPractitionersSuccess({ practitioners }));
    yield put(fetchSurveySuccess({ survey }));

  } catch (e) {
    yield put(fetchSurveyFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Fetch New Survey
export function* watchFetchNewSurvey() {
  yield takeEvery(FETCH_NEW_SURVEY, sagaFetchNewSurvey);
}

export function* sagaFetchNewSurvey({ payload }) {
  try {
    const response = yield call(apiFetchNewSurvey, payload);
    const { practitioners, survey } = response.data;

    yield put(listPractitionersSuccess({ practitioners }));
    yield put(fetchSurveySuccess({ survey }));

  } catch (e) {
    yield put(fetchSurveyFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create Survey
export function* watchCreateSurvey() {
  yield takeLeading(CREATE_SURVEY, sagaCreateSurvey);
}

export function* sagaCreateSurvey({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateSurvey, payload);

    yield put(createSurveySuccess({ survey: response.data }));
    yield put(toastSaved({ name: 'Survey' }));
    history.push(generateRoute(INDEX_SURVEY_PATH));
    yield put(initSurvey());


  } catch (e) {
    yield put(createSurveyFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update Survey
export function* watchUpdateSurvey() {
  yield takeLeading(UPDATE_SURVEY, sagaUpdateSurvey);
}

export function* sagaUpdateSurvey({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateSurvey, payload);

    yield put(updateSurveySuccess({ survey: response.data }));
    yield put(toastSaved({ name: 'Survey' }));
    history.push(generateRoute(INDEX_SURVEY_PATH));
    yield put(initSurvey());

  } catch (e) {
    yield put(updateSurveyFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete Survey
export function* watchDeleteSurvey() {
  yield takeLeading(DELETE_SURVEY, sagaDeleteSurvey);
}

export function* sagaDeleteSurvey({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeleteSurvey, payload);

    yield put(deleteSurveySuccess({ survey: response.data }));
    yield put(toastDeleted({ name: 'Survey' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deleteSurveyFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Link Survey
export function* watchLinkSurvey() {
  yield takeLeading(LINK_SURVEY, sagaLinkSurvey);
}

export function* sagaLinkSurvey({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiLinkSurvey, payload);

    yield put(linkSurveySuccess({ survey: response.data }));
    yield put(toastDefault({ message: 'Survey has been linked' }));

    yield put(startFirstLayerModalListening());
    yield put(closeFirstLayerModal());

  } catch (e) {
    yield put(linkSurveyFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

export default surveySaga;

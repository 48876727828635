import {
  LIST_ASSISTANT_REPORTS,
  LIST_ASSISTANT_REPORTS_FAILED,
  LIST_ASSISTANT_REPORTS_SUCCESS
} from "redux/model/assistantReports/assistantReports/constants";

export const listAssistantReports = () => ({
  type: LIST_ASSISTANT_REPORTS,
  payload: {}
});

export const listAssistantReportsSuccess = ({ assistantReports }) => ({
  type: LIST_ASSISTANT_REPORTS_SUCCESS,
  payload: { assistantReports }
});

export const listAssistantReportsFailed = ({ error }) => ({
  type: LIST_ASSISTANT_REPORTS_FAILED,
  payload: { error }
});

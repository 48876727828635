import {
  CREATE_SURVEY,
  CREATE_SURVEY_FAILED,
  CREATE_SURVEY_SUCCESS,
  DELETE_SURVEY,
  DELETE_SURVEY_FAILED,
  DELETE_SURVEY_SUCCESS, FETCH_NEW_SURVEY,
  FETCH_SURVEY,
  FETCH_SURVEY_FAILED,
  FETCH_SURVEY_SUCCESS,
  INIT_SURVEY,
  UPDATE_SURVEY,
  UPDATE_SURVEY_FAILED,
  UPDATE_SURVEY_SUCCESS,
  LINK_SURVEY,
  LINK_SURVEY_FAILED,
  LINK_SURVEY_SUCCESS,
} from "redux/model/surveys/survey/constants";

export const initSurvey = () => ({
  type: INIT_SURVEY,
  payload: {}
});

export const createSurvey = ({ survey }) => ({
  type: CREATE_SURVEY,
  payload: { survey }
});

export const createSurveySuccess = ({ survey }) => ({
  type: CREATE_SURVEY_SUCCESS,
  payload: { survey }
});

export const createSurveyFailed = ({ error }) => ({
  type: CREATE_SURVEY_FAILED,
  payload: { error }
});

export const fetchSurvey = ({ surveyId }) => ({
  type: FETCH_SURVEY,
  payload: { surveyId }
});

export const fetchNewSurvey = ({ practitionerReportItemId }) => ({
  type: FETCH_NEW_SURVEY,
  payload: { practitionerReportItemId }
});

export const fetchSurveySuccess = ({ survey }) => ({
  type: FETCH_SURVEY_SUCCESS,
  payload: { survey }
});

export const fetchSurveyFailed = ({ error }) => ({
  type: FETCH_SURVEY_FAILED,
  payload: { error }
});

export const updateSurvey = ({ survey }) => ({
  type: UPDATE_SURVEY,
  payload: { survey }
});

export const updateSurveySuccess = ({ survey }) => ({
  type: UPDATE_SURVEY_SUCCESS,
  payload: { survey }
});

export const updateSurveyFailed = ({ error }) => ({
  type: UPDATE_SURVEY_FAILED,
  payload: { error }
});

export const deleteSurvey = ({ survey }) => ({
  type: DELETE_SURVEY,
  payload: { survey }
});

export const deleteSurveySuccess = ({ survey }) => ({
  type: DELETE_SURVEY_SUCCESS,
  payload: { survey }
});

export const deleteSurveyFailed = ({ error }) => ({
  type: DELETE_SURVEY_FAILED,
  payload: { error }
});

export const linkSurvey = ({ survey, practitionerReportItemId }) => ({
  type: LINK_SURVEY,
  payload: { survey, practitionerReportItemId }
});

export const linkSurveySuccess = ({ survey }) => ({
  type: LINK_SURVEY_SUCCESS,
  payload: { survey }
});

export const linkSurveyFailed = ({ error }) => ({
  type: LINK_SURVEY_FAILED,
  payload: { error }
});

import {
  ASSIGN_CONSULTATION_ADVICE_TO_CLIENT,
  ASSIGN_CONSULTATION_AFTER_TREATMENT,
  ASSIGN_CONSULTATION_AREAS,
  ASSIGN_CONSULTATION_ARM_REGION_APPLICATION,
  ASSIGN_CONSULTATION_ARM_REGION_DEEP_MUSCLES,
  ASSIGN_CONSULTATION_ARM_REGION_DEEP_TISSUES_TECHNIQUES,
  ASSIGN_CONSULTATION_ARM_REGION_SUPERFICIAL_MUSCLES,
  ASSIGN_CONSULTATION_ARM_REGION_WARM_UP,
  ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_PALPATION_TEMPERATURE,
  ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_PALPATION_TENDERNESS,
  ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_PALPATION_TENSION,
  ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_PALPATION_TEXTURE,
  ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_ROM_ACTIVE,
  ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_ROM_PASSIVE,
  ASSIGN_CONSULTATION_ASSESSMENT_POSTURE_PELVIS,
  ASSIGN_CONSULTATION_ASSESSMENT_POSTURE_SHOULDER,
  ASSIGN_CONSULTATION_ASSESSMENT_POSTURE_SPINE,
  ASSIGN_CONSULTATION_BACK_REGION_APPLICATION,
  ASSIGN_CONSULTATION_BACK_REGION_DEEP_MUSCLES,
  ASSIGN_CONSULTATION_BACK_REGION_DEEP_TISSUES_TECHNIQUES,
  ASSIGN_CONSULTATION_BACK_REGION_SUPERFICIAL_MUSCLES,
  ASSIGN_CONSULTATION_BACK_REGION_WARM_UP,
  ASSIGN_CONSULTATION_BODY_FOCUS_IMAGE,
  ASSIGN_CONSULTATION_CARDIOVASCULAR,
  ASSIGN_CONSULTATION_COMPLAINT_AREA,
  ASSIGN_CONSULTATION_COMPLAINT_FEELS_BETTER_WITH,
  ASSIGN_CONSULTATION_COMPLAINT_FREQUENCY_OF_PAIN,
  ASSIGN_CONSULTATION_COMPLAINT_PAIN_DISCOMFORT_IS_MADE_WORSE_BY,
  ASSIGN_CONSULTATION_COMPLAINT_PAIN_LEVEL,
  ASSIGN_CONSULTATION_COMPLAINT_TYPE_OF_PAIN,
  ASSIGN_CONSULTATION_CONDITIONS,
  ASSIGN_CONSULTATION_DATETIME,
  ASSIGN_CONSULTATION_DRAPING_TECHNIQUE,
  ASSIGN_CONSULTATION_DURATION,
  ASSIGN_CONSULTATION_HEAD_AND_NECK_APPLICATION,
  ASSIGN_CONSULTATION_HEAD_AND_NECK_DEEP_MUSCLES,
  ASSIGN_CONSULTATION_HEAD_AND_NECK_DEEP_TISSUES_TECHNIQUES,
  ASSIGN_CONSULTATION_HEAD_AND_NECK_SUPERFICIAL_MUSCLES,
  ASSIGN_CONSULTATION_HEAD_AND_NECK_WARM_UP,
  ASSIGN_CONSULTATION_HERBS_OR_VITAMINS,
  ASSIGN_CONSULTATION_HICAPS_COPY_GIVEN_NOTE,
  ASSIGN_CONSULTATION_HIPS_APPLICATION,
  ASSIGN_CONSULTATION_HIPS_DEEP_MUSCLES,
  ASSIGN_CONSULTATION_HIPS_DEEP_TISSUES_TECHNIQUES,
  ASSIGN_CONSULTATION_HIPS_SUPERFICIAL_MUSCLES,
  ASSIGN_CONSULTATION_HIPS_WARM_UP,
  ASSIGN_CONSULTATION_INSURANCE_COMPANY,
  ASSIGN_CONSULTATION_IS_HICAPS_COPY_GIVEN,
  ASSIGN_CONSULTATION_IS_RECEIPT_GIVEN,
  ASSIGN_CONSULTATION_LUMBAR_REGION_APPLICATION,
  ASSIGN_CONSULTATION_LUMBAR_REGION_DEEP_MUSCLES,
  ASSIGN_CONSULTATION_LUMBAR_REGION_DEEP_TISSUES_TECHNIQUES,
  ASSIGN_CONSULTATION_LUMBAR_REGION_SUPERFICIAL_MUSCLES,
  ASSIGN_CONSULTATION_LUMBAR_REGION_WARM_UP,
  ASSIGN_CONSULTATION_MATERIALS,
  ASSIGN_CONSULTATION_MEDICATION,
  ASSIGN_CONSULTATION_MUSCULOSKELETAL,
  ASSIGN_CONSULTATION_NERVOUS,
  ASSIGN_CONSULTATION_NUMBNESS_OR_TINGLING,
  ASSIGN_CONSULTATION_OTHER_CONDITIONS,
  ASSIGN_CONSULTATION_OTHER_CONDITIONS_NOTE,
  ASSIGN_CONSULTATION_PAIN_OR_TENDERNESS,
  ASSIGN_CONSULTATION_PATIENT, ASSIGN_CONSULTATION_PLAN,
  ASSIGN_CONSULTATION_PRACTITIONER,
  ASSIGN_CONSULTATION_PROVIDER,
  ASSIGN_CONSULTATION_PROVIDER_NUMBER,
  ASSIGN_CONSULTATION_REACTIONS,
  ASSIGN_CONSULTATION_REACTIONS_NOTE,
  ASSIGN_CONSULTATION_RECEIPT_NUMBER, ASSIGN_CONSULTATION_REPEAT_TIMES, ASSIGN_CONSULTATION_REPEAT_WEEK_OR_MONTH,
  ASSIGN_CONSULTATION_RESPIRATORY,
  ASSIGN_CONSULTATION_SERVICES_AND_GOODS,
  ASSIGN_CONSULTATION_SHOULDERS_APPLICATION,
  ASSIGN_CONSULTATION_SHOULDERS_DEEP_MUSCLES,
  ASSIGN_CONSULTATION_SHOULDERS_DEEP_TISSUES_TECHNIQUES,
  ASSIGN_CONSULTATION_SHOULDERS_SUPERFICIAL_MUSCLES,
  ASSIGN_CONSULTATION_SHOULDERS_WARM_UP,
  ASSIGN_CONSULTATION_SIGNED_DATE,
  ASSIGN_CONSULTATION_STIFFNESS,
  ASSIGN_CONSULTATION_SUGGESTIONS,
  ASSIGN_CONSULTATION_SWELLING,
  ASSIGN_CONSULTATION_THIGH_REGION, ASSIGN_CONSULTATION_THIGH_REGION_APPLICATION,
  ASSIGN_CONSULTATION_THIGH_REGION_DEEP_MUSCLES, ASSIGN_CONSULTATION_THIGH_REGION_DEEP_TISSUES_TECHNIQUES,
  ASSIGN_CONSULTATION_THIGH_REGION_WARM_UP,
  ASSIGN_CONSULTATION_TIME_FROM,
  ASSIGN_CONSULTATION_TIME_TO,
  ASSIGN_CONSULTATION_TREATMENTS,
  ASSIGN_CONSULTATION_COMPLAINT_NOTES,
  ASSIGN_CONSULTATION_TREATMENT_PLAN_OTHERS,
  FETCH_CONSULTATION,
  FETCH_CONSULTATION_FAILED,
  FETCH_CONSULTATION_SUCCESS,
  INIT_CONSULTATION,
  LIST_CONSULTATION_PATIENTS,
  LIST_CONSULTATION_PATIENTS_SUCCESS,
  LIST_CONSULTATION_PATIENTS_FAILED
} from "redux/model/consultations/consultation/constants";

const INIT_STATE = {
  consultation: {},
  loading: true,
  error: null,
};

const consultationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_CONSULTATION:
      return INIT_STATE;

    case FETCH_CONSULTATION:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_CONSULTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        consultation: action.payload.consultation,
      };

    case FETCH_CONSULTATION_FAILED:
      return {
        ...state,
        consultation: {},
        loading: false,
        error: action.payload.error,
      };
    
    case LIST_CONSULTATION_PATIENTS:
      return {
        ...state,
        loading: true,
        error: null,
      }
    
    case LIST_CONSULTATION_PATIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      }

    case LIST_CONSULTATION_PATIENTS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    case ASSIGN_CONSULTATION_DATETIME:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          datetime: action.payload.datetime,
        },
      };

    case ASSIGN_CONSULTATION_PATIENT:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          patientId: action.payload.patient.systemId,
        },
      };

    case ASSIGN_CONSULTATION_MUSCULOSKELETAL:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          musculoskeletal: action.payload.musculoskeletal,
        },
      };

    case ASSIGN_CONSULTATION_NERVOUS:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          nervous: action.payload.nervous,
        },
      };

    case ASSIGN_CONSULTATION_CARDIOVASCULAR:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          cardiovascular: action.payload.cardiovascular,
        },
      };

    case ASSIGN_CONSULTATION_RESPIRATORY:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          respiratory: action.payload.respiratory,
        },
      };

    case ASSIGN_CONSULTATION_REACTIONS:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          reactions: action.payload.reactions,
        },
      };

    case ASSIGN_CONSULTATION_REACTIONS_NOTE:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          reactionsNote: action.payload.reactionsNote,
        },
      };

    case ASSIGN_CONSULTATION_OTHER_CONDITIONS:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          otherConditions: action.payload.otherConditions,
        },
      };

    case ASSIGN_CONSULTATION_OTHER_CONDITIONS_NOTE:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          otherConditionsNote: action.payload.otherConditionsNote,
        },
      };

    case ASSIGN_CONSULTATION_MEDICATION:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          medication: action.payload.medication,
        },
      };

    case ASSIGN_CONSULTATION_PAIN_OR_TENDERNESS:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          painOrTenderness: action.payload.painOrTenderness,
        },
      };

    case ASSIGN_CONSULTATION_STIFFNESS:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          stiffness: action.payload.stiffness,
        },
      };

    case ASSIGN_CONSULTATION_NUMBNESS_OR_TINGLING:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          numbnessOrTingling: action.payload.numbnessOrTingling,
        },
      };

    case ASSIGN_CONSULTATION_COMPLAINT_NOTES:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          complaintNotes: action.payload.complaintNotes,
        }
      };

    case ASSIGN_CONSULTATION_TREATMENT_PLAN_OTHERS:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentPlanOthers: action.payload.treatmentPlanOthers,
        }
      };

    case ASSIGN_CONSULTATION_SWELLING:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          swelling: action.payload.swelling,
        },
      };

    case ASSIGN_CONSULTATION_BODY_FOCUS_IMAGE:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          bodyFocusImage: action.payload.bodyFocusImage,
        },
      };

    case ASSIGN_CONSULTATION_SIGNED_DATE:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          signedDate: action.payload.signedDate,
        },
      };

    case ASSIGN_CONSULTATION_AREAS:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          areas: action.payload.areas,
        },
      };

    case ASSIGN_CONSULTATION_TREATMENTS:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatments: action.payload.treatments,
        },
      };

    case ASSIGN_CONSULTATION_CONDITIONS:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          conditions: action.payload.conditions,
        },
      };

    case ASSIGN_CONSULTATION_HERBS_OR_VITAMINS:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          herbsOrVitamins: action.payload.herbsOrVitamins,
        },
      };

    case ASSIGN_CONSULTATION_MATERIALS:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          materials: action.payload.materials,
        },
      };

    case ASSIGN_CONSULTATION_AFTER_TREATMENT:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          afterTreatment: action.payload.afterTreatment,
        },
      };

    case ASSIGN_CONSULTATION_SUGGESTIONS:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          suggestions: action.payload.suggestions,
        },
      };

    case ASSIGN_CONSULTATION_PROVIDER:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          provider: action.payload.provider,
        },
      };

    case ASSIGN_CONSULTATION_INSURANCE_COMPANY:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          insuranceCompany: action.payload.insuranceCompany,
        },
      };

    case ASSIGN_CONSULTATION_PROVIDER_NUMBER:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          providerNumber: action.payload.providerNumber,
        },
      };

    case ASSIGN_CONSULTATION_DURATION:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          duration: action.payload.duration,
        },
      };

    case ASSIGN_CONSULTATION_PRACTITIONER:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          practitionerId: action.payload.practitioner.id,
        },
      };

    case ASSIGN_CONSULTATION_TIME_FROM:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          timeFrom: action.payload.timeFrom,
        },
      };

    case ASSIGN_CONSULTATION_TIME_TO:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          timeTo: action.payload.timeTo,
        },
      };

    case ASSIGN_CONSULTATION_SERVICES_AND_GOODS:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          servicesAndGoods: action.payload.servicesAndGoods,
        },
      };

    case ASSIGN_CONSULTATION_IS_HICAPS_COPY_GIVEN:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          isHicapsCopyGiven: action.payload.isHicapsCopyGiven,
        },
      };

    case ASSIGN_CONSULTATION_HICAPS_COPY_GIVEN_NOTE:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          hicapsCopyGivenNote: action.payload.hicapsCopyGivenNote,
        },
      };

    case ASSIGN_CONSULTATION_IS_RECEIPT_GIVEN:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          isReceiptGiven: action.payload.isReceiptGiven,
        },
      };

    case ASSIGN_CONSULTATION_RECEIPT_NUMBER:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          receiptNumber: action.payload.receiptNumber,
        },
      };

    case ASSIGN_CONSULTATION_COMPLAINT_AREA:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          complaintArea: action.payload.complaintArea,
        }
      };

    case ASSIGN_CONSULTATION_COMPLAINT_TYPE_OF_PAIN:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          complaintTypeOfPain: action.payload.complaintTypeOfPain,
        }
      };

    case ASSIGN_CONSULTATION_COMPLAINT_PAIN_LEVEL:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          complaintPainLevel: action.payload.complaintPainLevel,
        }
      };

    case ASSIGN_CONSULTATION_COMPLAINT_FREQUENCY_OF_PAIN:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          complaintFrequencyOfPain: action.payload.complaintFrequencyOfPain,
        }
      };

    case ASSIGN_CONSULTATION_COMPLAINT_PAIN_DISCOMFORT_IS_MADE_WORSE_BY:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          complaintPainDiscomfortIsMadeWorseBy: action.payload.complaintPainDiscomfortIsMadeWorseBy,
        }
      };

    case ASSIGN_CONSULTATION_COMPLAINT_FEELS_BETTER_WITH:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          complaintFeelsBetterWith: action.payload.complaintFeelsBetterWith,
        }
      };

    case ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_PALPATION_TENSION:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          assessmentPainAreaPalpationTension: action.payload.assessmentPainAreaPalpationTension,
        }
      };

    case ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_PALPATION_TEXTURE:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          assessmentPainAreaPalpationTexture: action.payload.assessmentPainAreaPalpationTexture,
        }
      };

    case ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_PALPATION_TENDERNESS:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          assessmentPainAreaPalpationTenderness: action.payload.assessmentPainAreaPalpationTenderness,
        }
      };

    case ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_PALPATION_TEMPERATURE:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          assessmentPainAreaPalpationTemperature: action.payload.assessmentPainAreaPalpationTemperature,
        }
      };

    case ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_ROM_ACTIVE:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          assessmentPainAreaRomActive: action.payload.assessmentPainAreaRomActive,
        }
      };

    case ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_ROM_PASSIVE:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          assessmentPainAreaRomPassive: action.payload.assessmentPainAreaRomPassive,
        }
      };

    case ASSIGN_CONSULTATION_ASSESSMENT_POSTURE_SHOULDER:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          assessmentPostureShoulder: action.payload.assessmentPostureShoulder,
        }
      };

    case ASSIGN_CONSULTATION_ASSESSMENT_POSTURE_SPINE:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          assessmentPostureSpine: action.payload.assessmentPostureSpine,
        }
      };

    case ASSIGN_CONSULTATION_ASSESSMENT_POSTURE_PELVIS:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          assessmentPosturePelvis: action.payload.assessmentPosturePelvis,
        }
      };


    case ASSIGN_CONSULTATION_DRAPING_TECHNIQUE:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          drapingTechnique: action.payload.drapingTechnique,
        }
      };


    case ASSIGN_CONSULTATION_HEAD_AND_NECK_SUPERFICIAL_MUSCLES:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentHeadAndNeckSuperficialMuscles: action.payload.headAndNeckSuperficialMuscles,
        }
      };

    case ASSIGN_CONSULTATION_HEAD_AND_NECK_DEEP_MUSCLES:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentHeadAndNeckDeepMuscles: action.payload.headAndNeckDeepMuscles,
        }
      };

    case ASSIGN_CONSULTATION_HEAD_AND_NECK_WARM_UP:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentHeadAndNeckWarmUp: action.payload.headAndNeckWarmUp,
        }
      };

    case ASSIGN_CONSULTATION_HEAD_AND_NECK_DEEP_TISSUES_TECHNIQUES:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentHeadAndNeckDeepTissuesTechniques: action.payload.headAndNeckDeepTissuesTechniques,
        }
      };

    case ASSIGN_CONSULTATION_HEAD_AND_NECK_APPLICATION:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentHeadAndNeckApplication: action.payload.headAndNeckApplication,
        }
      };

    case ASSIGN_CONSULTATION_SHOULDERS_SUPERFICIAL_MUSCLES:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentShouldersSuperficialMuscles: action.payload.shouldersSuperficialMuscles,
        }
      };

    case ASSIGN_CONSULTATION_SHOULDERS_DEEP_MUSCLES:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentShouldersDeepMuscles: action.payload.shouldersDeepMuscles,
        }
      };

    case ASSIGN_CONSULTATION_SHOULDERS_WARM_UP:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentShouldersWarmUp: action.payload.shouldersWarmUp,
        }
      };

    case ASSIGN_CONSULTATION_SHOULDERS_DEEP_TISSUES_TECHNIQUES:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentShouldersDeepTissuesTechniques: action.payload.shouldersDeepTissuesTechniques,
        }
      };

    case ASSIGN_CONSULTATION_SHOULDERS_APPLICATION:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentShouldersApplication: action.payload.shouldersApplication,
        }
      };

    case ASSIGN_CONSULTATION_BACK_REGION_SUPERFICIAL_MUSCLES:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentBackRegionSuperficialMuscles: action.payload.backRegionSuperficialMuscles,
        }
      };

    case ASSIGN_CONSULTATION_BACK_REGION_DEEP_MUSCLES:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentBackRegionDeepMuscles: action.payload.backRegionDeepMuscles,
        }
      };

    case ASSIGN_CONSULTATION_BACK_REGION_WARM_UP:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentBackRegionWarmUp: action.payload.backRegionWarmUp,
        }
      };

    case ASSIGN_CONSULTATION_BACK_REGION_DEEP_TISSUES_TECHNIQUES:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentBackRegionDeepTissuesTechniques: action.payload.backRegionDeepTissuesTechniques,
        }
      };

    case ASSIGN_CONSULTATION_BACK_REGION_APPLICATION:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentBackRegionApplication: action.payload.backRegionApplication,
        }
      };

    case ASSIGN_CONSULTATION_LUMBAR_REGION_SUPERFICIAL_MUSCLES:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentLumbarRegionSuperficialMuscles: action.payload.lumbarRegionSuperficialMuscles,
        }
      };

    case ASSIGN_CONSULTATION_LUMBAR_REGION_DEEP_MUSCLES:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentLumbarRegionDeepMuscles: action.payload.lumbarRegionDeepMuscles,
        }
      };

    case ASSIGN_CONSULTATION_LUMBAR_REGION_WARM_UP:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentLumbarRegionWarmUp: action.payload.lumbarRegionWarmUp,
        }
      };

    case ASSIGN_CONSULTATION_LUMBAR_REGION_DEEP_TISSUES_TECHNIQUES:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentLumbarRegionDeepTissuesTechniques: action.payload.lumbarRegionDeepTissuesTechniques,
        }
      };

    case ASSIGN_CONSULTATION_LUMBAR_REGION_APPLICATION:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentLumbarRegionApplication: action.payload.lumbarRegionApplication,
        }
      };

    case ASSIGN_CONSULTATION_HIPS_SUPERFICIAL_MUSCLES:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentHipsSuperficialMuscles: action.payload.hipsSuperficialMuscles,
        }
      };

    case ASSIGN_CONSULTATION_HIPS_DEEP_MUSCLES:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentHipsDeepMuscles: action.payload.hipsDeepMuscles,
        }
      };

    case ASSIGN_CONSULTATION_HIPS_WARM_UP:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentHipsWarmUp: action.payload.hipsWarmUp,
        }
      };

    case ASSIGN_CONSULTATION_HIPS_DEEP_TISSUES_TECHNIQUES:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentHipsDeepTissuesTechniques: action.payload.hipsDeepTissuesTechniques,
        }
      };

    case ASSIGN_CONSULTATION_HIPS_APPLICATION:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentHipsApplication: action.payload.hipsApplication,
        }
      };

    case ASSIGN_CONSULTATION_ARM_REGION_SUPERFICIAL_MUSCLES:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentArmRegionSuperficialMuscles: action.payload.armRegionSuperficialMuscles,
        }
      };

    case ASSIGN_CONSULTATION_ARM_REGION_DEEP_MUSCLES:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentArmRegionDeepMuscles: action.payload.armRegionDeepMuscles,
        }
      };

    case ASSIGN_CONSULTATION_ARM_REGION_WARM_UP:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentArmRegionWarmUp: action.payload.armRegionWarmUp,
        }
      };

    case ASSIGN_CONSULTATION_ARM_REGION_DEEP_TISSUES_TECHNIQUES:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentArmRegionDeepTissuesTechniques: action.payload.armRegionDeepTissuesTechniques,
        }
      };

    case ASSIGN_CONSULTATION_ARM_REGION_APPLICATION:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentArmRegionApplication: action.payload.armRegionApplication,
        }
      };

    case ASSIGN_CONSULTATION_THIGH_REGION:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentThighRegion: action.payload.thighRegion,
        }
      };

    case ASSIGN_CONSULTATION_THIGH_REGION_DEEP_MUSCLES:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentThighRegionDeepMuscles: action.payload.thighRegionDeepMuscles,
        }
      };

    case ASSIGN_CONSULTATION_THIGH_REGION_WARM_UP:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentThighRegionWarmUp: action.payload.thighRegionWarmUp,
        }
      };

    case ASSIGN_CONSULTATION_THIGH_REGION_DEEP_TISSUES_TECHNIQUES:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentThighRegionDeepTissuesTechniques: action.payload.thighRegionDeepTissuesTechniques,
        }
      };

    case ASSIGN_CONSULTATION_THIGH_REGION_APPLICATION:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentThighRegionApplication: action.payload.thighRegionApplication,
        }
      };

    case ASSIGN_CONSULTATION_PLAN:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentPlan: action.payload.plan,
        }
      };

    case ASSIGN_CONSULTATION_REPEAT_TIMES:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentRepeatTimes: action.payload.repeatTimes,
        }
      };

    case ASSIGN_CONSULTATION_REPEAT_WEEK_OR_MONTH:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          treatmentRepeatWeekOrMonth: action.payload.repeatWeekOrMonth,
        }
      };

    case ASSIGN_CONSULTATION_ADVICE_TO_CLIENT:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          adviceToClient: action.payload.adviceToClient,
        }
      };


    default:
      return { ...state };
  }
};

export default consultationReducer;

import React from "react";
import { PrivateRoute } from "@tmatt-tech/allaroundmassage_frontend_common";
import { BASE_SURVEY_PATH, INDEX_SURVEY_PATH, NEW_SURVEY_PATH, EDIT_SURVEY_PATH } from "routes/survey/constants";

const AllSurvey = React.lazy(() => import('pages/surveys/index/Surveys'));
const NewSurvey = React.lazy(() => import('pages/surveys/new/NewSurvey'));
const EditSurvey = React.lazy(() => import('pages/surveys/edit/EditSurvey'));

export const surveyRoutes = {
  path: BASE_SURVEY_PATH,
  name: 'Surveys',
  icon: 'dripicons-user-group',
  children: [
    {
      path: INDEX_SURVEY_PATH,
      name: 'All Surveys',
      component: AllSurvey,
      route: PrivateRoute,
    },
    {
      path: NEW_SURVEY_PATH,
      name: 'Add New',
      component: NewSurvey,
      route: PrivateRoute,
    },
  ],
};

export const hiddenSurveyRoutes = {
  children: [
    {
      path: EDIT_SURVEY_PATH,
      name: 'Edit Survey',
      component: EditSurvey,
      route: PrivateRoute,
    }
  ]
}

// @flow
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import sagas from './sagas';
import reduxThunk from 'redux-thunk';
import { isDevelopment, manageAuthToken } from "@tmatt-tech/allaroundmassage_frontend_common";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, reduxThunk, manageAuthToken];

export function configureStore(initialState: {}) {
    const composeEnhancers = generateCompose();

    const store = createStore(reducers, initialState, composeEnhancers(applyMiddleware(...middlewares)));
    sagaMiddleware.run(sagas, store.dispatch).toPromise().catch(e => {
        console.log({ message: e.message, source: 'sagaError', exception: e });
    });
    return store;
}

const generateCompose = () => {
    // if (isDevelopment()) {
    //
    //     try {
    //         return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({name: 'allaroundmassage_clinic'}) || compose;
    //     } catch (e) {
    //         return compose
    //     }
    //
    // } else {
    //     return compose
    // }

    try {
        return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({name: 'allaroundmassage_clinic'}) || compose;
    } catch (e) {
        return compose
    }
};

// @flow
import React, { Component } from 'react';
import Routes from './routes/Routes';
import { ConfigToast } from "@tmatt-tech/allaroundmassage_frontend_common";
import 'react-toastify/dist/ReactToastify.css';

// Themes
import '@tmatt-tech/allaroundmassage_frontend_common/dist/assets/scss/Saas.scss';
import ConfigTheme from "configs/components/ConfigTheme";

type AppProps = {};

/**
 * Main app component
 */
class App extends Component<AppProps> {
  render() {
    return (
      <React.Fragment>
        <ConfigTheme />
        <ConfigToast />

        <Routes />
      </React.Fragment>
    );
  }
}

export default App;


export const CREATE_PROVIDER_API = {
  url: `/api/providers`,
  method: 'post'
};

export const LIST_PROVIDER_API = {
  url: `/api/providers`,
  method: 'get',
}

export const FETCH_PROVIDER_API = {
  url: `/api/providers/:id`,
  method: 'get',
}

export const UPDATE_PROVIDER_API = {
  url: `/api/providers/:id`,
  method: 'put'
}

export const DELETE_PROVIDER_API = {
  url: `/api/providers/:id`,
  method: 'delete'
}



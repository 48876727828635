import { ERROR_FORM_INVOICE, LOADING_FORM_INVOICE, RESET_FORM_INVOICE } from "redux/ui/form/invoice/constants";
import {
  CREATE_INVOICE,
  CREATE_INVOICE_FAILED,
  CREATE_INVOICE_SUCCESS,
  UPDATE_INVOICE, UPDATE_INVOICE_FAILED, UPDATE_INVOICE_SUCCESS
} from "redux/model/invoices/invoice/constants";


const INIT_STATE = {
  loading: false,
  error: null,
};

const formInvoiceReducer = (state = INIT_STATE, action) => {
  switch (action.type) {

    case LOADING_FORM_INVOICE:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ERROR_FORM_INVOICE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case RESET_FORM_INVOICE:
      return {
        ...state,
        loading: false,
        error: null
      };

    case CREATE_INVOICE:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_INVOICE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_INVOICE:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case UPDATE_INVOICE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    default:
      return { ...state };
  }
};

export default formInvoiceReducer;

import _ from 'lodash';
import {
  CREATE_REVIEW_SUCCESS,
  DELETE_REVIEW_SUCCESS,
  UPDATE_REVIEW_SUCCESS
} from "redux/model/reviews/review/constants";
import { LIST_REVIEWS, LIST_REVIEWS_FAILED, LIST_REVIEWS_SUCCESS } from "redux/model/reviews/reviews/constants";

const INIT_STATE = {
  reviews: {},
  loading: true,
  error: null,
};

const reviewsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_REVIEWS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_REVIEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        reviews: _.mapKeys(action.payload.reviews, 'id'),
      };

    case LIST_REVIEWS_FAILED:
      return {
        ...state,
        reviews: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_REVIEW_SUCCESS:
      return {
        ...state,
        reviews: {
          [action.payload.review.id]: action.payload.review,
          ...state.reviews,
        },
      };

    case UPDATE_REVIEW_SUCCESS:
      return {
        ...state,
        reviews: {
          ...state.reviews,
          [action.payload.review.id]: action.payload.review,
        },
      };

    case DELETE_REVIEW_SUCCESS:
      return {
        ...state,
        reviews: _.omit(state.reviews, action.payload.review.id)
      };

    default:
      return { ...state };
  }
};

export default reviewsReducer;

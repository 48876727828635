import _ from 'lodash';
import {
  CREATE_GIFT_CARD_SUCCESS,
  DELETE_GIFT_CARD_SUCCESS,
  UPDATE_GIFT_CARD_SUCCESS
} from "redux/model/giftCards/giftCard/constants";
import {
  INIT_GIFT_CARDS,
  LIST_GIFT_CARDS,
  LIST_GIFT_CARDS_FAILED,
  LIST_GIFT_CARDS_SUCCESS
} from "redux/model/giftCards/giftCards/constants";

const INIT_STATE = {
  giftCards: {},
  totalSize: 0,
  loading: true,
  error: null,
};

const giftCardsReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_GIFT_CARDS:
      return INIT_STATE;

    case LIST_GIFT_CARDS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_GIFT_CARDS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        giftCards: _.mapKeys(action.payload.giftCards, 'id'),
        totalSize: action.payload.totalSize,
      };

    case LIST_GIFT_CARDS_FAILED:
      return {
        ...state,
        giftCards: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_GIFT_CARD_SUCCESS:
      return {
        ...state,
        giftCards: {
          [action.payload.giftCard.id]: action.payload.giftCard,
          ...state.giftCards,
        },
      };

    case UPDATE_GIFT_CARD_SUCCESS:
      return {
        ...state,
        giftCards: {
          ...state.giftCards,
          [action.payload.giftCard.id]: action.payload.giftCard,
        },
      };

    case DELETE_GIFT_CARD_SUCCESS:
      return {
        ...state,
        giftCards: _.omit(state.giftCards, action.payload.giftCard.id)
      };

    default:
      return { ...state };
  }
};

export default giftCardsReducers;

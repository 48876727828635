import _ from 'lodash';
import {
  CREATE_PATIENT_SUCCESS,
  DELETE_PATIENT_SUCCESS,
  UPDATE_PATIENT_SUCCESS
} from "redux/model/patients/patient/constants";
import {
  INIT_PATIENTS,
  LIST_PATIENTS,
  LIST_PATIENTS_FAILED,
  LIST_PATIENTS_SUCCESS
} from "redux/model/patients/patients/constants";

const INIT_STATE = {
  patients: {},
  totalSize: 0,
  loading: true,
  error: null,
};

const patientsReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_PATIENTS:
      return INIT_STATE;

    case LIST_PATIENTS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_PATIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        patients: _.mapKeys(action.payload.patients, 'id'),
        totalSize: action.payload.totalSize,
      };

    case LIST_PATIENTS_FAILED:
      return {
        ...state,
        patients: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_PATIENT_SUCCESS:
      return {
        ...state,
        patients: {
          [action.payload.patient.id]: action.payload.patient,
          ...state.patients,
        },
      };

    case UPDATE_PATIENT_SUCCESS:
      return {
        ...state,
        patients: {
          ...state.patients,
          [action.payload.patient.id]: action.payload.patient,
        },
      };

    case DELETE_PATIENT_SUCCESS:
      return {
        ...state,
        patients: _.omit(state.patients, action.payload.patient.id)
      };

    default:
      return { ...state };
  }
};

export default patientsReducers;

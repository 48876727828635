import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { apiListProviders } from "api/provider/providerApi";
import { listProvidersFailed, listProvidersSuccess } from "redux/model/providers/providers/providersActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/allaroundmassage_frontend_common";
import { LIST_PROVIDERS } from "redux/model/providers/providers/constants";
import { listProviderNumbersSuccess } from "redux/model/providerNumbers/providerNumbers/providerNumbersActions";


function* providersSaga() {
  yield all([
    fork(watchListProviders),
  ]);
}

// List Providers
export function* watchListProviders() {
  yield takeEvery(LIST_PROVIDERS, sagaListProviders);
}

export function* sagaListProviders() {
  try {
    const response = yield call(apiListProviders);
    const { providers, providerNumbers } = response.data

    yield put(listProvidersSuccess({ providers }));
    yield put(listProviderNumbersSuccess({ providerNumbers }));

  } catch (e) {
    yield put(listProvidersFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default providersSaga;

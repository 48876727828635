import { axios, extractIdFrom, generateRoute } from "@tmatt-tech/allaroundmassage_frontend_common";
import {
  CLINIC_CHECK_STAFF_IN_API, CLINIC_CHECK_STAFF_OUT_API,
  FETCH_CLINIC_API,
  FETCH_CURRENT_CLINIC_API,
  UPDATE_CLINIC_API
} from "api/clinic/constants";

export const apiFetchClinic = ({ clinicId }) => {
  const { url, method } = FETCH_CLINIC_API;

  return axios({
    method,
    url: generateRoute(url, clinicId),
  });
};

export const apiFetchCurrentClinic = () => {
  const { url, method } = FETCH_CURRENT_CLINIC_API;

  return axios({
    method,
    url: url,
  });
};

export const apiUpdateClinic = ({ clinic }) => {
  const { url, method } = UPDATE_CLINIC_API;

  return axios({
    method,
    url: generateRoute(url, clinic.id),
    data: clinicParams(clinic),
  });
};

export const apiClinicCheckStaffIn = ({ staff, amount }) => {
  const { url, method } = CLINIC_CHECK_STAFF_IN_API;

  return axios({
    method,
    url,
    data: {
      staff: { mobile: staff.mobile },
      amount
    },
  });
};

export const apiClinicCheckStaffOut = ({ amount }) => {
  const { url, method } = CLINIC_CHECK_STAFF_OUT_API;

  return axios({
    method,
    url,
    data: {
      amount
    },
  });
};

const clinicParams = (clinic) => {
  let params = {
    email: clinic.email,
    username: clinic.username,
    name: clinic.name,
    phone: clinic.phone,
    mobile: clinic.mobile,
    location: clinic.location,
    businessName: clinic.businessName,
    abn: clinic.abn,
    overview: clinic.overview,
    logo: clinic.logo ? { sourceId: extractIdFrom(clinic.logo.sourceId) } : null,
  }

  if (clinic.password) {
    params = {
      ...params,
      password: clinic.password,
    }
  }

  return params;
};

import React from 'react';
import { Redirect } from 'react-router-dom';
import { authRoutes, PrivateRoute } from "@tmatt-tech/allaroundmassage_frontend_common";
import { OVERVIEW_DASHBOARD_PATH } from "routes/dashboard/constants";
import { assistantReportRoutes, hiddenAssistantReportRoutes } from "routes/assistantReport/routes";
import { dashboardRoutes, hiddenDashboardRoutes } from "routes/dashboard/routes";
import { hiddenInvoiceRoutes, invoiceRoutes } from "routes/invoice/routes";
import { hiddenPatientRoutes, patientRoutes } from "routes/patient/routes";
import { hiddenPractitionerRoutes, practitionerRoutes } from "routes/practitioner/routes";
import { hiddenGiftCardRoutes, giftCardRoutes } from "routes/giftCard/routes";
import { hiddenClinicRoutes } from "routes/clinic/routes";
import { consultationRoutes, hiddenConsultationRoutes } from "routes/consultation/routes";
import { hiddenReviewRoutes, reviewRoutes } from "routes/review/routes";
import { hiddenSurveyRoutes, surveyRoutes } from "routes/survey/routes";
import { hiddenStaffRoutes } from "routes/staff/routes";
import { hiddenProviderRoutes, providerRoutes } from "routes/provider/routes";
import { hiddenNoticeRoutes, noticeRoutes } from "routes/notice/routes";

// root routes
const rootRoute = {
  path: '/',
  exact: true,
  component: () => <Redirect to={OVERVIEW_DASHBOARD_PATH} />,
  route: PrivateRoute,
};


// flatten the list of all nested routes
const flattenRoutes = routes => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach(item => {
    flatRoutes.push(item);

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

const hiddenRoutes = {
  children: [
    ...hiddenAssistantReportRoutes.children,
    ...hiddenInvoiceRoutes.children,
    ...hiddenDashboardRoutes.children,
    ...hiddenPatientRoutes.children,
    ...hiddenPractitionerRoutes.children,
    ...hiddenGiftCardRoutes.children,
    ...hiddenClinicRoutes.children,
    ...hiddenConsultationRoutes.children,
    ...hiddenReviewRoutes.children,
    ...hiddenSurveyRoutes.children,
    ...hiddenStaffRoutes.children,
    ...hiddenProviderRoutes.children,
    ...hiddenNoticeRoutes.children,
  ],
};

// All routes
const allRoutes = [
  rootRoute,
  authRoutes,
  assistantReportRoutes,
  invoiceRoutes,
  dashboardRoutes,
  patientRoutes,
  practitionerRoutes,
  giftCardRoutes,
  consultationRoutes,
  reviewRoutes,
  surveyRoutes,
  providerRoutes,
  noticeRoutes,
  hiddenRoutes,
];

const authProtectedRoutes = [
  rootRoute,
  dashboardRoutes,
  assistantReportRoutes,
  consultationRoutes,
  giftCardRoutes,
  invoiceRoutes,
  // reviewRoutes,
  surveyRoutes,
  patientRoutes,
  // practitionerRoutes,
  providerRoutes,
  // noticeRoutes,
];

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };

import {
  FETCH_PATIENT,
  FETCH_PATIENT_FAILED,
  FETCH_PATIENT_SUCCESS,
  INIT_PATIENT,
  ASSIGN_PATIENT, START_PATIENT_FETCHED_LISTENING, STOP_PATIENT_FETCHED_LISTENING
} from "redux/model/patients/patient/constants";

const INIT_STATE = {
  patient: {},
  loading: true,
  error: null,
  fetchedListening: false,
};

const patientReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_PATIENT:
      return INIT_STATE;

    case FETCH_PATIENT:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        patient: action.payload.patient,
      };

    case FETCH_PATIENT_FAILED:
      return {
        ...state,
        patient: {},
        loading: false,
        error: action.payload.error,
      };

    case ASSIGN_PATIENT:
      return {
        ...state,
        patient: {
          ...state.patient,
          ...action.payload.fields,
        }
      }

    case START_PATIENT_FETCHED_LISTENING:
      return {
        ...state,
        fetchedListening: true,
      };

    case STOP_PATIENT_FETCHED_LISTENING:
      return {
        ...state,
        fetchedListening: false,
      };

    default:
      return { ...state };
  }
};

export default patientReducers;

export const INIT_PROVIDER = 'INIT_PROVIDER';

export const CREATE_PROVIDER = 'CREATE_PROVIDER';
export const CREATE_PROVIDER_SUCCESS = 'CREATE_PROVIDER_SUCCESS';
export const CREATE_PROVIDER_FAILED = 'CREATE_PROVIDER_FAILED';

export const FETCH_PROVIDER = 'FETCH_PROVIDER';
export const FETCH_PROVIDER_SUCCESS = 'FETCH_PROVIDER_SUCCESS';
export const FETCH_PROVIDER_FAILED = 'FETCH_PROVIDER_FAILED';

export const UPDATE_PROVIDER = 'UPDATE_PROVIDER';
export const UPDATE_PROVIDER_SUCCESS = 'UPDATE_PROVIDER_SUCCESS';
export const UPDATE_PROVIDER_FAILED = 'UPDATE_PROVIDER_FAILED';

export const DELETE_PROVIDER = 'DELETE_PROVIDER';
export const DELETE_PROVIDER_SUCCESS = 'DELETE_PROVIDER_SUCCESS';
export const DELETE_PROVIDER_FAILED = 'DELETE_PROVIDER_FAILED';

export const NEW_PROVIDER = 'NEW_PROVIDER';

export const ASSIGN_PROVIDER_NAME = 'ASSIGN_PROVIDER_NAME';
export const ASSIGN_PROVIDER_MOBILE = 'ASSIGN_PROVIDER_MOBILE';
export const ASSIGN_PROVIDER_MEMBERSHIP_NUMBER = 'ASSIGN_PROVIDER_MEMBERSHIP_NUMBER';
export const ASSIGN_PROVIDER_INSURANCE_EXPIRE_DATE = 'ASSIGN_PROVIDER_INSURANCE_EXPIRE_DATE';
export const ASSIGN_PROVIDER_FIRST_AID_EXPIRY_DATE = 'ASSIGN_PROVIDER_FIRST_AID_EXPIRY_DATE';
export const ASSIGN_PROVIDER_CPD_LODGED_DATE = 'ASSIGN_PROVIDER_CPD_LODGED_DATE';
export const ASSIGN_PROVIDER_ADDRESS_1 = 'ASSIGN_PROVIDER_ADDRESS_1';
export const ASSIGN_PROVIDER_ADDRESS_2 = 'ASSIGN_PROVIDER_ADDRESS_2';
export const ASSIGN_PROVIDER_ADDRESS_3 = 'ASSIGN_PROVIDER_ADDRESS_3';

import {
  FETCH_SURVEY,
  FETCH_SURVEY_FAILED,
  FETCH_SURVEY_SUCCESS,
  INIT_SURVEY
} from "redux/model/surveys/survey/constants";

const INIT_STATE = {
  survey: {
    emergency: {},
    privateHealthFund: {},
  },
  loading: true,
  error: null,
};

const surveyReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_SURVEY:
      return INIT_STATE;

    case FETCH_SURVEY:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_SURVEY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        survey: action.payload.survey,
      };

    case FETCH_SURVEY_FAILED:
      return {
        ...state,
        survey: {},
        loading: false,
        error: action.payload.error,
      };

    default:
      return { ...state };
  }
};

export default surveyReducer;

import { axios, generateRoute } from "@tmatt-tech/allaroundmassage_frontend_common";
import {
  CREATE_PRACTITIONER_API,
  LIST_PRACTITIONER_API,
  FETCH_PRACTITIONER_API,
  DELETE_PRACTITIONER_API,
  UPDATE_PRACTITIONER_API
} from "api/practitioner/constants";


export const apiListPractitioners = () => {
  const { url, method } = LIST_PRACTITIONER_API;

  return axios({
    method,
    url,
  });
};

export const apiCreatePractitioner = ({ practitioner }) => {
  const { url, method } = CREATE_PRACTITIONER_API;

  return axios({
    method,
    url,
    data: practitionerParams(practitioner),
  });
};

export const apiFetchPractitioner = ({ practitionerId }) => {
  const { url, method } = FETCH_PRACTITIONER_API;

  return axios({
    method,
    url: generateRoute(url, practitionerId),
  });
};

export const apiUpdatePractitioner = ({ practitioner }) => {
  const { url, method } = UPDATE_PRACTITIONER_API;

  return axios({
    method,
    url: generateRoute(url, practitioner.id),
    data: practitionerParams(practitioner),
  });
};

export const apiDeletePractitioner = ({ practitioner }) => {
  const { url, method } = DELETE_PRACTITIONER_API;

  return axios({
    method,
    url: generateRoute(url, practitioner.id),
  });
};

const practitionerParams = (practitioner) => {
  let params = {
    firstName: practitioner.firstName,
    lastName: practitioner.lastName,
    username: practitioner.username,
    email: practitioner.email,
    mobile: practitioner.mobile,
    state: practitioner.state,
    commission: practitioner.commission,
  }

  if (practitioner.password) {
    params = {
      ...params,
      password: practitioner.password,
    }
  }

  return params;
};

import { LIST_PROVIDERS, LIST_PROVIDERS_FAILED, LIST_PROVIDERS_SUCCESS } from "redux/model/providers/providers/constants";

export const listProviders = () => ({
  type: LIST_PROVIDERS,
  payload: {}
});

export const listProvidersSuccess = ({ providers }) => ({
  type: LIST_PROVIDERS_SUCCESS,
  payload: { providers }
});

export const listProvidersFailed = ({ error }) => ({
  type: LIST_PROVIDERS_FAILED,
  payload: { error }
});

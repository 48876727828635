import { all } from 'redux-saga/effects';
import assistantReportSaga from "redux/model/assistantReports/assistantReport/assistantReportSaga";
import assistantReportsSaga from "redux/model/assistantReports/assistantReports/assistantReportsSaga";
import practitionersSaga from "redux/model/practitioners/practitioners/practitionersSaga";
import practitionerReportSaga from "redux/model/practitionerReports/practitionerReport/practitionerReportSaga";
import invoiceSaga from "redux/model/invoices/invoice/invoiceSaga";
import invoicesSaga from "redux/model/invoices/invoices/invoicesSaga";
import clinicSaga from "redux/model/clinics/clinic/clinicSaga";
import patientSaga from "redux/model/patients/patient/patientSaga";
import patientsSaga from "redux/model/patients/patients/patientsSaga";
import practitionerSaga from "redux/model/practitioners/practitioner/practitionerSaga";
import consultationSaga from "redux/model/consultations/consultation/consultationSaga";
import consultationsSaga from "redux/model/consultations/consultations/consultationsSaga";
import reviewSaga from "redux/model/reviews/review/reviewSaga";
import reviewsSaga from "redux/model/reviews/reviews/reviewsSaga";
import giftCardSaga from "redux/model/giftCards/giftCard/giftCardSaga";
import giftCardsSaga from "redux/model/giftCards/giftCards/giftCardsSaga";
import surveySaga from "redux/model/surveys/survey/surveySaga";
import surveysSaga from "redux/model/surveys/surveys/surveysSaga";
import providerSaga from "redux/model/providers/provider/providerSaga";
import providersSaga from "redux/model/providers/providers/providersSaga";
import noticesSaga from "redux/model/notices/notices/noticesSaga";
import transactionsSaga from "redux/model/transactions/transactions/transactionsSaga";

function* modelSaga() {
  yield all([
    assistantReportSaga(),
    assistantReportsSaga(),
    practitionerReportSaga(),
    invoiceSaga(),
    invoicesSaga(),
    patientSaga(),
    patientsSaga(),
    reviewSaga(),
    reviewsSaga(),
    consultationSaga(),
    consultationsSaga(),
    practitionerSaga(),
    practitionersSaga(),
    clinicSaga(),
    giftCardSaga(),
    giftCardsSaga(),
    surveySaga(),
    surveysSaga(),
    providerSaga(),
    providersSaga(),
    noticesSaga(),
    transactionsSaga(),
  ]);
}

export default modelSaga;

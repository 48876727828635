import { ERROR_FORM_PATIENT, LOADING_FORM_PATIENT, RESET_FORM_PATIENT } from "redux/ui/form/patient/constants";
import {
  CREATE_PATIENT,
  CREATE_PATIENT_FAILED,
  CREATE_PATIENT_SUCCESS,
  UPDATE_PATIENT, UPDATE_PATIENT_FAILED, UPDATE_PATIENT_SUCCESS
} from "redux/model/patients/patient/constants";


const INIT_STATE = {
  loading: false,
  error: null,
};

const formPatientReducer = (state = INIT_STATE, action) => {
  switch (action.type) {

    case LOADING_FORM_PATIENT:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ERROR_FORM_PATIENT:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case RESET_FORM_PATIENT:
      return {
        ...state,
        loading: false,
        error: null
      };

    case CREATE_PATIENT:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_PATIENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_PATIENT:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case UPDATE_PATIENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    default:
      return { ...state };
  }
};

export default formPatientReducer;

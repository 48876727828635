import {
  axios,
  extractIdFrom,
  generateRoute,
  toApiDateString,
  toApiDateTimeString, toMomentFromTimeString
} from "@tmatt-tech/allaroundmassage_frontend_common";
import {
  CREATE_CONSULTATION_API,
  DELETE_CONSULTATION_API,
  FETCH_CONSULTATION_API,
  FETCH_NEW_CONSULTATION_API, LINK_CONSULTATION_API,
  LIST_CONSULTATION_API,
  UPDATE_CONSULTATION_API,
  LIST_CONSULTATION_PATIENTS_API
} from "api/consultation/constants";


export const apiListConsultations = ({ page, query }) => {
  const { url, method } = LIST_CONSULTATION_API;

  return axios({
    method,
    url,
    params: {
      page,
      query,
    }
  });
};

export const apiListConsultationPatient = ({ query }) => {
  const {url, method} = LIST_CONSULTATION_PATIENTS_API;

  return axios({
    method,
    url,
    params: {
      query,
    }
  });
};

export const apiCreateConsultation = ({ consultation }) => {
  const { url, method } = CREATE_CONSULTATION_API;

  return axios({
    method,
    url,
    data: consultationParams(consultation),
  });
};

export const apiFetchConsultation = ({ consultationId }) => {
  const { url, method } = FETCH_CONSULTATION_API;

  return axios({
    method,
    url: generateRoute(url, consultationId),
  });
};

export const apiFetchNewConsultation = ({ practitionerReportItemId }) => {
  const { url, method } = FETCH_NEW_CONSULTATION_API;

  return axios({
    method,
    url,
    params: { practitionerReportItemId },
  });
};

export const apiUpdateConsultation = ({ consultation }) => {
  const { url, method } = UPDATE_CONSULTATION_API;

  return axios({
    method,
    url: generateRoute(url, consultation.id),
    data: consultationParams(consultation),
  });
};

export const apiDeleteConsultation = ({ consultation }) => {
  const { url, method } = DELETE_CONSULTATION_API;

  return axios({
    method,
    url: generateRoute(url, consultation.id),
  });
};

export const apiLinkConsultation = ({ consultation, practitionerReportItemId }) => {
  const { url, method } = LINK_CONSULTATION_API;

  return axios({
    method,
    url: generateRoute(url, consultation.id),
    data: {
      practitionerReportItemId: extractIdFrom(practitionerReportItemId),
    },
  });
};


const consultationParams = (consultation) => {
  const {
    datetime,
    patientId,
    musculoskeletal,
    nervous,
    cardiovascular,
    respiratory,
    reactions,
    reactionsNote,
    otherConditions,
    otherConditionsNote,
    medication,
    painOrTenderness,
    stiffness,
    numbnessOrTingling,
    complaintNotes,
    treatmentPlanOthers,
    swelling,
    bodyFocusImage,
    signature,
    guardianSignature,
    signedDate,
    areas,
    treatments,
    conditions,
    herbsOrVitamins,
    materials,
    afterTreatment,
    suggestions,

    // new
    complaintArea,
    complaintTypeOfPain,
    complaintPainLevel,
    complaintFrequencyOfPain,
    complaintPainDiscomfortIsMadeWorseBy,
    complaintFeelsBetterWith,

    assessmentPainAreaPalpationTension,
    assessmentPainAreaPalpationTexture,
    assessmentPainAreaPalpationTenderness,
    assessmentPainAreaPalpationTemperature,

    assessmentPainAreaRomActive,
    assessmentPainAreaRomPassive,

    assessmentPostureShoulder,
    assessmentPostureSpine,
    assessmentPosturePelvis,

    drapingTechnique,

    treatmentHeadAndNeckSuperficialMuscles,
    treatmentHeadAndNeckDeepMuscles,
    treatmentHeadAndNeckWarmUp,
    treatmentHeadAndNeckDeepTissuesTechniques,
    treatmentHeadAndNeckApplication,

    treatmentShouldersSuperficialMuscles,
    treatmentShouldersDeepMuscles,
    treatmentShouldersWarmUp,
    treatmentShouldersDeepTissuesTechniques,
    treatmentShouldersApplication,

    treatmentBackRegionSuperficialMuscles,
    treatmentBackRegionDeepMuscles,
    treatmentBackRegionWarmUp,
    treatmentBackRegionDeepTissuesTechniques,
    treatmentBackRegionApplication,

    treatmentLumbarRegionSuperficialMuscles,
    treatmentLumbarRegionDeepMuscles,
    treatmentLumbarRegionWarmUp,
    treatmentLumbarRegionDeepTissuesTechniques,
    treatmentLumbarRegionApplication,

    treatmentHipsSuperficialMuscles,
    treatmentHipsDeepMuscles,
    treatmentHipsWarmUp,
    treatmentHipsDeepTissuesTechniques,
    treatmentHipsApplication,

    treatmentArmRegionSuperficialMuscles,
    treatmentArmRegionDeepMuscles,
    treatmentArmRegionWarmUp,
    treatmentArmRegionDeepTissuesTechniques,
    treatmentArmRegionApplication,

    treatmentThighRegion,
    treatmentThighRegionDeepMuscles,
    treatmentThighRegionWarmUp,
    treatmentThighRegionDeepTissuesTechniques,
    treatmentThighRegionApplication,

    treatmentPlan,
    treatmentRepeatTimes,
    treatmentRepeatWeekOrMonth,
    adviceToClient,
    //


    provider,
    insuranceCompany,
    providerNumber,
    duration,
    practitionerId,
    timeFrom,
    timeTo,
    servicesAndGoods,
    isHicapsCopyGiven,
    hicapsCopyGivenNote,
    isReceiptGiven,
    receiptNumber,
    practitionerReportItemId,
  } = consultation;

  return {
    datetime: toApiDateTimeString(datetime),
    patientId: extractIdFrom(patientId),
    musculoskeletal,
    nervous,
    cardiovascular,
    respiratory,
    reactions,
    reactionsNote,
    otherConditions,
    otherConditionsNote,
    medication,
    painOrTenderness,
    stiffness,
    numbnessOrTingling,   
    swelling,
    bodyFocusImage,
    signature,
    guardianSignature,
    signedDate: toApiDateString(signedDate),
    areas,
    treatments,
    conditions,
    herbsOrVitamins,
    materials,
    afterTreatment,
    suggestions,
    
    // new
    complaintNotes,
    complaintArea,
    complaintTypeOfPain,
    complaintPainLevel,
    complaintFrequencyOfPain,
    complaintPainDiscomfortIsMadeWorseBy,
    complaintFeelsBetterWith,

    assessmentPainAreaPalpationTension,
    assessmentPainAreaPalpationTexture,
    assessmentPainAreaPalpationTenderness,
    assessmentPainAreaPalpationTemperature,

    assessmentPainAreaRomActive,
    assessmentPainAreaRomPassive,

    assessmentPostureShoulder,
    assessmentPostureSpine,
    assessmentPosturePelvis,

    drapingTechnique,

    treatmentPlanOthers,
    treatmentHeadAndNeckSuperficialMuscles,
    treatmentHeadAndNeckDeepMuscles,
    treatmentHeadAndNeckWarmUp,
    treatmentHeadAndNeckDeepTissuesTechniques,
    treatmentHeadAndNeckApplication,

    treatmentShouldersSuperficialMuscles,
    treatmentShouldersDeepMuscles,
    treatmentShouldersWarmUp,
    treatmentShouldersDeepTissuesTechniques,
    treatmentShouldersApplication,

    treatmentBackRegionSuperficialMuscles,
    treatmentBackRegionDeepMuscles,
    treatmentBackRegionWarmUp,
    treatmentBackRegionDeepTissuesTechniques,
    treatmentBackRegionApplication,

    treatmentLumbarRegionSuperficialMuscles,
    treatmentLumbarRegionDeepMuscles,
    treatmentLumbarRegionWarmUp,
    treatmentLumbarRegionDeepTissuesTechniques,
    treatmentLumbarRegionApplication,

    treatmentHipsSuperficialMuscles,
    treatmentHipsDeepMuscles,
    treatmentHipsWarmUp,
    treatmentHipsDeepTissuesTechniques,
    treatmentHipsApplication,

    treatmentArmRegionSuperficialMuscles,
    treatmentArmRegionDeepMuscles,
    treatmentArmRegionWarmUp,
    treatmentArmRegionDeepTissuesTechniques,
    treatmentArmRegionApplication,

    treatmentThighRegion,
    treatmentThighRegionDeepMuscles,
    treatmentThighRegionWarmUp,
    treatmentThighRegionDeepTissuesTechniques,
    treatmentThighRegionApplication,

    treatmentPlan,
    treatmentRepeatTimes,
    treatmentRepeatWeekOrMonth,

    adviceToClient,
    //
    provider,
    insuranceCompany,
    providerNumber,
    duration,
    practitionerId: extractIdFrom(practitionerId),
    timeFrom: toApiDateTimeString(toMomentFromTimeString(timeFrom)),
    timeTo: toApiDateTimeString(toMomentFromTimeString(timeTo)),
    servicesAndGoods,
    isHicapsCopyGiven,
    hicapsCopyGivenNote,
    isReceiptGiven,
    receiptNumber,
    practitionerReportItemId,
  };
};

import _ from 'lodash';
import {
  LIST_PRACTITIONERS,
  LIST_PRACTITIONERS_FAILED,
  LIST_PRACTITIONERS_SUCCESS
} from "redux/model/practitioners/practitioners/constants";
import { DELETE_PRACTITIONER_SUCCESS } from "redux/model/practitioners/practitioner/constants";

const INIT_STATE = {
  practitioners: {},
  loading: true,
  error: null,
};

const practitionersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_PRACTITIONERS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_PRACTITIONERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        practitioners: _.mapKeys(action.payload.practitioners, 'id'),
      };

    case LIST_PRACTITIONERS_FAILED:
      return {
        ...state,
        practitioners: {},
        loading: false,
        error: action.payload.error,
      };

    case DELETE_PRACTITIONER_SUCCESS:
      return {
        ...state,
        practitioners: _.omit(state.practitioners, action.payload.practitioner.id),
      };

    default:
      return { ...state };
  }
};

export default practitionersReducer;

export const INIT_ASSISTANT_REPORT = 'INIT_ASSISTANT_REPORT';

export const CREATE_ASSISTANT_REPORT = 'CREATE_ASSISTANT_REPORT';
export const CREATE_ASSISTANT_REPORT_SUCCESS = 'CREATE_ASSISTANT_REPORT_SUCCESS';
export const CREATE_ASSISTANT_REPORT_FAILED = 'CREATE_ASSISTANT_REPORT_FAILED';

export const FETCH_ASSISTANT_REPORT = 'FETCH_ASSISTANT_REPORT';
export const FETCH_ASSISTANT_REPORT_SUCCESS = 'FETCH_ASSISTANT_REPORT_SUCCESS';
export const FETCH_ASSISTANT_REPORT_FAILED = 'FETCH_ASSISTANT_REPORT_FAILED';

export const UPDATE_ASSISTANT_REPORT = 'UPDATE_ASSISTANT_REPORT';
export const UPDATE_ASSISTANT_REPORT_SUCCESS = 'UPDATE_ASSISTANT_REPORT_SUCCESS';
export const UPDATE_ASSISTANT_REPORT_FAILED = 'UPDATE_ASSISTANT_REPORT_FAILED';

export const FETCH_TODAY_ASSISTANT_REPORT = 'FETCH_TODAY_ASSISTANT_REPORT';

export const LOCK_ASSISTANT_REPORT = 'LOCK_ASSISTANT_REPORT';
export const LOCK_ASSISTANT_REPORT_SUCCESS = 'LOCK_ASSISTANT_REPORT_SUCCESS';
export const LOCK_ASSISTANT_REPORT_FAILED = 'LOCK_ASSISTANT_REPORT_FAILED';

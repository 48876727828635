import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/allaroundmassage_frontend_common";
import {
  FETCH_CLINIC,
  FETCH_CURRENT_CLINIC,
  UPDATE_CLINIC,
  CLINIC_STAFF_CHECK_IN,
  CLINIC_STAFF_CHECK_OUT
} from "redux/model/clinics/clinic/constants";
import {
  apiClinicCheckStaffIn,
  apiClinicCheckStaffOut,
  apiFetchClinic,
  apiFetchCurrentClinic,
  apiUpdateClinic
} from "api/clinic/clinicApi";
import {
  fetchClinicFailed,
  fetchClinicSuccess,
  initClinic,
  updateClinicFailed,
  updateClinicSuccess,
  checkStaffInFailed,
  checkStaffInSuccess,
  checkStaffOutFailed,
  checkStaffOutSuccess,
} from "redux/model/clinics/clinic/clinicActions";
import { toastError, toastSaved } from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeFirstLayerModal,
} from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/modal/modalActions";
import { apiSignInStaff } from "api/staffAuth/staffAuthApi";

function* clinicSaga() {
  yield all([
    fork(watchFetchClinic),
    fork(watchFetchCurrentClinic),
    fork(watchUpdateClinic),
    fork(watchStaffCheckIn),
    fork(watchStaffCheckOut)
  ]);
}

// Staff Check-in
export function* watchStaffCheckIn() {
  yield takeEvery(CLINIC_STAFF_CHECK_IN, sagaCheckStaffIn);
}

export function* sagaCheckStaffIn({ payload }) {
  try {

    yield delay(SAGA_WAITING_TIME);

    yield call(apiSignInStaff, { mobile: payload.staff.mobile, password: payload.password });
    const response = yield call(apiClinicCheckStaffIn, payload);
    yield put(checkStaffInSuccess({ clinic: response.data }));
    yield put(closeFirstLayerModal());

  } catch (e) {
    yield put(checkStaffInFailed(generateErrorMessage(e)));
    yield put(toastError({message: 'Check in failed'}));

    throwFrontError(e);
  }
}

export function* watchStaffCheckOut() {
  yield takeEvery(CLINIC_STAFF_CHECK_OUT, sagaCheckStaffOut);
}

export function* sagaCheckStaffOut({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiClinicCheckStaffOut, payload);
    yield put(checkStaffOutSuccess({ clinic: response.data }));
    yield put(closeFirstLayerModal());

  } catch (e) {

    yield put(checkStaffOutFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Fetch Clinic
export function* watchFetchClinic() {
  yield takeEvery(FETCH_CLINIC, sagaFetchClinic);
}

export function* sagaFetchClinic({ payload }) {
  try {
    const response = yield call(apiFetchClinic, payload);

    yield put(fetchClinicSuccess({ clinic: response.data }));

  } catch (e) {
    yield put(fetchClinicFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Fetch Current Clinic
export function* watchFetchCurrentClinic() {
  yield takeEvery(FETCH_CURRENT_CLINIC, sagaFetchCurrentClinic);
}

export function* sagaFetchCurrentClinic({ payload }) {
  try {
    const response = yield call(apiFetchCurrentClinic);

    yield put(fetchClinicSuccess({ clinic: response.data }));

  } catch (e) {
    yield put(fetchClinicFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Update Clinic
export function* watchUpdateClinic() {
  yield takeLeading(UPDATE_CLINIC, sagaUpdateClinic);
}

export function* sagaUpdateClinic({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateClinic, payload);

    yield put(updateClinicSuccess({ clinic: response.data }));
    yield put(toastSaved({ name: response.data.name }));
    yield put(initClinic());

  } catch (e) {
    yield put(updateClinicFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default clinicSaga;

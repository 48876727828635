import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import {
  generateErrorMessage,
  generateRoute,
  history,
  throwFrontError
} from "@tmatt-tech/allaroundmassage_frontend_common";
import {
  CREATE_REVIEW,
  DELETE_REVIEW,
  FETCH_NEW_REVIEW,
  FETCH_REVIEW,
  UPDATE_REVIEW
} from "redux/model/reviews/review/constants";
import {
  apiCreateReview,
  apiDeleteReview,
  apiFetchNewReview,
  apiFetchReview,
  apiUpdateReview
} from "api/review/reviewApi";
import {
  createReviewFailed,
  createReviewSuccess,
  deleteReviewFailed,
  deleteReviewSuccess,
  fetchReviewFailed,
  fetchReviewSuccess,
  initReview,
  updateReviewFailed,
  updateReviewSuccess
} from "redux/model/reviews/review/reviewActions";
import {
  toastDeleted,
  toastError,
  toastSaved
} from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeSecondLayerModal,
  startSecondLayerModalListening
} from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/modal/modalActions";
import { INDEX_REVIEW_PATH } from "routes/review/constants";


function* reviewSaga() {
  yield all([
    fork(watchFetchReview),
    fork(watchFetchNewReview),
    fork(watchUpdateReview),
    fork(watchCreateReview),
    fork(watchDeleteReview),
  ]);
}

// Fetch Review
export function* watchFetchReview() {
  yield takeEvery(FETCH_REVIEW, sagaFetchReview);
}

export function* sagaFetchReview({ payload }) {
  try {
    const response = yield call(apiFetchReview, payload);
    const { review } = response.data;

    yield put(fetchReviewSuccess({ review }));

  } catch (e) {
    yield put(fetchReviewFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Fetch New Review
export function* watchFetchNewReview() {
  yield takeEvery(FETCH_NEW_REVIEW, sagaFetchNewReview);
}

export function* sagaFetchNewReview({ payload }) {
  try {
    const response = yield call(apiFetchNewReview, payload);
    const { review } = response.data;

    yield put(fetchReviewSuccess({ review }));

  } catch (e) {
    yield put(fetchReviewFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create Review
export function* watchCreateReview() {
  yield takeLeading(CREATE_REVIEW, sagaCreateReview);
}

export function* sagaCreateReview({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateReview, payload);

    yield put(createReviewSuccess({ review: response.data }));
    yield put(toastSaved({ name: 'review' }));
    history.push(generateRoute(INDEX_REVIEW_PATH));
    yield put(initReview());


  } catch (e) {
    yield put(createReviewFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update Review
export function* watchUpdateReview() {
  yield takeLeading(UPDATE_REVIEW, sagaUpdateReview);
}

export function* sagaUpdateReview({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateReview, payload);

    yield put(updateReviewSuccess({ review: response.data }));
    yield put(toastSaved({ name: 'review' }));
    history.push(generateRoute(INDEX_REVIEW_PATH));
    yield put(initReview());

  } catch (e) {
    yield put(updateReviewFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete Review
export function* watchDeleteReview() {
  yield takeLeading(DELETE_REVIEW, sagaDeleteReview);
}

export function* sagaDeleteReview({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeleteReview, payload);

    yield put(deleteReviewSuccess({ review: response.data }));
    yield put(toastDeleted({ name: 'review' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deleteReviewFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default reviewSaga;

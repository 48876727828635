import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import {
  CREATE_PATIENT,
  DELETE_PATIENT,
  FETCH_PATIENT,
  QUERY_POINTS,
  UPDATE_PATIENT
} from "redux/model/patients/patient/constants";
import {
  apiCreatePatient,
  apiDeletePatient,
  apiFetchPatient,
  apiQueryPoints,
  apiUpdatePatient
} from "api/patient/apiPatient";
import {
  createPatientFailed,
  createPatientSuccess,
  deletePatientFailed,
  deletePatientSuccess, fetchPatient,
  fetchPatientFailed,
  fetchPatientSuccess,
  initPatient, startPatientFetchedListening,
  updatePatientFailed,
  updatePatientSuccess
} from "redux/model/patients/patient/patientActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import { INDEX_PATIENTS_PATH } from "routes/patient/constants";
import {
  generateErrorMessage, generateRoute, history,
  throwFrontError,
} from "@tmatt-tech/allaroundmassage_frontend_common";
import {
  toastDeleted,
  toastError,
  toastSaved
} from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/toast/toastActions";
import {} from "api/patient/apiPatient";
import {
  closeFirstLayerModal,
  closeSecondLayerModal, startFirstLayerModalListening,
  startSecondLayerModalListening
} from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/modal/modalActions";
import { assignConsultationPatient } from "redux/model/consultations/consultation/consultationActions";
import { assignPractitionerReportItemPointsBalance } from 'redux/actions';
import _ from 'lodash';


function* patientSaga() {
  yield all([
    fork(watchFetchPatient),
    fork(watchUpdatePatient),
    fork(watchCreatePatient),
    fork(watchDeletePatient),
    fork(watchQueryPoints),
  ]);
}

// Query Points
export function* watchQueryPoints() {
  yield takeLeading(QUERY_POINTS, sagaQueryPoints);
}

export function* sagaQueryPoints({ payload }) {
  console.log('export function* sagaQueryPoints({ payload }) { ==== onBlur jungle');
  const { phone, practitionerReportItem } = payload;
  console.log(`phone: ${phone}`);
  try {
    if (_.trim(phone) !== '') {
      const response = yield call(apiQueryPoints, { phone });
      const { patient, note } = response.data;

      if (patient) {
        const { points } = patient;
        yield put(assignPractitionerReportItemPointsBalance({ practitionerReportItem, pointsBalance: points }));
      }
      else {
        yield put(assignPractitionerReportItemPointsBalance({ practitionerReportItem, pointsBalance: note }));
      }
      
    }
    else {
      yield put(assignPractitionerReportItemPointsBalance({ practitionerReportItem, pointsBalance: null }));
    }
  } catch (e) {
    
  }
}

// Fetch Patient
export function* watchFetchPatient() {
  yield takeEvery(FETCH_PATIENT, sagaFetchPatient);
}

export function* sagaFetchPatient({ payload }) {
  try {
    const { patientId, target } = payload;
    const response = yield call(apiFetchPatient, { patientId });

    const { patient } = response.data;
    yield put(fetchPatientSuccess({ patient }));

    if (target) {
      yield put(startPatientFetchedListening());
    }

  } catch (e) {
    yield put(fetchPatientFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create Patient
export function* watchCreatePatient() {
  yield takeLeading(CREATE_PATIENT, sagaCreatePatient);
}

export function* sagaCreatePatient({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreatePatient, payload);

    const { patient } = response.data;
    yield put(createPatientSuccess({ patient }));
    yield put(toastSaved({ name: `Patient` }));

    const { target } = payload;

    if (target) {
      yield put(startFirstLayerModalListening());
      yield put(closeFirstLayerModal());
      yield put(assignConsultationPatient({ patient}));
      yield put(fetchPatient({ patientId: patient.id }));

    } else {
      history.push(generateRoute(INDEX_PATIENTS_PATH));
      yield put(initPatient());
    }

  } catch (e) {
    yield put(createPatientFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update Patient
export function* watchUpdatePatient() {
  yield takeLeading(UPDATE_PATIENT, sagaUpdatePatient);
}

export function* sagaUpdatePatient({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdatePatient, payload);

    const { patient } = response.data;
    yield put(updatePatientSuccess({ patient }));
    yield put(toastSaved({ name: 'Patient' }));

    const { target } = payload;

    if (target) {
      yield put(startFirstLayerModalListening());
      yield put(closeFirstLayerModal());
      yield put(startSecondLayerModalListening());
      yield put(closeSecondLayerModal());
      yield put(fetchPatient({ patientId: patient.id }));

    } else {
      history.push(generateRoute(INDEX_PATIENTS_PATH));
      yield put(initPatient());
    }


  } catch (e) {
    yield put(updatePatientFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete Patient
export function* watchDeletePatient() {
  yield takeLeading(DELETE_PATIENT, sagaDeletePatient);
}

export function* sagaDeletePatient({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeletePatient, payload);

    const { patient } = response.data;
    yield put(deletePatientSuccess({ patient }));
    yield put(toastDeleted({ name: 'Patient' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deletePatientFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default patientSaga;

export const INIT_REVIEW = 'INIT_REVIEW';

export const CREATE_REVIEW = 'CREATE_REVIEW';
export const CREATE_REVIEW_SUCCESS = 'CREATE_REVIEW_SUCCESS';
export const CREATE_REVIEW_FAILED = 'CREATE_REVIEW_FAILED';

export const FETCH_REVIEW = 'FETCH_REVIEW';
export const FETCH_REVIEW_SUCCESS = 'FETCH_REVIEW_SUCCESS';
export const FETCH_REVIEW_FAILED = 'FETCH_REVIEW_FAILED';

export const FETCH_NEW_REVIEW = 'FETCH_NEW_REVIEW';

export const UPDATE_REVIEW = 'UPDATE_REVIEW';
export const UPDATE_REVIEW_SUCCESS = 'UPDATE_REVIEW_SUCCESS';
export const UPDATE_REVIEW_FAILED = 'UPDATE_REVIEW_FAILED';

export const DELETE_REVIEW = 'DELETE_REVIEW';
export const DELETE_REVIEW_SUCCESS = 'DELETE_REVIEW_SUCCESS';
export const DELETE_REVIEW_FAILED = 'DELETE_REVIEW_FAILED';

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_ASSISTANT_REPORTS } from "redux/model/assistantReports/assistantReports/constants";
import { apiListAssistantReports } from "api/assistantReport/assistantReportApi";
import { listAssistantReportsFailed, listAssistantReportsSuccess } from "redux/model/assistantReports/assistantReports/assistantReportsActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/allaroundmassage_frontend_common";


function* assistantReportsSaga() {
  yield all([
    fork(watchListAssistantReports),
  ]);
}

// List AssistantReports
export function* watchListAssistantReports() {
  yield takeEvery(LIST_ASSISTANT_REPORTS, sagaListAssistantReports);
}

export function* sagaListAssistantReports() {
  try {
    const response = yield call(apiListAssistantReports);

    yield put(listAssistantReportsSuccess({ assistantReports: response.data }));

  } catch (e) {
    yield put(listAssistantReportsFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default assistantReportsSaga;

import {
  ASSIGN_PROVIDER_NUMBER_CODE,
  ASSIGN_PROVIDER_NUMBER_INSURANCE_COMPANY, LIST_PROVIDER_NUMBERS_SUCCESS,
  NEW_PROVIDER_NUMBER,
  PULL_PROVIDER_NUMBER
} from "redux/model/providerNumbers/providerNumbers/constants";

export const newProviderNumber = ({ dummyId }) => ({
  type: NEW_PROVIDER_NUMBER,
  payload: { dummyId }
});

export const pullProviderNumber = ({ providerNumber }) => ({
  type: PULL_PROVIDER_NUMBER,
  payload: { providerNumber }
});

export const assignProviderNumberInsuranceCompany = ({ providerNumber, insuranceCompany }) => ({
  type: ASSIGN_PROVIDER_NUMBER_INSURANCE_COMPANY,
  payload: { providerNumber, insuranceCompany }
});

export const assignProviderNumberCode = ({ providerNumber, code }) => ({
  type: ASSIGN_PROVIDER_NUMBER_CODE,
  payload: { providerNumber, code }
});

export const listProviderNumbersSuccess = ({ providerNumbers }) => ({
  type: LIST_PROVIDER_NUMBERS_SUCCESS,
  payload: { providerNumbers }
});

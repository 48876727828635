import React from "react";
import { PrivateRoute } from "@tmatt-tech/allaroundmassage_frontend_common";
import { BASE_REVIEW_PATH, INDEX_REVIEW_PATH, NEW_REVIEW_PATH, EDIT_REVIEW_PATH } from "routes/review/constants";

const AllReview = React.lazy(() => import('pages/reviews/index/Reviews'));
const NewReview = React.lazy(() => import('pages/reviews/new/NewReview'));
const EditReview = React.lazy(() => import('pages/reviews/edit/EditReview'));

export const reviewRoutes = {
  path: BASE_REVIEW_PATH,
  name: 'Reviews',
  icon: 'dripicons-user-group',
  children: [
    {
      path: INDEX_REVIEW_PATH,
      name: 'All Reviews',
      component: AllReview,
      route: PrivateRoute,
    },
    {
      path: NEW_REVIEW_PATH,
      name: 'Add New',
      component: NewReview,
      route: PrivateRoute,
    },
  ],
};

export const hiddenReviewRoutes = {
  children: [
    {
      path: EDIT_REVIEW_PATH,
      name: 'Edit Review',
      component: EditReview,
      route: PrivateRoute,
    }
  ]
}

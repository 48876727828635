import {
  ASSIGN_INVOICE,
  ASSIGN_INVOICE_AMOUNT,
  ASSIGN_INVOICE_BEING_FOR,
  ASSIGN_INVOICE_DATE,
  ASSIGN_INVOICE_INVOICE_NUMBER,
  ASSIGN_INVOICE_ITEM_NUMBER,
  ASSIGN_INVOICE_NOTE,
  ASSIGN_INVOICE_PATIENT,
  ASSIGN_INVOICE_PROVIDER_MEMBERSHIP_NUMBER,
  ASSIGN_INVOICE_PROVIDER_NAME,
  ASSIGN_INVOICE_PROVIDER_NO,
  ASSIGN_INVOICE_SUM_OF,
  CREATE_INVOICE,
  CREATE_INVOICE_FAILED,
  CREATE_INVOICE_SUCCESS,
  DELETE_INVOICE,
  DELETE_INVOICE_FAILED,
  DELETE_INVOICE_SUCCESS,
  FETCH_INVOICE,
  FETCH_INVOICE_FAILED,
  FETCH_INVOICE_SUCCESS,
  FETCH_NEW_INVOICE,
  INIT_INVOICE,
  LIST_INVOICE_PATIENTS,
  LIST_INVOICE_PATIENTS_FAILED,
  LIST_INVOICE_PATIENTS_SUCCESS,
  NOTIFY_PATIENT_INVOICE,
  UPDATE_INVOICE,
  UPDATE_INVOICE_FAILED,
  UPDATE_INVOICE_SUCCESS
} from "redux/model/invoices/invoice/constants";

export const initInvoice = () => ({
  type: INIT_INVOICE,
  payload: {}
});

export const createInvoice = ({ invoice }) => ({
  type: CREATE_INVOICE,
  payload: { invoice }
});

export const createInvoiceSuccess = ({ invoice }) => ({
  type: CREATE_INVOICE_SUCCESS,
  payload: { invoice }
});

export const createInvoiceFailed = ({ error }) => ({
  type: CREATE_INVOICE_FAILED,
  payload: { error }
});

export const fetchInvoice = ({ invoiceId }) => ({
  type: FETCH_INVOICE,
  payload: { invoiceId }
});

export const fetchInvoiceSuccess = ({ invoice }) => ({
  type: FETCH_INVOICE_SUCCESS,
  payload: { invoice }
});

export const fetchInvoiceFailed = ({ error }) => ({
  type: FETCH_INVOICE_FAILED,
  payload: { error }
});

export const updateInvoice = ({ invoice }) => ({
  type: UPDATE_INVOICE,
  payload: { invoice }
});

export const updateInvoiceSuccess = ({ invoice }) => ({
  type: UPDATE_INVOICE_SUCCESS,
  payload: { invoice }
});

export const updateInvoiceFailed = ({ error }) => ({
  type: UPDATE_INVOICE_FAILED,
  payload: { error }
});

export const deleteInvoice = ({ invoice }) => ({
  type: DELETE_INVOICE,
  payload: { invoice }
});

export const deleteInvoiceSuccess = ({ invoice }) => ({
  type: DELETE_INVOICE_SUCCESS,
  payload: { invoice }
});

export const deleteInvoiceFailed = ({ error }) => ({
  type: DELETE_INVOICE_FAILED,
  payload: { error }
});

export const fetchNewInvoice = () => ({
  type: FETCH_NEW_INVOICE,
  payload: {}
});

export const notifyPatientInvoice = ({ invoice }) => ({
  type: NOTIFY_PATIENT_INVOICE,
  payload: { invoice }
});

export const assignInvoiceInvoiceNumber = ({ invoiceNumber }) => ({
  type: ASSIGN_INVOICE_INVOICE_NUMBER,
  payload: { invoiceNumber }
});

export const assignInvoiceDate = ({ date }) => ({
  type: ASSIGN_INVOICE_DATE,
  payload: { date }
});

export const assignInvoicePatient = ({ patient }) => ({
  type: ASSIGN_INVOICE_PATIENT,
  payload: { patient }
});

export const assignInvoiceSumOf = ({ sumOf }) => ({
  type: ASSIGN_INVOICE_SUM_OF,
  payload: { sumOf }
});

export const assignInvoiceBeingFor = ({ beingFor }) => ({
  type: ASSIGN_INVOICE_BEING_FOR,
  payload: { beingFor }
});

export const assignInvoiceProviderNo = ({ providerNo }) => ({
  type: ASSIGN_INVOICE_PROVIDER_NO,
  payload: { providerNo }
});

export const assignInvoiceProviderName = ({ providerName }) => ({
  type: ASSIGN_INVOICE_PROVIDER_NAME,
  payload: { providerName }
});

export const assignInvoiceProviderMembershipNumber = ({ providerMembershipNumber }) => ({
  type: ASSIGN_INVOICE_PROVIDER_MEMBERSHIP_NUMBER,
  payload: { providerMembershipNumber }
});

export const assignInvoiceItemNumber = ({ itemNumber }) => ({
  type: ASSIGN_INVOICE_ITEM_NUMBER,
  payload: { itemNumber }
});

export const assignInvoiceAmount = ({ fields }) => ({
  type: ASSIGN_INVOICE_AMOUNT,
  payload: { fields }
});

export const assignInvoiceNote = ({ note }) => ({
  type: ASSIGN_INVOICE_NOTE,
  payload: { note }
});

export const assignInvoice = ({ fields }) => ({
  type: ASSIGN_INVOICE,
  payload: { fields }
});


export const listInvoicePatients = ({ query }) => ({
  type: LIST_INVOICE_PATIENTS,
  payload: { query }
});

export const listInvoicePatientsSuccess = ({}) => ({
  type: LIST_INVOICE_PATIENTS_SUCCESS,
  payload: {}
});

export const listInvoicePatientsFailed = ({ error }) => ({
  type: LIST_INVOICE_PATIENTS_FAILED,
  payload: { error }
});
import {
  ASSIGN_INVOICE,
  ASSIGN_INVOICE_AMOUNT,
  ASSIGN_INVOICE_BEING_FOR,
  ASSIGN_INVOICE_DATE,
  ASSIGN_INVOICE_INVOICE_NUMBER,
  ASSIGN_INVOICE_ITEM_NUMBER,
  ASSIGN_INVOICE_NOTE,
  ASSIGN_INVOICE_PATIENT,
  ASSIGN_INVOICE_PROVIDER_MEMBERSHIP_NUMBER,
  ASSIGN_INVOICE_PROVIDER_NAME,
  ASSIGN_INVOICE_PROVIDER_NO,
  ASSIGN_INVOICE_SUM_OF,
  FETCH_INVOICE,
  FETCH_INVOICE_FAILED,
  FETCH_INVOICE_SUCCESS,
  INIT_INVOICE,
  LIST_INVOICE_PATIENTS,
  LIST_INVOICE_PATIENTS_FAILED,
  LIST_INVOICE_PATIENTS_SUCCESS
} from "redux/model/invoices/invoice/constants";

const INIT_STATE = {
  invoice: {},
  loading: true,
  error: null,
};

const invoiceReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_INVOICE:
      return INIT_STATE;

    case FETCH_INVOICE:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        invoice: action.payload.invoice,
      };

    case FETCH_INVOICE_FAILED:
      return {
        ...state,
        invoice: {},
        loading: false,
        error: action.payload.error,
      };

    
    case LIST_INVOICE_PATIENTS:
      return {
        ...state,
        loading: true,
        error: null,
      }
    
    case LIST_INVOICE_PATIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      }

    case LIST_INVOICE_PATIENTS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    case ASSIGN_INVOICE_INVOICE_NUMBER:
      return {
        ...state,
        invoice: {
          ...state.invoice,
          invoiceNumber: action.payload.invoiceNumber,
        },
      };

    case ASSIGN_INVOICE_DATE:
      return {
        ...state,
        invoice: {
          ...state.invoice,
          date: action.payload.date,
        },
      };

    case ASSIGN_INVOICE_PATIENT:
      return {
        ...state,
        invoice: {
          ...state.invoice,
          patientId: action.payload.patient.id,
        },
      };

    case ASSIGN_INVOICE_SUM_OF:
      return {
        ...state,
        invoice: {
          ...state.invoice,
          sumOf: action.payload.sumOf,
        },
      };

    case ASSIGN_INVOICE_BEING_FOR:
      return {
        ...state,
        invoice: {
          ...state.invoice,
          beingFor: action.payload.beingFor,
        },
      };

    case ASSIGN_INVOICE_PROVIDER_NO:
      return {
        ...state,
        invoice: {
          ...state.invoice,
          providerNo: action.payload.providerNo,
        },
      };

    case ASSIGN_INVOICE_PROVIDER_NAME:
      return {
        ...state,
        invoice: {
          ...state.invoice,
          providerName: action.payload.providerName,
        },
      };
    case ASSIGN_INVOICE_PROVIDER_MEMBERSHIP_NUMBER:
      return {
        ...state,
        invoice: {
          ...state.invoice,
          providerMembershipNumber: action.payload.providerMembershipNumber,
        },
      };

    case ASSIGN_INVOICE_ITEM_NUMBER:
      return {
        ...state,
        invoice: {
          ...state.invoice,
          itemNumber: action.payload.itemNumber,
        },
      };

    case ASSIGN_INVOICE_AMOUNT:
      const invoice = {
        ...state.invoice,
        ...action.payload.fields,
      };

      const insPaid = parseFloat(invoice.insPaid) || 0;
      const patPaid = parseFloat(invoice.patPaid) || 0;
      const gst = parseFloat(invoice.gst) || 0;

      return {
        ...state,
        invoice: {
          ...invoice,
          amount: insPaid + patPaid + gst,
        },
      };

    case ASSIGN_INVOICE_NOTE:
      return {
        ...state,
        invoice: {
          ...state.invoice,
          note: action.payload.note,
        },
      };

    case ASSIGN_INVOICE:
      return {
        ...state,
        invoice: {
          ...state.invoice,
          ...action.payload.fields,
        }
      }

    default:
      return { ...state };
  }
};

export default invoiceReducer;

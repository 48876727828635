import { ERROR_FORM_CONSULTATION, LOADING_FORM_CONSULTATION, RESET_FORM_CONSULTATION } from "redux/ui/form/consultation/constants";
import {
  CREATE_CONSULTATION,
  CREATE_CONSULTATION_FAILED,
  CREATE_CONSULTATION_SUCCESS, LINK_CONSULTATION, LINK_CONSULTATION_FAILED, LINK_CONSULTATION_SUCCESS,
  UPDATE_CONSULTATION, UPDATE_CONSULTATION_FAILED, UPDATE_CONSULTATION_SUCCESS
} from "redux/model/consultations/consultation/constants";


const INIT_STATE = {
  loading: false,
  error: null,
};

const formConsultationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {

    case LOADING_FORM_CONSULTATION:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ERROR_FORM_CONSULTATION:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case RESET_FORM_CONSULTATION:
      return {
        ...state,
        loading: false,
        error: null
      };

    case CREATE_CONSULTATION:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_CONSULTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_CONSULTATION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_CONSULTATION:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_CONSULTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case UPDATE_CONSULTATION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case LINK_CONSULTATION:
      return {
        ...state,
        loading: true,
        error: null
      };
    case LINK_CONSULTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case LINK_CONSULTATION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    default:
      return { ...state };
  }
};

export default formConsultationReducer;

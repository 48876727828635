import { LIST_SURVEYS, LIST_SURVEYS_FAILED, LIST_SURVEYS_SUCCESS } from "redux/model/surveys/surveys/constants";

export const listSurveys = () => ({
  type: LIST_SURVEYS,
  payload: {}
});

export const listSurveysSuccess = ({ surveys }) => ({
  type: LIST_SURVEYS_SUCCESS,
  payload: { surveys }
});

export const listSurveysFailed = ({ error }) => ({
  type: LIST_SURVEYS_FAILED,
  payload: { error }
});

import { combineReducers } from 'redux';
import assistantReportReducers from "redux/model/assistantReports/assistantReport/assistantReportReducers";
import assistantReportsReducers from "redux/model/assistantReports/assistantReports/assistantReportsReducers";
import practitionersReducers from "redux/model/practitioners/practitioners/practitionersReducers";
import practitionerReportReducers from "redux/model/practitionerReports/practitionerReport/practitionerReportReducers";
import practitionerReportItemsReducers
  from "redux/model/practitionerReportItems/practitionerReportItems/practitionerReportItemsReducers";
import practitionerReportsReducers
  from "redux/model/practitionerReports/practitionerReports/practitionerReportsReducers";
import invoiceReducers from "redux/model/invoices/invoice/invoiceReducers";
import invoicesReducers from "redux/model/invoices/invoices/invoicesReducers";
import clinicReducers from "redux/model/clinics/clinic/clinicReducers";
import patientReducers from "redux/model/patients/patient/patientReducers";
import patientsReducers from "redux/model/patients/patients/patientsReducers";
import practitionerReducers from "redux/model/practitioners/practitioner/practitionerReducers";
import consultationReducers from "redux/model/consultations/consultation/consultationReducers";
import consultationsReducers from "redux/model/consultations/consultations/consultationsReducers";
import reviewReducers from "redux/model/reviews/review/reviewReducers";
import reviewsReducers from "redux/model/reviews/reviews/reviewsReducers";

import giftCardReducers from "redux/model/giftCards/giftCard/giftCardReducers";
import giftCardsReducers from "redux/model/giftCards/giftCards/giftCardsReducers";
import surveyReducers from "redux/model/surveys/survey/surveyReducers";
import surveysReducers from "redux/model/surveys/surveys/surveysReducers";
import providerReducers from "redux/model/providers/provider/providerReducers";
import providersReducers from "redux/model/providers/providers/providersReducers";
import providerNumbersReducers from "redux/model/providerNumbers/providerNumbers/providerNumbersReducers";
import noticesReducers from "redux/model/notices/notices/noticesReducers";
import transactionsReducers from "redux/model/transactions/transactions/transactionsReducers";

export default combineReducers({
  clinic: clinicReducers,
  assistantReport: assistantReportReducers,
  assistantReports: assistantReportsReducers,
  practitionerReportItems: practitionerReportItemsReducers,
  practitionerReport: practitionerReportReducers,
  practitionerReports: practitionerReportsReducers,
  invoice: invoiceReducers,
  invoices: invoicesReducers,
  patient: patientReducers,
  patients: patientsReducers,
  review: reviewReducers,
  reviews: reviewsReducers,
  consultation: consultationReducers,
  consultations: consultationsReducers,
  practitioner: practitionerReducers,
  practitioners: practitionersReducers,
  giftCard: giftCardReducers,
  giftCards: giftCardsReducers,
  survey: surveyReducers,
  surveys: surveysReducers,
  provider: providerReducers,
  providers: providersReducers,
  providerNumbers: providerNumbersReducers,
  notices: noticesReducers,
  transactions: transactionsReducers,
});

import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import {
  generateErrorMessage,
  generateRoute,
  history,
  throwFrontError
} from "@tmatt-tech/allaroundmassage_frontend_common";
import {
  CREATE_INVOICE,
  DELETE_INVOICE,
  FETCH_INVOICE,
  FETCH_NEW_INVOICE,
  LIST_INVOICE_PATIENTS,
  NOTIFY_PATIENT_INVOICE,
  UPDATE_INVOICE
} from "redux/model/invoices/invoice/constants";
import {
  apiCreateInvoice,
  apiDeleteInvoice,
  apiFetchInvoice,
  apiFetchNewInvoice,
  apiListInvoicePatient,
  apiNotifyPatientInvoice,
  apiUpdateInvoice
} from "api/invoice/invoiceApi";
import {
  createInvoiceFailed,
  createInvoiceSuccess,
  deleteInvoiceFailed,
  deleteInvoiceSuccess,
  fetchInvoiceFailed,
  fetchInvoiceSuccess,
  initInvoice,
  updateInvoiceFailed,
  updateInvoiceSuccess,
  listInvoicePatientsFailed,
  listInvoicePatientsSuccess
} from "redux/model/invoices/invoice/invoiceActions";
import {
  toastDefault,
  toastDeleted,
  toastError,
  toastSaved
} from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeSecondLayerModal,
  startSecondLayerModalListening
} from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/modal/modalActions";
import { EDIT_INVOICE_PATH } from "routes/invoice/constants";
import { listPatientsSuccess } from "redux/model/patients/patients/patientsActions";


function* invoiceSaga() {
  yield all([
    fork(watchFetchInvoice),
    fork(watchFetchNewInvoice),
    fork(watchUpdateInvoice),
    fork(watchCreateInvoice),
    fork(watchDeleteInvoice),
    fork(watchNotifyPatientInvoice),
    fork(watchLisInvoicePatients),
  ]);
}

// Search Patients
export function* watchLisInvoicePatients() {
  yield takeEvery(LIST_INVOICE_PATIENTS, sagaLisInvoicePatients);
}

export function* sagaLisInvoicePatients({ payload }) {
  try {
    const response = yield call(apiListInvoicePatient, payload);
    const { patients } = response.data;
    yield put(listPatientsSuccess({ patients }));
    yield put(listInvoicePatientsSuccess({}));

    // 使用模板字符串插入 patients 数量
    yield put(toastDefault({ message: `${patients.length} patients loaded.` }));

  } catch (e) {
    yield put(listInvoicePatientsFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Fetch Invoice
export function* watchFetchInvoice() {
  yield takeEvery(FETCH_INVOICE, sagaFetchInvoice);
}

export function* sagaFetchInvoice({ payload }) {
  try {
    const response = yield call(apiFetchInvoice, payload);
    const { invoice, patients } = response.data;

    yield put(listPatientsSuccess({ patients }));
    yield put(fetchInvoiceSuccess({ invoice }));

  } catch (e) {
    yield put(fetchInvoiceFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Fetch New Invoice
export function* watchFetchNewInvoice() {
  yield takeEvery(FETCH_NEW_INVOICE, sagaFetchNewInvoice);
}

export function* sagaFetchNewInvoice() {
  try {
    const response = yield call(apiFetchNewInvoice);
    const { invoice, patients } = response.data;

    yield put(listPatientsSuccess({ patients }));
    yield put(fetchInvoiceSuccess({ invoice }));

  } catch (e) {
    yield put(fetchInvoiceFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create Invoice
export function* watchCreateInvoice() {
  yield takeLeading(CREATE_INVOICE, sagaCreateInvoice);
}

export function* sagaCreateInvoice({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateInvoice, payload);

    yield put(createInvoiceSuccess({ invoice: response.data }));
    yield put(toastSaved({ name: response.data.invoiceNumber }));
    history.push(generateRoute(EDIT_INVOICE_PATH, response.data.id));
    yield put(initInvoice());


  } catch (e) {
    yield put(createInvoiceFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update Invoice
export function* watchUpdateInvoice() {
  yield takeLeading(UPDATE_INVOICE, sagaUpdateInvoice);
}

export function* sagaUpdateInvoice({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateInvoice, payload);

    yield put(updateInvoiceSuccess({ invoice: response.data }));
    yield put(toastSaved({ name: response.data.invoiceNumber }));

  } catch (e) {
    yield put(updateInvoiceFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete Invoice
export function* watchDeleteInvoice() {
  yield takeLeading(DELETE_INVOICE, sagaDeleteInvoice);
}

export function* sagaDeleteInvoice({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeleteInvoice, payload);

    yield put(deleteInvoiceSuccess({ invoice: response.data }));
    yield put(toastDeleted({ name: response.data.invoiceNumber }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deleteInvoiceFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


// Notify Patient Invoice
export function* watchNotifyPatientInvoice() {
  yield takeEvery(NOTIFY_PATIENT_INVOICE, sagaNotifyPatientInvoice);
}

export function* sagaNotifyPatientInvoice({ payload }) {
  try {
    yield call(apiNotifyPatientInvoice, payload);

    yield put(toastDefault({ message: 'Email Sent' }))
  } catch (e) {
    yield put(toastError({}));

    throwFrontError(e);
  }
}


export default invoiceSaga;

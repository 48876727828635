import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/allaroundmassage_frontend_common";
import {
  CREATE_PROVIDER,
  DELETE_PROVIDER,
  FETCH_PROVIDER,
  UPDATE_PROVIDER
} from "redux/model/providers/provider/constants";
import { apiCreateProvider, apiDeleteProvider, apiFetchProvider, apiUpdateProvider } from "api/provider/providerApi";
import {
  createProviderFailed,
  createProviderSuccess,
  deleteProviderFailed,
  deleteProviderSuccess,
  fetchProviderFailed,
  fetchProviderSuccess,
  updateProviderFailed,
  updateProviderSuccess
} from "redux/model/providers/provider/providerActions";
import {
  toastDeleted,
  toastError,
  toastSaved
} from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeFirstLayerModal,
  closeSecondLayerModal,
  startFirstLayerModalListening, startSecondLayerModalListening
} from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/modal/modalActions";
import { listProviderNumbersSuccess } from "redux/model/providerNumbers/providerNumbers/providerNumbersActions";


function* providerSaga() {
  yield all([
    fork(watchFetchProvider),
    fork(watchUpdateProvider),
    fork(watchCreateProvider),
    fork(watchDeleteProvider),
  ]);
}

// Fetch Provider
export function* watchFetchProvider() {
  yield takeEvery(FETCH_PROVIDER, sagaFetchProvider);
}

export function* sagaFetchProvider({ payload }) {
  try {
    const response = yield call(apiFetchProvider, payload);
    const { provider, providerNumbers } = response.data;

    yield put(fetchProviderSuccess({ provider }));
    yield put(listProviderNumbersSuccess({ providerNumbers }));

  } catch (e) {
    yield put(fetchProviderFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create Provider
export function* watchCreateProvider() {
  yield takeLeading(CREATE_PROVIDER, sagaCreateProvider);
}

export function* sagaCreateProvider({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateProvider, payload);
    const { provider } = response.data;

    yield put(createProviderSuccess({ provider }));
    yield put(toastSaved({ name: provider.name }));

    yield put(startFirstLayerModalListening());
    yield put(closeFirstLayerModal());

  } catch (e) {
    yield put(createProviderFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update Provider
export function* watchUpdateProvider() {
  yield takeLeading(UPDATE_PROVIDER, sagaUpdateProvider);
}

export function* sagaUpdateProvider({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateProvider, payload);

    const { provider } = response.data;

    yield put(updateProviderSuccess({ provider }));
    yield put(toastSaved({ name: provider.name }));

    yield put(startFirstLayerModalListening());
    yield put(closeFirstLayerModal());

  } catch (e) {
    yield put(updateProviderFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete Provider
export function* watchDeleteProvider() {
  yield takeLeading(DELETE_PROVIDER, sagaDeleteProvider);
}

export function* sagaDeleteProvider({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeleteProvider, payload);

    yield put(deleteProviderSuccess({ provider: response.data }));
    yield put(toastDeleted({ name: response.data.name }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deleteProviderFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default providerSaga;

import { combineReducers } from 'redux';
import formPractitionerReportReducers from "redux/ui/form/practitionerReport/formPractitionerReportReducers";
import formInvoiceReducers from "redux/ui/form/invoice/formInvoiceReducers";
import formPatientReducers from "redux/ui/form/patient/formPatientReducers";
import formConsultationReducers from "redux/ui/form/consultation/formConsultationReducers";
import formReviewReducers from "redux/ui/form/review/formReviewReducers";
import formGiftCardReducers from "redux/ui/form/giftCard/formGiftCardReducers";
import formSurveyReducers from "redux/ui/form/survey/formSurveyReducers";
import formProviderReducers from "redux/ui/form/provider/formProviderReducers";

export default combineReducers({
  practitionerReport: formPractitionerReportReducers,
  invoice: formInvoiceReducers,
  patient: formPatientReducers,
  practitioner: formPractitionerReportReducers,
  consultation: formConsultationReducers,
  review: formReviewReducers,
  giftCard: formGiftCardReducers,
  survey: formSurveyReducers,
  provider: formProviderReducers,
});

import { axios } from "@tmatt-tech/allaroundmassage_frontend_common";
import { SIGN_IN_STAFF_API } from "api/staffAuth/constant";

export const apiSignInStaff = ({ mobile, password }) => {
  const { url, method } = SIGN_IN_STAFF_API;

  return axios({
    method,
    url,
    data: {
      account: mobile,
      password,
    }
  });
};

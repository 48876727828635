import {
  CREATE_ASSISTANT_REPORT,
  CREATE_ASSISTANT_REPORT_FAILED,
  CREATE_ASSISTANT_REPORT_SUCCESS,
  FETCH_ASSISTANT_REPORT,
  FETCH_ASSISTANT_REPORT_FAILED,
  FETCH_ASSISTANT_REPORT_SUCCESS, FETCH_TODAY_ASSISTANT_REPORT,
  INIT_ASSISTANT_REPORT, LOCK_ASSISTANT_REPORT, LOCK_ASSISTANT_REPORT_FAILED, LOCK_ASSISTANT_REPORT_SUCCESS,
  UPDATE_ASSISTANT_REPORT,
  UPDATE_ASSISTANT_REPORT_FAILED,
  UPDATE_ASSISTANT_REPORT_SUCCESS
} from "redux/model/assistantReports/assistantReport/constants";

export const initAssistantReport = () => ({
  type: INIT_ASSISTANT_REPORT,
  payload: {}
});

export const createAssistantReport = ({ assistantReport }) => ({
  type: CREATE_ASSISTANT_REPORT,
  payload: { assistantReport }
});

export const createAssistantReportSuccess = ({ assistantReport }) => ({
  type: CREATE_ASSISTANT_REPORT_SUCCESS,
  payload: { assistantReport }
});

export const createAssistantReportFailed = ({ error }) => ({
  type: CREATE_ASSISTANT_REPORT_FAILED,
  payload: { error }
});

export const fetchAssistantReport = ({ assistantReportId }) => ({
  type: FETCH_ASSISTANT_REPORT,
  payload: { assistantReportId }
});

export const fetchAssistantReportSuccess = ({ current }) => ({
  type: FETCH_ASSISTANT_REPORT_SUCCESS,
  payload: { current }
});

export const fetchAssistantReportFailed = ({ error }) => ({
  type: FETCH_ASSISTANT_REPORT_FAILED,
  payload: { error }
});

export const updateAssistantReport = ({ assistantReport }) => ({
  type: UPDATE_ASSISTANT_REPORT,
  payload: { assistantReport }
});

export const updateAssistantReportSuccess = ({ assistantReport }) => ({
  type: UPDATE_ASSISTANT_REPORT_SUCCESS,
  payload: { assistantReport }
});

export const updateAssistantReportFailed = ({ error }) => ({
  type: UPDATE_ASSISTANT_REPORT_FAILED,
  payload: { error }
});

export const fetchTodayAssistantReport = () => ({
  type: FETCH_TODAY_ASSISTANT_REPORT,
  payload: {}
});

export const lockAssistantReport = ({ assistantReport, currentStaff }) => ({
  type: LOCK_ASSISTANT_REPORT,
  payload: { assistantReport, currentStaff }
});

export const lockAssistantReportSuccess = () => ({
  type: LOCK_ASSISTANT_REPORT_SUCCESS,
  payload: {}
});

export const lockAssistantReportFailed = ({ error }) => ({
  type: LOCK_ASSISTANT_REPORT_FAILED,
  payload: { error }
});

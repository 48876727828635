import { ERROR_FORM_REVIEW, LOADING_FORM_REVIEW, RESET_FORM_REVIEW } from "redux/ui/form/review/constants";
import {
  CREATE_REVIEW,
  CREATE_REVIEW_FAILED,
  CREATE_REVIEW_SUCCESS,
  UPDATE_REVIEW, UPDATE_REVIEW_FAILED, UPDATE_REVIEW_SUCCESS
} from "redux/model/reviews/review/constants";


const INIT_STATE = {
  loading: false,
  error: null,
};

const formReviewReducer = (state = INIT_STATE, action) => {
  switch (action.type) {

    case LOADING_FORM_REVIEW:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ERROR_FORM_REVIEW:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case RESET_FORM_REVIEW:
      return {
        ...state,
        loading: false,
        error: null
      };

    case CREATE_REVIEW:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_REVIEW_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_REVIEW:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case UPDATE_REVIEW_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    default:
      return { ...state };
  }
};

export default formReviewReducer;

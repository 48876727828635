import _ from 'lodash';
import { LIST_NOTICES, LIST_NOTICES_FAILED, LIST_NOTICES_SUCCESS } from "redux/model/notices/notices/constants";
import { RECEIVE_NOTICE_SUCCESS } from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/notification/notificationConstants";

const INIT_STATE = {
  notices: {},
  loading: true,
  error: null,
};

const noticesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_NOTICES:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_NOTICES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        notices: _.mapKeys(action.payload.notices, 'id'),
      };

    case LIST_NOTICES_FAILED:
      return {
        ...state,
        notices: {},
        loading: false,
        error: action.payload.error,
      };

    case RECEIVE_NOTICE_SUCCESS:
      return {
        ...state,
        notices: {
          [action.payload.receivedNotice.id]: action.payload.receivedNotice,
          ...state.notices,
        }
      }

    default:
      return { ...state };
  }
};

export default noticesReducer;

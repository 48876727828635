import { axios } from "@tmatt-tech/allaroundmassage_frontend_common";
import { LIST_NOTICE_API } from "api/notice/constants";

export const apiListNotices = () => {
  const { url, method } = LIST_NOTICE_API;

  return axios({
    method,
    url,
  });
};

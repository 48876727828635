export const FETCH_CLINIC_API = {
  url: `/api/clinics/:id`,
  method: 'get',
}

export const FETCH_CURRENT_CLINIC_API = {
  url: `/api/clinic/current`,
  method: 'get',
}

export const UPDATE_CLINIC_API = {
  url: `/api/clinics/:id`,
  method: 'put'
}

export const CLINIC_CHECK_STAFF_IN_API = {
  url: `/api/clinic/check_staff_in`,
  method: 'post',
}

export const CLINIC_CHECK_STAFF_OUT_API = {
  url: `/api/clinic/check_staff_out`,
  method: 'post',
}

import React from "react";
import { PrivateRoute } from "@tmatt-tech/allaroundmassage_frontend_common";
import {
  BASE_ASSISTANT_REPORT_PATH,
  INDEX_ASSISTANT_REPORT_PATH,
  NEW_ASSISTANT_REPORT_PATH,
  SHOW_ASSISTANT_REPORT_PATH
} from "routes/assistantReport/constants";

const IndexAssistantReport = React.lazy(() => import('pages/assistantReports/index/AssistantReports'));
const NewAssistantReport = React.lazy(() => import('pages/assistantReports/new/NewAssistantReport'));
const ShowAssistantReport = React.lazy(() => import('pages/assistantReports/show/ShowAssistantReport'));

export const assistantReportRoutes = {
  path: BASE_ASSISTANT_REPORT_PATH,
  name: 'Reports',
  icon: 'uil uil-schedule',
  header: "APPS",
  children: [
    {
      path: INDEX_ASSISTANT_REPORT_PATH,
      name: 'All Reports',
      component: IndexAssistantReport,
      route: PrivateRoute,
    },
    {
      path: NEW_ASSISTANT_REPORT_PATH,
      name: 'Add New',
      component: NewAssistantReport,
      route: PrivateRoute,
    },
  ],
};

export const hiddenAssistantReportRoutes = {
  children: [
    {
      path: SHOW_ASSISTANT_REPORT_PATH,
      name: 'Show Report',
      component: ShowAssistantReport,
      route: PrivateRoute,
    }
  ]
}


// import React from "react";
// import { PrivateRoute } from "@tmatt-tech/allaroundmassage_frontend_common";
// import { NEW_ASSISTANT_REPORT_PATH, TODAY_ASSISTANT_REPORT_PATH } from "routes/assistantReport/constants";

// // const IndexAssistantReport = React.lazy(() => import('pages/assistantReports/index/AssistantReports'));
// const NewAssistantReport = React.lazy(() => import('pages/assistantReports/new/NewAssistantReport'));
// const ShowTodayAssistantReport = React.lazy(() => import('pages/assistantReports/today/ShowTodayAssistantReport'));
// // const ShowAssistantReport = React.lazy(() => import('pages/assistantReports/show/ShowAssistantReport'));

// export const assistantReportRoutes = {
//   path: TODAY_ASSISTANT_REPORT_PATH,
//   name: 'Reports',
//   icon: 'uil uil-schedule',
//   header: "APPS",
//   route: PrivateRoute,
//   component: ShowTodayAssistantReport,
//   // children: [
//   //   {
//   //     path: INDEX_ASSISTANT_REPORT_PATH,
//   //     name: 'All Reports',
//   //     component: IndexAssistantReport,
//   //     route: PrivateRoute,
//   //   },
//   //   {
//   //     path: NEW_ASSISTANT_REPORT_PATH,
//   //     name: 'Add New',
//   //     component: NewAssistantReport,
//   //     route: PrivateRoute,
//   //   },
//   // ],
// };

// export const hiddenAssistantReportRoutes = {
//   children: [
//     {
//       path: NEW_ASSISTANT_REPORT_PATH,
//       name: 'Add New',
//       component: NewAssistantReport,
//       route: PrivateRoute,
//     },
//     // {
//     //   path: SHOW_ASSISTANT_REPORT_PATH,
//     //   name: 'Show Report',
//     //   component: ShowAssistantReport,
//     //   route: PrivateRoute,
//     // }
//   ]
// }

import { INIT_PRACTITIONER_REPORTS } from "redux/model/practitionerReports/practitionerReports/constants";
import {
  FETCH_ASSISTANT_REPORT_SUCCESS,
  INIT_ASSISTANT_REPORT
} from "redux/model/assistantReports/assistantReport/constants";
import _ from "lodash";
import {
  CREATE_PRACTITIONER_REPORT_SUCCESS,
  UPDATE_PRACTITIONER_REPORT_SUCCESS
} from "redux/model/practitionerReports/practitionerReport/constants";

const INIT_STATE = {
  practitionerReports: {},
  loading: true,
  error: null,
};

const practitionerReportsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_PRACTITIONER_REPORTS:
      return INIT_STATE;

    case CREATE_PRACTITIONER_REPORT_SUCCESS:
      return {
        loading: false,
        error: null,
        practitionerReports: {
          ...state.practitionerReports,
          [action.payload.current.practitionerReport.id]: action.payload.current.practitionerReport,
        },
      };

    case UPDATE_PRACTITIONER_REPORT_SUCCESS:
      return {
        loading: false,
        error: null,
        practitionerReports: {
          ...state.practitionerReports,
          [action.payload.current.practitionerReport.id]: action.payload.current.practitionerReport,
        },
      };

    case FETCH_ASSISTANT_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        practitionerReports: _.mapKeys(action.payload.current.practitionerReports, 'id'),
      };

    case INIT_ASSISTANT_REPORT:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default practitionerReportsReducer;

import {
  FETCH_PRACTITIONER,
  FETCH_PRACTITIONER_FAILED,
  FETCH_PRACTITIONER_SUCCESS,
  INIT_PRACTITIONER
} from "redux/model/practitioners/practitioner/constants";

const INIT_STATE = {
  practitioner: {},
  loading: true,
  error: null,
};

const practitionerReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_PRACTITIONER:
      return INIT_STATE;

    case FETCH_PRACTITIONER:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_PRACTITIONER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        practitioner: action.payload.practitioner,
      };

    case FETCH_PRACTITIONER_FAILED:
      return {
        ...state,
        practitioner: {},
        loading: false,
        error: action.payload.error,
      };

    default:
      return { ...state };
  }
};

export default practitionerReducer;


export const CREATE_ASSISTANT_REPORT_API = {
  url: `/api/assistant_reports`,
  method: 'post'
};

export const LIST_ASSISTANT_REPORT_API = {
  url: `/api/assistant_reports`,
  method: 'get',
}

export const FETCH_ASSISTANT_REPORT_API = {
  url: `/api/assistant_reports/:id`,
  method: 'get',
}

export const UPDATE_ASSISTANT_REPORT_API = {
  url: `/api/assistant_reports/:id`,
  method: 'put'
}

export const FETCH_TODAY_ASSISTANT_REPORT_API = {
  url: `/api/assistant_report/today`,
  method: 'get',
}

export const LOCK_ASSISTANT_REPORT_API = {
  url: `/api/assistant_report/lock/:id`,
  method: 'post'
};

import _ from 'lodash';
import {
  CREATE_SURVEY_SUCCESS,
  DELETE_SURVEY_SUCCESS,
  UPDATE_SURVEY_SUCCESS
} from "redux/model/surveys/survey/constants";
import { LIST_SURVEYS, LIST_SURVEYS_FAILED, LIST_SURVEYS_SUCCESS } from "redux/model/surveys/surveys/constants";

const INIT_STATE = {
  surveys: {},
  loading: true,
  error: null,
};

const surveysReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_SURVEYS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_SURVEYS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        surveys: _.mapKeys(action.payload.surveys, 'id'),
      };

    case LIST_SURVEYS_FAILED:
      return {
        ...state,
        surveys: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_SURVEY_SUCCESS:
      return {
        ...state,
        surveys: {
          [action.payload.survey.id]: action.payload.survey,
          ...state.surveys,
        },
      };

    case UPDATE_SURVEY_SUCCESS:
      return {
        ...state,
        surveys: {
          ...state.surveys,
          [action.payload.survey.id]: action.payload.survey,
        },
      };

    case DELETE_SURVEY_SUCCESS:
      return {
        ...state,
        surveys: _.omit(state.surveys, action.payload.survey.id)
      };

    default:
      return { ...state };
  }
};

export default surveysReducer;

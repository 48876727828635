export const INIT_CONSULTATION = 'INIT_CONSULTATION';

export const CREATE_CONSULTATION = 'CREATE_CONSULTATION';
export const CREATE_CONSULTATION_SUCCESS = 'CREATE_CONSULTATION_SUCCESS';
export const CREATE_CONSULTATION_FAILED = 'CREATE_CONSULTATION_FAILED';

export const FETCH_CONSULTATION = 'FETCH_CONSULTATION';
export const FETCH_NEW_CONSULTATION = 'FETCH_NEW_CONSULTATION';
export const FETCH_CONSULTATION_SUCCESS = 'FETCH_CONSULTATION_SUCCESS';
export const FETCH_CONSULTATION_FAILED = 'FETCH_CONSULTATION_FAILED';

export const UPDATE_CONSULTATION = 'UPDATE_CONSULTATION';
export const UPDATE_CONSULTATION_SUCCESS = 'UPDATE_CONSULTATION_SUCCESS';
export const UPDATE_CONSULTATION_FAILED = 'UPDATE_CONSULTATION_FAILED';

export const DELETE_CONSULTATION = 'DELETE_CONSULTATION';
export const DELETE_CONSULTATION_SUCCESS = 'DELETE_CONSULTATION_SUCCESS';
export const DELETE_CONSULTATION_FAILED = 'DELETE_CONSULTATION_FAILED';

export const ASSIGN_CONSULTATION_DATETIME = 'ASSIGN_CONSULTATION_DATETIME';
export const ASSIGN_CONSULTATION_PATIENT = 'ASSIGN_CONSULTATION_PATIENT';
export const ASSIGN_CONSULTATION_MUSCULOSKELETAL = 'ASSIGN_CONSULTATION_MUSCULOSKELETAL';
export const ASSIGN_CONSULTATION_NERVOUS = 'ASSIGN_CONSULTATION_NERVOUS';
export const ASSIGN_CONSULTATION_CARDIOVASCULAR = 'ASSIGN_CONSULTATION_CARDIOVASCULAR';
export const ASSIGN_CONSULTATION_RESPIRATORY = 'ASSIGN_CONSULTATION_RESPIRATORY';
export const ASSIGN_CONSULTATION_REACTIONS = 'ASSIGN_CONSULTATION_REACTIONS';
export const ASSIGN_CONSULTATION_REACTIONS_NOTE = 'ASSIGN_CONSULTATION_REACTIONS_NOTE';
export const ASSIGN_CONSULTATION_OTHER_CONDITIONS = 'ASSIGN_CONSULTATION_OTHER_CONDITIONS';
export const ASSIGN_CONSULTATION_OTHER_CONDITIONS_NOTE = 'ASSIGN_CONSULTATION_OTHER_CONDITIONS_NOTE';
export const ASSIGN_CONSULTATION_MEDICATION = 'ASSIGN_CONSULTATION_MEDICATION';
export const ASSIGN_CONSULTATION_PAIN_OR_TENDERNESS = 'ASSIGN_CONSULTATION_PAIN_OR_TENDERNESS';
export const ASSIGN_CONSULTATION_STIFFNESS = 'ASSIGN_CONSULTATION_STIFFNESS';
export const ASSIGN_CONSULTATION_NUMBNESS_OR_TINGLING = 'ASSIGN_CONSULTATION_NUMBNESS_OR_TINGLING';
export const ASSIGN_CONSULTATION_SWELLING = 'ASSIGN_CONSULTATION_SWELLING';
export const ASSIGN_CONSULTATION_BODY_FOCUS_IMAGE = 'ASSIGN_CONSULTATION_BODY_FOCUS_IMAGE';
export const ASSIGN_CONSULTATION_SIGNED_DATE = 'ASSIGN_CONSULTATION_SIGNED_DATE';
export const ASSIGN_CONSULTATION_AREAS = 'ASSIGN_CONSULTATION_AREAS';
export const ASSIGN_CONSULTATION_TREATMENTS = 'ASSIGN_CONSULTATION_TREATMENTS';
export const ASSIGN_CONSULTATION_CONDITIONS = 'ASSIGN_CONSULTATION_CONDITIONS';
export const ASSIGN_CONSULTATION_HERBS_OR_VITAMINS = 'ASSIGN_CONSULTATION_HERBS_OR_VITAMINS';
export const ASSIGN_CONSULTATION_MATERIALS = 'ASSIGN_CONSULTATION_MATERIALS';
export const ASSIGN_CONSULTATION_AFTER_TREATMENT = 'ASSIGN_CONSULTATION_AFTER_TREATMENT';
export const ASSIGN_CONSULTATION_SUGGESTIONS = 'ASSIGN_CONSULTATION_SUGGESTIONS';


export const ASSIGN_CONSULTATION_COMPLAINT_AREA = "ASSIGN_CONSULTATION_COMPLAINT_AREA";
export const ASSIGN_CONSULTATION_COMPLAINT_TYPE_OF_PAIN = "ASSIGN_CONSULTATION_COMPLAINT_TYPE_OF_PAIN";
export const ASSIGN_CONSULTATION_COMPLAINT_PAIN_LEVEL = "ASSIGN_CONSULTATION_COMPLAINT_PAIN_LEVEL";
export const ASSIGN_CONSULTATION_COMPLAINT_FREQUENCY_OF_PAIN = "ASSIGN_CONSULTATION_COMPLAINT_FREQUENCY_OF_PAIN";
export const ASSIGN_CONSULTATION_COMPLAINT_PAIN_DISCOMFORT_IS_MADE_WORSE_BY = "ASSIGN_CONSULTATION_COMPLAINT_PAIN_DISCOMFORT_IS_MADE_WORSE_BY";
export const ASSIGN_CONSULTATION_COMPLAINT_FEELS_BETTER_WITH = "ASSIGN_CONSULTATION_COMPLAINT_FEELS_BETTER_WITH";

export const ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_PALPATION_TENSION = "ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_PALPATION_TENSION";
export const ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_PALPATION_TEXTURE = "ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_PALPATION_TEXTURE";
export const ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_PALPATION_TENDERNESS = "ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_PALPATION_TENDERNESS";
export const ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_PALPATION_TEMPERATURE = "ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_PALPATION_TEMPERATURE";

export const ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_ROM_ACTIVE = "ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_ROM_ACTIVE";
export const ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_ROM_PASSIVE = "ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_ROM_PASSIVE";

export const ASSIGN_CONSULTATION_ASSESSMENT_POSTURE_SHOULDER = "ASSIGN_CONSULTATION_ASSESSMENT_POSTURE_SHOULDER";
export const ASSIGN_CONSULTATION_ASSESSMENT_POSTURE_SPINE = "ASSIGN_CONSULTATION_ASSESSMENT_POSTURE_SPINE";
export const ASSIGN_CONSULTATION_ASSESSMENT_POSTURE_PELVIS = "ASSIGN_CONSULTATION_ASSESSMENT_POSTURE_PELVIS";

export const ASSIGN_CONSULTATION_DRAPING_TECHNIQUE = "ASSIGN_CONSULTATION_DRAPING_TECHNIQUE";

export const ASSIGN_CONSULTATION_HEAD_AND_NECK_SUPERFICIAL_MUSCLES = "ASSIGN_CONSULTATION_HEAD_AND_NECK_SUPERFICIAL_MUSCLES";
export const ASSIGN_CONSULTATION_HEAD_AND_NECK_DEEP_MUSCLES = "ASSIGN_CONSULTATION_HEAD_AND_NECK_DEEP_MUSCLES";
export const ASSIGN_CONSULTATION_HEAD_AND_NECK_WARM_UP = "ASSIGN_CONSULTATION_HEAD_AND_NECK_WARM_UP";
export const ASSIGN_CONSULTATION_HEAD_AND_NECK_DEEP_TISSUES_TECHNIQUES = "ASSIGN_CONSULTATION_HEAD_AND_NECK_DEEP_TISSUES_TECHNIQUES";
export const ASSIGN_CONSULTATION_HEAD_AND_NECK_APPLICATION = "ASSIGN_CONSULTATION_HEAD_AND_NECK_APPLICATION";

export const ASSIGN_CONSULTATION_SHOULDERS_SUPERFICIAL_MUSCLES = "ASSIGN_CONSULTATION_SHOULDERS_SUPERFICIAL_MUSCLES";
export const ASSIGN_CONSULTATION_SHOULDERS_DEEP_MUSCLES = "ASSIGN_CONSULTATION_SHOULDERS_DEEP_MUSCLES";
export const ASSIGN_CONSULTATION_SHOULDERS_WARM_UP = "ASSIGN_CONSULTATION_SHOULDERS_WARM_UP";
export const ASSIGN_CONSULTATION_SHOULDERS_DEEP_TISSUES_TECHNIQUES = "ASSIGN_CONSULTATION_SHOULDERS_DEEP_TISSUES_TECHNIQUES";
export const ASSIGN_CONSULTATION_SHOULDERS_APPLICATION = "ASSIGN_CONSULTATION_SHOULDERS_APPLICATION";

export const ASSIGN_CONSULTATION_BACK_REGION_SUPERFICIAL_MUSCLES = "ASSIGN_CONSULTATION_BACK_REGION_SUPERFICIAL_MUSCLES";
export const ASSIGN_CONSULTATION_BACK_REGION_DEEP_MUSCLES = "ASSIGN_CONSULTATION_BACK_REGION_DEEP_MUSCLES";
export const ASSIGN_CONSULTATION_BACK_REGION_WARM_UP = "ASSIGN_CONSULTATION_BACK_REGION_WARM_UP";
export const ASSIGN_CONSULTATION_BACK_REGION_DEEP_TISSUES_TECHNIQUES = "ASSIGN_CONSULTATION_BACK_REGION_DEEP_TISSUES_TECHNIQUES";
export const ASSIGN_CONSULTATION_BACK_REGION_APPLICATION = "ASSIGN_CONSULTATION_BACK_REGION_APPLICATION";

export const ASSIGN_CONSULTATION_LUMBAR_REGION_SUPERFICIAL_MUSCLES = "ASSIGN_CONSULTATION_LUMBAR_REGION_SUPERFICIAL_MUSCLES";
export const ASSIGN_CONSULTATION_LUMBAR_REGION_DEEP_MUSCLES = "ASSIGN_CONSULTATION_LUMBAR_REGION_DEEP_MUSCLES";
export const ASSIGN_CONSULTATION_LUMBAR_REGION_WARM_UP = "ASSIGN_CONSULTATION_LUMBAR_REGION_WARM_UP";
export const ASSIGN_CONSULTATION_LUMBAR_REGION_DEEP_TISSUES_TECHNIQUES = "ASSIGN_CONSULTATION_LUMBAR_REGION_DEEP_TISSUES_TECHNIQUES";
export const ASSIGN_CONSULTATION_LUMBAR_REGION_APPLICATION = "ASSIGN_CONSULTATION_LUMBAR_REGION_APPLICATION";

export const ASSIGN_CONSULTATION_HIPS_SUPERFICIAL_MUSCLES = "ASSIGN_CONSULTATION_HIPS_SUPERFICIAL_MUSCLES";
export const ASSIGN_CONSULTATION_HIPS_DEEP_MUSCLES = "ASSIGN_CONSULTATION_HIPS_DEEP_MUSCLES";
export const ASSIGN_CONSULTATION_HIPS_WARM_UP = "ASSIGN_CONSULTATION_HIPS_WARM_UP";
export const ASSIGN_CONSULTATION_HIPS_DEEP_TISSUES_TECHNIQUES = "ASSIGN_CONSULTATION_HIPS_DEEP_TISSUES_TECHNIQUES";
export const ASSIGN_CONSULTATION_HIPS_APPLICATION = "ASSIGN_CONSULTATION_HIPS_APPLICATION";

export const ASSIGN_CONSULTATION_ARM_REGION_SUPERFICIAL_MUSCLES = "ASSIGN_CONSULTATION_ARM_REGION_SUPERFICIAL_MUSCLES";
export const ASSIGN_CONSULTATION_ARM_REGION_DEEP_MUSCLES = "ASSIGN_CONSULTATION_ARM_REGION_DEEP_MUSCLES";
export const ASSIGN_CONSULTATION_ARM_REGION_WARM_UP = "ASSIGN_CONSULTATION_ARM_REGION_WARM_UP";
export const ASSIGN_CONSULTATION_ARM_REGION_DEEP_TISSUES_TECHNIQUES = "ASSIGN_CONSULTATION_ARM_REGION_DEEP_TISSUES_TECHNIQUES";
export const ASSIGN_CONSULTATION_ARM_REGION_APPLICATION = "ASSIGN_CONSULTATION_ARM_REGION_APPLICATION";

export const ASSIGN_CONSULTATION_THIGH_REGION = "ASSIGN_CONSULTATION_THIGH_REGION";
export const ASSIGN_CONSULTATION_THIGH_REGION_DEEP_MUSCLES = "ASSIGN_CONSULTATION_THIGH_REGION_DEEP_MUSCLES";
export const ASSIGN_CONSULTATION_THIGH_REGION_WARM_UP = "ASSIGN_CONSULTATION_THIGH_REGION_WARM_UP";
export const ASSIGN_CONSULTATION_THIGH_REGION_DEEP_TISSUES_TECHNIQUES = "ASSIGN_CONSULTATION_THIGH_REGION_DEEP_TISSUES_TECHNIQUES";
export const ASSIGN_CONSULTATION_THIGH_REGION_APPLICATION = "ASSIGN_CONSULTATION_THIGH_REGION_APPLICATION";

export const ASSIGN_CONSULTATION_PLAN = "ASSIGN_CONSULTATION_PLAN";
export const ASSIGN_CONSULTATION_REPEAT_TIMES = "ASSIGN_CONSULTATION_REPEAT_TIMES";
export const ASSIGN_CONSULTATION_REPEAT_WEEK_OR_MONTH = "ASSIGN_CONSULTATION_REPEAT_WEEK_OR_MONTH";

export const ASSIGN_CONSULTATION_ADVICE_TO_CLIENT = "ASSIGN_CONSULTATION_ADVICE_TO_CLIENT";

export const ASSIGN_CONSULTATION_DURATION = 'ASSIGN_CONSULTATION_DURATION';
export const ASSIGN_CONSULTATION_PRACTITIONER = 'ASSIGN_CONSULTATION_PRACTITIONER';
export const ASSIGN_CONSULTATION_TIME_FROM = 'ASSIGN_CONSULTATION_TIME_FROM';
export const ASSIGN_CONSULTATION_TIME_TO = 'ASSIGN_CONSULTATION_TIME_TO';
export const ASSIGN_CONSULTATION_SERVICES_AND_GOODS = 'ASSIGN_CONSULTATION_SERVICES_AND_GOODS';
export const ASSIGN_CONSULTATION_IS_HICAPS_COPY_GIVEN = 'ASSIGN_CONSULTATION_IS_HICAPS_COPY_GIVEN';
export const ASSIGN_CONSULTATION_HICAPS_COPY_GIVEN_NOTE = 'ASSIGN_CONSULTATION_HICAPS_COPY_GIVEN_NOTE';
export const ASSIGN_CONSULTATION_IS_RECEIPT_GIVEN = 'ASSIGN_CONSULTATION_IS_RECEIPT_GIVEN';
export const ASSIGN_CONSULTATION_RECEIPT_NUMBER = 'ASSIGN_CONSULTATION_RECEIPT_NUMBER';
export const ASSIGN_CONSULTATION_PROVIDER = 'ASSIGN_CONSULTATION_PROVIDER';
export const ASSIGN_CONSULTATION_INSURANCE_COMPANY = 'ASSIGN_CONSULTATION_INSURANCE_COMPANY';
export const ASSIGN_CONSULTATION_PROVIDER_NUMBER = 'ASSIGN_CONSULTATION_PROVIDER_NUMBER';

export const ASSIGN_CONSULTATION_COMPLAINT_NOTES = 'ASSIGN_CONSULTATION_COMPLAINT_NOTES';
export const ASSIGN_CONSULTATION_TREATMENT_PLAN_OTHERS = 'ASSIGN_CONSULTATION_TREATMENT_PLAN_OTHERS';


export const LINK_CONSULTATION = 'LINK_CONSULTATION';
export const LINK_CONSULTATION_SUCCESS = 'LINK_CONSULTATION_SUCCESS';
export const LINK_CONSULTATION_FAILED = 'LINK_CONSULTATION_FAILED';

export const LIST_CONSULTATION_PATIENTS = 'LIST_CONSULTATION_PATIENTS';
export const LIST_CONSULTATION_PATIENTS_SUCCESS = 'LIST_CONSULTATION_PATIENTS_SUCCESS';
export const LIST_CONSULTATION_PATIENTS_FAILED = 'LIST_CONSULTATION_PATIENTS_FAILED';
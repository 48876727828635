import { axios, generateRoute } from "@tmatt-tech/allaroundmassage_frontend_common";
import {
  CREATE_REVIEW_API,
  DELETE_REVIEW_API,
  FETCH_NEW_REVIEW_API,
  FETCH_REVIEW_API,
  LIST_REVIEW_API,
  UPDATE_REVIEW_API
} from "api/review/constants";


export const apiListReviews = () => {
  const { url, method } = LIST_REVIEW_API;

  return axios({
    method,
    url,
  });
};

export const apiCreateReview = ({ review }) => {
  const { url, method } = CREATE_REVIEW_API;

  return axios({
    method,
    url,
    data: reviewParams(review),
  });
};

export const apiFetchReview = ({ reviewId }) => {
  const { url, method } = FETCH_REVIEW_API;

  return axios({
    method,
    url: generateRoute(url, reviewId),
  });
};

export const apiFetchNewReview = ({ practitionerReportItemId }) => {
  const { url, method } = FETCH_NEW_REVIEW_API;

  return axios({
    method,
    url: url,
    params: { practitionerReportItemId },
  });
};

export const apiUpdateReview = ({ review }) => {
  const { url, method } = UPDATE_REVIEW_API;

  return axios({
    method,
    url: generateRoute(url, review.id),
    data: reviewParams(review),
  });
};

export const apiDeleteReview = ({ review }) => {
  const { url, method } = DELETE_REVIEW_API;

  return axios({
    method,
    url: generateRoute(url, review.id),
  });
};

const reviewParams = (review) => {
  return {
    oil: review.oil,
    hotStones: review.hotStones,
    strength: review.strength,
    typesOfMassage: review.typesOfMassage,
    scrub: review.scrub,
    concern: review.concern,
    concernNote: review.concernNote,
    practitionerReportItemId: review.practitionerReportItemId,
  }
};

import { ERROR_FORM_PRACTITIONER_REPORT, LOADING_FORM_PRACTITIONER_REPORT, RESET_FORM_PRACTITIONER_REPORT } from "redux/ui/form/practitionerReport/constants";
import {
  CREATE_PRACTITIONER_REPORT,
  CREATE_PRACTITIONER_REPORT_FAILED,
  CREATE_PRACTITIONER_REPORT_SUCCESS,
  UPDATE_PRACTITIONER_REPORT, UPDATE_PRACTITIONER_REPORT_FAILED, UPDATE_PRACTITIONER_REPORT_SUCCESS
} from "redux/model/practitionerReports/practitionerReport/constants";


const INIT_STATE = {
  loading: false,
  error: null,
};

const formPractitionerReportReducer = (state = INIT_STATE, action) => {
  switch (action.type) {

    case LOADING_FORM_PRACTITIONER_REPORT:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ERROR_FORM_PRACTITIONER_REPORT:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case RESET_FORM_PRACTITIONER_REPORT:
      return {
        ...state,
        loading: false,
        error: null
      };

    case CREATE_PRACTITIONER_REPORT:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_PRACTITIONER_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_PRACTITIONER_REPORT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_PRACTITIONER_REPORT:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_PRACTITIONER_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case UPDATE_PRACTITIONER_REPORT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    default:
      return { ...state };
  }
};

export default formPractitionerReportReducer;

import { ERROR_FORM_PROVIDER, LOADING_FORM_PROVIDER, RESET_FORM_PROVIDER } from "redux/ui/form/provider/constants";

export const loadingFormProvider = () => ({
  type: LOADING_FORM_PROVIDER,
  payload: {}
});

export const errorFormProvider = ({ error }) => ({
  type: ERROR_FORM_PROVIDER,
  payload: { error }
});

export const resetFormProvider = () => ({
  type: RESET_FORM_PROVIDER,
  payload: {}
});

import { axios, extractIdFrom, generateRoute, toApiDateString } from "@tmatt-tech/allaroundmassage_frontend_common";
import {
  CREATE_INVOICE_API,
  DELETE_INVOICE_API,
  FETCH_INVOICE_API,
  FETCH_NEW_INVOICE_API,
  LIST_INVOICE_API,
  NOTIFY_PATIENT_INVOICE_API,
  UPDATE_INVOICE_API,
  LIST_INVOICE_PATIENTS_API
} from "api/invoice/constants";


export const apiListInvoices = () => {
  const { url, method } = LIST_INVOICE_API;

  return axios({
    method,
    url,
  });
};

export const apiCreateInvoice = ({ invoice }) => {
  const { url, method } = CREATE_INVOICE_API;

  return axios({
    method,
    url,
    data: invoiceParams(invoice),
  });
};

export const apiListInvoicePatient = ({ query }) => {
  const {url, method} = LIST_INVOICE_PATIENTS_API;

  return axios({
    method,
    url,
    params: {
      query,
    }
  });
};

export const apiFetchInvoice = ({ invoiceId }) => {
  const { url, method } = FETCH_INVOICE_API;

  return axios({
    method,
    url: generateRoute(url, invoiceId),
  });
};

export const apiFetchNewInvoice = () => {
  const { url, method } = FETCH_NEW_INVOICE_API;

  return axios({
    method,
    url,
  });
};

export const apiUpdateInvoice = ({ invoice }) => {
  const { url, method } = UPDATE_INVOICE_API;

  return axios({
    method,
    url: generateRoute(url, invoice.id),
    data: invoiceParams(invoice),
  });
};

export const apiDeleteInvoice = ({ invoice }) => {
  const { url, method } = DELETE_INVOICE_API;

  return axios({
    method,
    url: generateRoute(url, invoice.id),
  });
};

export const apiNotifyPatientInvoice = ({ invoice }) => {
  const { url, method } = NOTIFY_PATIENT_INVOICE_API;

  return axios({
    method,
    url: generateRoute(url, invoice.id),
  });
};

const invoiceParams = (invoice) => {
  const {
    invoiceNumber,
    date,
    sumOf,
    beingFor,
    providerNo,
    providerName,
    providerMembershipNumber,
    itemNumber,
    insPaid,
    patPaid,
    gst,
    amount,
    note,
    patient,
    patientId,
  } = invoice;


  return {
    invoiceNumber,
    date: toApiDateString(date),
    patientId: extractIdFrom(patient ? patient.id : patientId),
    sumOf,
    beingFor,
    providerNo,
    providerName,
    providerMembershipNumber,
    itemNumber,
    insPaid: insPaid || 0,
    patPaid: patPaid || 0,
    gst: gst || 0,
    amount: amount || 0,
    note,
  };
};

import { axios, extractIdFrom, generateRoute, toApiDateTimeString } from "@tmatt-tech/allaroundmassage_frontend_common";
import {
  CREATE_SURVEY_API, UPDATE_SURVEY_API,
  DELETE_SURVEY_API, FETCH_NEW_SURVEY_API,
  FETCH_SURVEY_API, LIST_SURVEY_API,
  LINK_SURVEY_API,
} from "api/survey/constants";


export const apiListSurveys = () => {
  const { url, method } = LIST_SURVEY_API;

  return axios({
    method,
    url,
  });
};

export const apiCreateSurvey = ({ survey }) => {
  const { url, method } = CREATE_SURVEY_API;

  return axios({
    method,
    url,
    data: surveyParams(survey),
  });
};

export const apiFetchSurvey = ({ surveyId }) => {
  const { url, method } = FETCH_SURVEY_API;

  return axios({
    method,
    url: generateRoute(url, surveyId),
  });
};

export const apiFetchNewSurvey = ({ practitionerReportItemId }) => {
  const { url, method } = FETCH_NEW_SURVEY_API;

  return axios({
    method,
    url: url,
    params: { practitionerReportItemId },
  });
};

export const apiUpdateSurvey = ({ survey }) => {
  const { url, method } = UPDATE_SURVEY_API;

  return axios({
    method,
    url: generateRoute(url, survey.id),
    data: surveyParams(survey),
  });
};

export const apiDeleteSurvey = ({ survey }) => {
  const { url, method } = DELETE_SURVEY_API;

  return axios({
    method,
    url: generateRoute(url, survey.id),
  });
};

export const apiLinkSurvey = ({ survey, practitionerReportItemId }) => {
  const { url, method } = LINK_SURVEY_API;

  return axios({
    method,
    url: generateRoute(url, survey.id),
    data: {
      practitionerReportItemId: extractIdFrom(practitionerReportItemId),
    },
  });
};

const surveyParams = (survey) => {
  return {
    datetime: toApiDateTimeString(survey.datetime),
    practitionerId: extractIdFrom(survey.practitionerId),
    room: survey.room,
    duration: survey.duration,
    price: survey.price,
    practitionerSkills: survey.practitionerSkills,
    environment: survey.environment,
    overallServices: survey.overallServices,
    comments: survey.comments,
    practitionerReportItemId: survey.practitionerReportItemId,
    oil: survey.oil,
    hotStones: survey.hotStones,
    strength: survey.strength,
    typesOfMassage: survey.typesOfMassage,
    scrub: survey.scrub,
    concern: survey.concern,
    concernNote: survey.concernNote,
  }
};

import { axios, generateRoute } from "@tmatt-tech/allaroundmassage_frontend_common";
import {
  CREATE_PATIENT_API,
  DELETE_PATIENT_API,
  FETCH_PATIENT_API,
  INDEX_PATIENTS_API,
  UPDATE_PATIENT_API,
  QUERY_POINTS_API,
} from "api/patient/constants";

export const apiListPatients = ({ page, query }) => {
  const { url, method } = INDEX_PATIENTS_API;

  return axios({
    method,
    url,
    params: {
      page,
      query,
    }
  });
};

export const apiCreatePatient = ({ patient }) => {
  console.log('api: ', patient);
  const { url, method } = CREATE_PATIENT_API;

  return axios({
    method,
    url,
    data: patientParams({ patient }),
  });
};

export const apiFetchPatient = ({ patientId }) => {
  const { url, method } = FETCH_PATIENT_API;

  return axios({
    method,
    url: generateRoute(url, patientId),
  });
};

export const apiUpdatePatient = ({ patient }) => {
  const { url, method } = UPDATE_PATIENT_API;

  return axios({
    method,
    url: generateRoute(url, patient.id),
    data: patientParams({ patient }),
  });
};

export const apiDeletePatient = ({ patient }) => {
  const { url, method } = DELETE_PATIENT_API;

  return axios({
    method,
    url: generateRoute(url, patient.id),
  });
};

export const apiQueryPoints = ({ phone }) => {
  const { url, method } = QUERY_POINTS_API;
  console.log(' export const apiQueryPoints = ({ phone }) => { ');

  return axios({
    method,
    url,
    data: {
      query: phone,
    },
  });
};

const patientParams = ({ patient }) => {
  const {
    firstName,
    lastName,
    dateOfBirth,
    mobile,
    homePhone,
    gender,
    address,
    occupation,
    preferredLanguage,
    note,
    signature,
    privateHealthFundName,
    privateHealthFundMembershipNumber,
    privateHealthFundMembershipCode,
    emergencyContact,
    emergencyPhone,
  } = patient;

  // 生成一个随机的字符串用于电子邮件地址
  const randomEmailPart = Math.random().toString(36).substring(2, 9);

  // 检查电子邮件是否为空或null，并据此设置电子邮件地址
  const email = patient.email ? patient.email : `_no.mail.${randomEmailPart}@allaroundmassage.com.au`;

  return {
    patient: {
      email,
      firstName,
      lastName,
      dateOfBirth,
      mobile,
      homePhone,
      gender,
      address,
      occupation,
      preferredLanguage,
      note,
      signature,
      privateHealthFundName,
      privateHealthFundMembershipNumber,
      privateHealthFundMembershipCode,
      emergencyContact,
      emergencyPhone,
    }
  }
};

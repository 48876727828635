import React from "react";
import { PrivateRoute } from "@tmatt-tech/allaroundmassage_frontend_common";
import { BASE_CONSULTATION_PATH, INDEX_CONSULTATION_PATH, NEW_CONSULTATION_PATH, EDIT_CONSULTATION_PATH } from "routes/consultation/constants";

const AllConsultation = React.lazy(() => import('pages/consultations/index/Consultations'));
const NewConsultation = React.lazy(() => import('pages/consultations/new/NewConsultation'));
const EditConsultation = React.lazy(() => import('pages/consultations/edit/EditConsultation'));

export const consultationRoutes = {
  path: BASE_CONSULTATION_PATH,
  // name: 'Medical Records',
  name: "Remedial",
  icon: 'uil uil-invoice',
  children: [
    {
      path: INDEX_CONSULTATION_PATH,
      name: 'All Medical Records',
      component: AllConsultation,
      route: PrivateRoute,
    },
    {
      path: NEW_CONSULTATION_PATH,
      name: 'Add New',
      component: NewConsultation,
      route: PrivateRoute,
    },
  ],
};

export const hiddenConsultationRoutes = {
  children: [
    {
      path: EDIT_CONSULTATION_PATH,
      name: 'Edit Consultation',
      component: EditConsultation,
      route: PrivateRoute,
    }
  ]
}


export const CREATE_PATIENT_API = {
  url: `/api/system/patients`,
  method: 'post'
};

export const INDEX_PATIENTS_API = {
  url: `/api/system/patients`,
  method: 'get',
}

export const FETCH_PATIENT_API = {
  url: `/api/system/patients/:id`,
  method: 'get',
}

export const UPDATE_PATIENT_API = {
  url: `/api/system/patients/:id`,
  method: 'put'
}

export const DELETE_PATIENT_API = {
  url: `/api/system/patients/:id`,
  method: 'delete'
}

export const QUERY_POINTS_API = {
  url: `/api/system/patients/query_points`,
  method: 'post'
};

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_PRACTITIONERS } from "redux/model/practitioners/practitioners/constants";
import { apiListPractitioners } from "api/practitioner/practitionerApi";
import { listPractitionersFailed, listPractitionersSuccess } from "redux/model/practitioners/practitioners/practitionersActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/allaroundmassage_frontend_common";


function* practitionersSaga() {
  yield all([
    fork(watchListPractitioners),
  ]);
}

// List Practitioners
export function* watchListPractitioners() {
  yield takeEvery(LIST_PRACTITIONERS, sagaListPractitioners);
}

export function* sagaListPractitioners() {
  try {
    const response = yield call(apiListPractitioners);

    yield put(listPractitionersSuccess({ practitioners: response.data }));

  } catch (e) {
    yield put(listPractitionersFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default practitionersSaga;

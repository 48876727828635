
export const CREATE_PRACTITIONER_API = {
  url: `/api/practitioners`,
  method: 'post'
};

export const LIST_PRACTITIONER_API = {
  url: `/api/practitioners`,
  method: 'get',
}

export const FETCH_PRACTITIONER_API = {
  url: `/api/practitioners/:id`,
  method: 'get',
}

export const UPDATE_PRACTITIONER_API = {
  url: `/api/practitioners/:id`,
  method: 'put'
}

export const DELETE_PRACTITIONER_API = {
  url: `/api/practitioners/:id`,
  method: 'delete'
}

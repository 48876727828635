
export const CREATE_SURVEY_API = {
  url: `/api/surveys`,
  method: 'post'
};

export const LIST_SURVEY_API = {
  url: `/api/surveys`,
  method: 'get',
}

export const FETCH_SURVEY_API = {
  url: `/api/surveys/:id`,
  method: 'get',
}

export const FETCH_NEW_SURVEY_API = {
  url: `/api/survey/new`,
  method: 'get',
}

export const UPDATE_SURVEY_API = {
  url: `/api/surveys/:id`,
  method: 'put'
}

export const DELETE_SURVEY_API = {
  url: `/api/surveys/:id`,
  method: 'delete'
}

export const LINK_SURVEY_API = {
  url: `/api/survey/link/:id`,
  method: 'patch',
};

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_SURVEYS } from "redux/model/surveys/surveys/constants";
import { apiListSurveys } from "api/survey/surveyApi";
import { listSurveysFailed, listSurveysSuccess } from "redux/model/surveys/surveys/surveysActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/allaroundmassage_frontend_common";


function* surveysSaga() {
  yield all([
    fork(watchListSurveys),
  ]);
}

// List Surveys
export function* watchListSurveys() {
  yield takeEvery(LIST_SURVEYS, sagaListSurveys);
}

export function* sagaListSurveys() {
  try {
    const response = yield call(apiListSurveys);

    yield put(listSurveysSuccess({ surveys: response.data }));

  } catch (e) {
    yield put(listSurveysFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default surveysSaga;

export const INIT_SURVEY = 'INIT_SURVEY';

export const CREATE_SURVEY = 'CREATE_SURVEY';
export const CREATE_SURVEY_SUCCESS = 'CREATE_SURVEY_SUCCESS';
export const CREATE_SURVEY_FAILED = 'CREATE_SURVEY_FAILED';

export const FETCH_SURVEY = 'FETCH_SURVEY';
export const FETCH_SURVEY_SUCCESS = 'FETCH_SURVEY_SUCCESS';
export const FETCH_SURVEY_FAILED = 'FETCH_SURVEY_FAILED';

export const FETCH_NEW_SURVEY = 'FETCH_NEW_SURVEY';

export const UPDATE_SURVEY = 'UPDATE_SURVEY';
export const UPDATE_SURVEY_SUCCESS = 'UPDATE_SURVEY_SUCCESS';
export const UPDATE_SURVEY_FAILED = 'UPDATE_SURVEY_FAILED';

export const DELETE_SURVEY = 'DELETE_SURVEY';
export const DELETE_SURVEY_SUCCESS = 'DELETE_SURVEY_SUCCESS';
export const DELETE_SURVEY_FAILED = 'DELETE_SURVEY_FAILED';

export const LINK_SURVEY = 'LINK_SURVEY';
export const LINK_SURVEY_SUCCESS = 'LINK_SURVEY_SUCCESS';
export const LINK_SURVEY_FAILED = 'LINK_SURVEY_FAILED';

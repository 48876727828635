import {
  ASSIGN_PROVIDER_MOBILE,
  ASSIGN_PROVIDER_NAME,
  ASSIGN_PROVIDER_MEMBERSHIP_NUMBER,
  FETCH_PROVIDER,
  FETCH_PROVIDER_FAILED,
  FETCH_PROVIDER_SUCCESS,
  INIT_PROVIDER,
  NEW_PROVIDER,
  ASSIGN_PROVIDER_INSURANCE_EXPIRE_DATE,
  ASSIGN_PROVIDER_FIRST_AID_EXPIRY_DATE,
  ASSIGN_PROVIDER_CPD_LODGED_DATE, ASSIGN_PROVIDER_ADDRESS_1, ASSIGN_PROVIDER_ADDRESS_2, ASSIGN_PROVIDER_ADDRESS_3
} from "redux/model/providers/provider/constants";

const INIT_STATE = {
  provider: {
    emergency: {},
    privateHealthFund: {},
  },
  loading: true,
  error: null,
};

const providerReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_PROVIDER:
      return INIT_STATE;

    case FETCH_PROVIDER:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_PROVIDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        provider: action.payload.provider,
      };

    case FETCH_PROVIDER_FAILED:
      return {
        ...state,
        provider: {},
        loading: false,
        error: action.payload.error,
      };

    case NEW_PROVIDER:
      return {
        ...state,
        provider: {
          id: action.payload.dummyId,
          providerNumberIds: [],
        },
        loading: false,
        error: null,
      }

    case ASSIGN_PROVIDER_NAME:
      return {
        ...state,
        provider: {
          ...state.provider,
          name: action.payload.name,
        },
      };

    case ASSIGN_PROVIDER_MOBILE:
      return {
        ...state,
        provider: {
          ...state.provider,
          mobile: action.payload.mobile,
        }
      }

    case ASSIGN_PROVIDER_MEMBERSHIP_NUMBER:
      return {
        ...state,
        provider: {
          ...state.provider,
          membershipNumber: action.payload.membershipNumber,
        }
      }

    case ASSIGN_PROVIDER_INSURANCE_EXPIRE_DATE:
      return {
        ...state,
        provider: {
          ...state.provider,
          insuranceExpireDate: action.payload.insuranceExpireDate,
        }
      }

    case ASSIGN_PROVIDER_FIRST_AID_EXPIRY_DATE:
      return {
        ...state,
        provider: {
          ...state.provider,
          firstAidExpiryDate: action.payload.firstAidExpiryDate,
        }
      }

    case ASSIGN_PROVIDER_CPD_LODGED_DATE:
      return {
        ...state,
        provider: {
          ...state.provider,
          cpdLodgedDate: action.payload.cpdLodgedDate,
        }
      }

    case ASSIGN_PROVIDER_ADDRESS_1:
      return {
        ...state,
        provider: {
          ...state.provider,
          address1: action.payload.address1,
        }
      }

    case ASSIGN_PROVIDER_ADDRESS_2:
      return {
        ...state,
        provider: {
          ...state.provider,
          address2: action.payload.address2,
        }
      }

    case ASSIGN_PROVIDER_ADDRESS_3:
      return {
        ...state,
        provider: {
          ...state.provider,
          address3: action.payload.address3,
        }
      }

    default:
      return { ...state };
  }
};

export default providerReducer;

import { LIST_REVIEWS, LIST_REVIEWS_FAILED, LIST_REVIEWS_SUCCESS } from "redux/model/reviews/reviews/constants";

export const listReviews = () => ({
  type: LIST_REVIEWS,
  payload: {}
});

export const listReviewsSuccess = ({ reviews }) => ({
  type: LIST_REVIEWS_SUCCESS,
  payload: { reviews }
});

export const listReviewsFailed = ({ error }) => ({
  type: LIST_REVIEWS_FAILED,
  payload: { error }
});

import React from "react";
import { PrivateRoute } from "@tmatt-tech/allaroundmassage_frontend_common";
import { BASE_INVOICE_PATH, INDEX_INVOICE_PATH, NEW_INVOICE_PATH, EDIT_INVOICE_PATH } from "routes/invoice/constants";

const AllInvoice = React.lazy(() => import('pages/invoices/index/Invoices'));
const NewInvoice = React.lazy(() => import('pages/invoices/new/NewInvoice'));
const EditInvoice = React.lazy(() => import('pages/invoices/edit/EditInvoice'));

export const invoiceRoutes = {
  path: BASE_INVOICE_PATH,
  name: 'Invoices',
  icon: 'uil uil-invoice',
  children: [
    {
      path: INDEX_INVOICE_PATH,
      name: 'All Invoices',
      component: AllInvoice,
      route: PrivateRoute,
    },
    {
      path: NEW_INVOICE_PATH,
      name: 'Add New',
      component: NewInvoice,
      route: PrivateRoute,
    },
  ],
};

export const hiddenInvoiceRoutes = {
  children: [
    {
      path: EDIT_INVOICE_PATH,
      name: 'Edit Invoice',
      component: EditInvoice,
      route: PrivateRoute,
    }
  ]
}

import { LIST_NOTICES, LIST_NOTICES_FAILED, LIST_NOTICES_SUCCESS } from "redux/model/notices/notices/constants";
import { RECEIVE_NOTICE_SUCCESS } from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/notification/notificationConstants";

export const listNotices = () => ({
  type: LIST_NOTICES,
  payload: {}
});

export const listNoticesSuccess = ({ notices }) => ({
  type: LIST_NOTICES_SUCCESS,
  payload: { notices }
});

export const listNoticesFailed = ({ error }) => ({
  type: LIST_NOTICES_FAILED,
  payload: { error }
});

export const receiveNoticeSuccess = ({ receivedNotice }) => ({
  type: RECEIVE_NOTICE_SUCCESS,
  payload: { receivedNotice }
});

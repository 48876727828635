import {
  ASSIGN_PROVIDER_MOBILE,
  ASSIGN_PROVIDER_NAME,
  ASSIGN_PROVIDER_MEMBERSHIP_NUMBER,
  CREATE_PROVIDER,
  CREATE_PROVIDER_FAILED,
  CREATE_PROVIDER_SUCCESS,
  DELETE_PROVIDER,
  DELETE_PROVIDER_FAILED,
  DELETE_PROVIDER_SUCCESS,
  FETCH_PROVIDER,
  FETCH_PROVIDER_FAILED,
  FETCH_PROVIDER_SUCCESS,
  INIT_PROVIDER,
  NEW_PROVIDER,
  UPDATE_PROVIDER,
  UPDATE_PROVIDER_FAILED,
  UPDATE_PROVIDER_SUCCESS,
  ASSIGN_PROVIDER_INSURANCE_EXPIRE_DATE,
  ASSIGN_PROVIDER_FIRST_AID_EXPIRY_DATE,
  ASSIGN_PROVIDER_CPD_LODGED_DATE, ASSIGN_PROVIDER_ADDRESS_1, ASSIGN_PROVIDER_ADDRESS_2, ASSIGN_PROVIDER_ADDRESS_3
} from "redux/model/providers/provider/constants";

export const initProvider = () => ({
  type: INIT_PROVIDER,
  payload: {}
});

export const createProvider = ({ provider }) => ({
  type: CREATE_PROVIDER,
  payload: { provider }
});

export const createProviderSuccess = ({ provider }) => ({
  type: CREATE_PROVIDER_SUCCESS,
  payload: { provider }
});

export const createProviderFailed = ({ error }) => ({
  type: CREATE_PROVIDER_FAILED,
  payload: { error }
});

export const fetchProvider = ({ providerId }) => ({
  type: FETCH_PROVIDER,
  payload: { providerId }
});

export const fetchProviderSuccess = ({ provider }) => ({
  type: FETCH_PROVIDER_SUCCESS,
  payload: { provider }
});

export const fetchProviderFailed = ({ error }) => ({
  type: FETCH_PROVIDER_FAILED,
  payload: { error }
});

export const updateProvider = ({ provider }) => ({
  type: UPDATE_PROVIDER,
  payload: { provider }
});

export const updateProviderSuccess = ({ provider }) => ({
  type: UPDATE_PROVIDER_SUCCESS,
  payload: { provider }
});

export const updateProviderFailed = ({ error }) => ({
  type: UPDATE_PROVIDER_FAILED,
  payload: { error }
});

export const deleteProvider = ({ provider }) => ({
  type: DELETE_PROVIDER,
  payload: { provider }
});

export const deleteProviderSuccess = ({ provider }) => ({
  type: DELETE_PROVIDER_SUCCESS,
  payload: { provider }
});

export const deleteProviderFailed = ({ error }) => ({
  type: DELETE_PROVIDER_FAILED,
  payload: { error }
});

export const newProvider = ({ dummyId }) => ({
  type: NEW_PROVIDER,
  payload: { dummyId }
});

export const assignProviderName = ({ name }) => ({
  type: ASSIGN_PROVIDER_NAME,
  payload: { name },
});

export const assignProviderMobile = ({ mobile }) => ({
  type: ASSIGN_PROVIDER_MOBILE,
  payload: { mobile },
});

export const assignProviderMembershipNumber = ({ membershipNumber }) => ({
  type: ASSIGN_PROVIDER_MEMBERSHIP_NUMBER,
  payload: { membershipNumber },
});

export const assignProviderInsuranceExpireDate = ({ insuranceExpireDate }) => ({
  type: ASSIGN_PROVIDER_INSURANCE_EXPIRE_DATE,
  payload: { insuranceExpireDate },
});

export const assignProviderFirstAidExpiryDate = ({ firstAidExpiryDate }) => ({
  type: ASSIGN_PROVIDER_FIRST_AID_EXPIRY_DATE,
  payload: { firstAidExpiryDate },
});

export const assignProviderCpdLodgedDate = ({ cpdLodgedDate }) => ({
  type: ASSIGN_PROVIDER_CPD_LODGED_DATE,
  payload: { cpdLodgedDate },
});

export const assignProviderAddress1 = ({ address1 }) => ({
  type: ASSIGN_PROVIDER_ADDRESS_1,
  payload: { address1 },
});

export const assignProviderAddress2 = ({ address2 }) => ({
  type: ASSIGN_PROVIDER_ADDRESS_2,
  payload: { address2 },
});

export const assignProviderAddress3 = ({ address3 }) => ({
  type: ASSIGN_PROVIDER_ADDRESS_3,
  payload: { address3 },
});



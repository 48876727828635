import {
  ASSIGN_CONSULTATION_ADVICE_TO_CLIENT,
  ASSIGN_CONSULTATION_AFTER_TREATMENT,
  ASSIGN_CONSULTATION_AREAS,
  ASSIGN_CONSULTATION_ARM_REGION_APPLICATION,
  ASSIGN_CONSULTATION_ARM_REGION_DEEP_MUSCLES,
  ASSIGN_CONSULTATION_ARM_REGION_DEEP_TISSUES_TECHNIQUES,
  ASSIGN_CONSULTATION_ARM_REGION_SUPERFICIAL_MUSCLES,
  ASSIGN_CONSULTATION_ARM_REGION_WARM_UP,
  ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_PALPATION_TEMPERATURE,
  ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_PALPATION_TENDERNESS,
  ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_PALPATION_TENSION,
  ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_PALPATION_TEXTURE,
  ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_ROM_ACTIVE,
  ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_ROM_PASSIVE,
  ASSIGN_CONSULTATION_ASSESSMENT_POSTURE_PELVIS,
  ASSIGN_CONSULTATION_ASSESSMENT_POSTURE_SHOULDER,
  ASSIGN_CONSULTATION_ASSESSMENT_POSTURE_SPINE,
  ASSIGN_CONSULTATION_BACK_REGION_APPLICATION,
  ASSIGN_CONSULTATION_BACK_REGION_DEEP_MUSCLES,
  ASSIGN_CONSULTATION_BACK_REGION_DEEP_TISSUES_TECHNIQUES,
  ASSIGN_CONSULTATION_BACK_REGION_SUPERFICIAL_MUSCLES,
  ASSIGN_CONSULTATION_BACK_REGION_WARM_UP,
  ASSIGN_CONSULTATION_BODY_FOCUS_IMAGE,
  ASSIGN_CONSULTATION_CARDIOVASCULAR,
  ASSIGN_CONSULTATION_COMPLAINT_AREA,
  ASSIGN_CONSULTATION_COMPLAINT_FEELS_BETTER_WITH,
  ASSIGN_CONSULTATION_COMPLAINT_FREQUENCY_OF_PAIN,
  ASSIGN_CONSULTATION_COMPLAINT_PAIN_DISCOMFORT_IS_MADE_WORSE_BY,
  ASSIGN_CONSULTATION_COMPLAINT_PAIN_LEVEL,
  ASSIGN_CONSULTATION_COMPLAINT_TYPE_OF_PAIN,
  ASSIGN_CONSULTATION_CONDITIONS,
  ASSIGN_CONSULTATION_DATETIME,
  ASSIGN_CONSULTATION_DURATION,
  ASSIGN_CONSULTATION_DRAPING_TECHNIQUE,
  ASSIGN_CONSULTATION_HEAD_AND_NECK_APPLICATION,
  ASSIGN_CONSULTATION_HEAD_AND_NECK_DEEP_MUSCLES,
  ASSIGN_CONSULTATION_HEAD_AND_NECK_DEEP_TISSUES_TECHNIQUES,
  ASSIGN_CONSULTATION_HEAD_AND_NECK_SUPERFICIAL_MUSCLES,
  ASSIGN_CONSULTATION_HEAD_AND_NECK_WARM_UP,
  ASSIGN_CONSULTATION_HERBS_OR_VITAMINS,
  ASSIGN_CONSULTATION_HICAPS_COPY_GIVEN_NOTE,
  ASSIGN_CONSULTATION_HIPS_APPLICATION,
  ASSIGN_CONSULTATION_HIPS_DEEP_MUSCLES,
  ASSIGN_CONSULTATION_HIPS_DEEP_TISSUES_TECHNIQUES,
  ASSIGN_CONSULTATION_HIPS_SUPERFICIAL_MUSCLES,
  ASSIGN_CONSULTATION_HIPS_WARM_UP,
  ASSIGN_CONSULTATION_INSURANCE_COMPANY,
  ASSIGN_CONSULTATION_IS_HICAPS_COPY_GIVEN,
  ASSIGN_CONSULTATION_IS_RECEIPT_GIVEN,
  ASSIGN_CONSULTATION_LUMBAR_REGION_APPLICATION,
  ASSIGN_CONSULTATION_LUMBAR_REGION_DEEP_MUSCLES,
  ASSIGN_CONSULTATION_LUMBAR_REGION_DEEP_TISSUES_TECHNIQUES,
  ASSIGN_CONSULTATION_LUMBAR_REGION_SUPERFICIAL_MUSCLES,
  ASSIGN_CONSULTATION_LUMBAR_REGION_WARM_UP,
  ASSIGN_CONSULTATION_MATERIALS,
  ASSIGN_CONSULTATION_MEDICATION,
  ASSIGN_CONSULTATION_MUSCULOSKELETAL,
  ASSIGN_CONSULTATION_NERVOUS,
  ASSIGN_CONSULTATION_NUMBNESS_OR_TINGLING,
  ASSIGN_CONSULTATION_OTHER_CONDITIONS,
  ASSIGN_CONSULTATION_OTHER_CONDITIONS_NOTE,
  ASSIGN_CONSULTATION_PAIN_OR_TENDERNESS,
  ASSIGN_CONSULTATION_PATIENT, ASSIGN_CONSULTATION_PLAN,
  ASSIGN_CONSULTATION_PRACTITIONER,
  ASSIGN_CONSULTATION_PROVIDER,
  ASSIGN_CONSULTATION_PROVIDER_NUMBER,
  ASSIGN_CONSULTATION_REACTIONS,
  ASSIGN_CONSULTATION_REACTIONS_NOTE,
  ASSIGN_CONSULTATION_RECEIPT_NUMBER, ASSIGN_CONSULTATION_REPEAT_TIMES, ASSIGN_CONSULTATION_REPEAT_WEEK_OR_MONTH,
  ASSIGN_CONSULTATION_RESPIRATORY,
  ASSIGN_CONSULTATION_SERVICES_AND_GOODS,
  ASSIGN_CONSULTATION_SHOULDERS_APPLICATION,
  ASSIGN_CONSULTATION_SHOULDERS_DEEP_MUSCLES,
  ASSIGN_CONSULTATION_SHOULDERS_DEEP_TISSUES_TECHNIQUES,
  ASSIGN_CONSULTATION_SHOULDERS_SUPERFICIAL_MUSCLES,
  ASSIGN_CONSULTATION_SHOULDERS_WARM_UP,
  ASSIGN_CONSULTATION_SIGNED_DATE,
  ASSIGN_CONSULTATION_STIFFNESS,
  ASSIGN_CONSULTATION_SUGGESTIONS,
  ASSIGN_CONSULTATION_SWELLING,
  ASSIGN_CONSULTATION_THIGH_REGION, ASSIGN_CONSULTATION_THIGH_REGION_APPLICATION,
  ASSIGN_CONSULTATION_THIGH_REGION_DEEP_MUSCLES, ASSIGN_CONSULTATION_THIGH_REGION_DEEP_TISSUES_TECHNIQUES,
  ASSIGN_CONSULTATION_THIGH_REGION_WARM_UP,
  ASSIGN_CONSULTATION_TIME_FROM,
  ASSIGN_CONSULTATION_TIME_TO,
  ASSIGN_CONSULTATION_TREATMENTS,
  CREATE_CONSULTATION,
  CREATE_CONSULTATION_FAILED,
  CREATE_CONSULTATION_SUCCESS,
  DELETE_CONSULTATION,
  DELETE_CONSULTATION_FAILED,
  DELETE_CONSULTATION_SUCCESS,
  FETCH_CONSULTATION,
  FETCH_CONSULTATION_FAILED,
  FETCH_CONSULTATION_SUCCESS,
  FETCH_NEW_CONSULTATION,
  INIT_CONSULTATION,
  LINK_CONSULTATION,
  LINK_CONSULTATION_FAILED,
  LINK_CONSULTATION_SUCCESS,
  UPDATE_CONSULTATION,
  UPDATE_CONSULTATION_FAILED,
  UPDATE_CONSULTATION_SUCCESS,
  ASSIGN_CONSULTATION_COMPLAINT_NOTES,
  ASSIGN_CONSULTATION_TREATMENT_PLAN_OTHERS,
  LIST_CONSULTATION_PATIENTS,
  LIST_CONSULTATION_PATIENTS_SUCCESS,
  LIST_CONSULTATION_PATIENTS_FAILED
} from "redux/model/consultations/consultation/constants";

export const initConsultation = () => ({
  type: INIT_CONSULTATION,
  payload: {}
});

export const createConsultation = ({ consultation }) => ({
  type: CREATE_CONSULTATION,
  payload: { consultation }
});

export const createConsultationSuccess = ({ consultation }) => ({
  type: CREATE_CONSULTATION_SUCCESS,
  payload: { consultation }
});

export const createConsultationFailed = ({ error }) => ({
  type: CREATE_CONSULTATION_FAILED,
  payload: { error }
});

export const fetchConsultation = ({ consultationId }) => ({
  type: FETCH_CONSULTATION,
  payload: { consultationId }
});

export const fetchConsultationSuccess = ({ consultation }) => ({
  type: FETCH_CONSULTATION_SUCCESS,
  payload: { consultation }
});

export const fetchConsultationFailed = ({ error }) => ({
  type: FETCH_CONSULTATION_FAILED,
  payload: { error }
});

export const updateConsultation = ({ consultation }) => ({
  type: UPDATE_CONSULTATION,
  payload: { consultation }
});

export const updateConsultationSuccess = ({ consultation }) => ({
  type: UPDATE_CONSULTATION_SUCCESS,
  payload: { consultation }
});

export const updateConsultationFailed = ({ error }) => ({
  type: UPDATE_CONSULTATION_FAILED,
  payload: { error }
});

export const deleteConsultation = ({ consultation }) => ({
  type: DELETE_CONSULTATION,
  payload: { consultation }
});

export const deleteConsultationSuccess = ({ consultation }) => ({
  type: DELETE_CONSULTATION_SUCCESS,
  payload: { consultation }
});

export const deleteConsultationFailed = ({ error }) => ({
  type: DELETE_CONSULTATION_FAILED,
  payload: { error }
});

export const fetchNewConsultation = ({ practitionerReportItemId }) => ({
  type: FETCH_NEW_CONSULTATION,
  payload: { practitionerReportItemId }
});

export const assignConsultationDatetime = ({ datetime }) => ({
  type: ASSIGN_CONSULTATION_DATETIME,
  payload: { datetime }
});

export const assignConsultationPatient = ({ patient }) => ({
  type: ASSIGN_CONSULTATION_PATIENT,
  payload: { patient }
});

export const assignConsultationMusculoskeletal = ({ musculoskeletal }) => ({
  type: ASSIGN_CONSULTATION_MUSCULOSKELETAL,
  payload: { musculoskeletal }
});

export const assignConsultationNervous = ({ nervous }) => ({
  type: ASSIGN_CONSULTATION_NERVOUS,
  payload: { nervous }
});

export const assignConsultationCardiovascular = ({ cardiovascular }) => ({
  type: ASSIGN_CONSULTATION_CARDIOVASCULAR,
  payload: { cardiovascular }
});

export const assignConsultationRespiratory = ({ respiratory }) => ({
  type: ASSIGN_CONSULTATION_RESPIRATORY,
  payload: { respiratory }
});

export const assignConsultationReactions = ({ reactions }) => ({
  type: ASSIGN_CONSULTATION_REACTIONS,
  payload: { reactions }
});

export const assignConsultationReactionsNote = ({ reactionsNote }) => ({
  type: ASSIGN_CONSULTATION_REACTIONS_NOTE,
  payload: { reactionsNote }
});

export const assignConsultationOtherConditions = ({ otherConditions }) => ({
  type: ASSIGN_CONSULTATION_OTHER_CONDITIONS,
  payload: { otherConditions }
});

export const assignConsultationOtherConditionsNote = ({ otherConditionsNote }) => ({
  type: ASSIGN_CONSULTATION_OTHER_CONDITIONS_NOTE,
  payload: { otherConditionsNote }
});

export const assignConsultationMedication = ({ medication }) => ({
  type: ASSIGN_CONSULTATION_MEDICATION,
  payload: { medication }
});

export const assignConsultationPainOrTenderness = ({ painOrTenderness }) => ({
  type: ASSIGN_CONSULTATION_PAIN_OR_TENDERNESS,
  payload: { painOrTenderness }
});

export const assignConsultationStiffness = ({ stiffness }) => ({
  type: ASSIGN_CONSULTATION_STIFFNESS,
  payload: { stiffness }
});

export const assignConsultationNumbnessOrTingling = ({ numbnessOrTingling }) => ({
  type: ASSIGN_CONSULTATION_NUMBNESS_OR_TINGLING,
  payload: { numbnessOrTingling }
});

export const assignConsultationSwelling = ({ swelling }) => ({
  type: ASSIGN_CONSULTATION_SWELLING,
  payload: { swelling }
});

export const assignConsultationBodyFocusImage = ({ bodyFocusImage }) => ({
  type: ASSIGN_CONSULTATION_BODY_FOCUS_IMAGE,
  payload: { bodyFocusImage }
});

export const assignConsultationSignedDate = ({ signedDate }) => ({
  type: ASSIGN_CONSULTATION_SIGNED_DATE,
  payload: { signedDate }
});

export const assignConsultationAreas = ({ areas }) => ({
  type: ASSIGN_CONSULTATION_AREAS,
  payload: { areas }
});

export const assignConsultationTreatments = ({ treatments }) => ({
  type: ASSIGN_CONSULTATION_TREATMENTS,
  payload: { treatments }
});

export const assignConsultationConditions = ({ conditions }) => ({
  type: ASSIGN_CONSULTATION_CONDITIONS,
  payload: { conditions }
});

export const assignConsultationHerbsOrVitamins = ({ herbsOrVitamins }) => ({
  type: ASSIGN_CONSULTATION_HERBS_OR_VITAMINS,
  payload: { herbsOrVitamins }
});

export const assignConsultationMaterials = ({ materials }) => ({
  type: ASSIGN_CONSULTATION_MATERIALS,
  payload: { materials }
});

export const assignConsultationAfterTreatment = ({ afterTreatment }) => ({
  type: ASSIGN_CONSULTATION_AFTER_TREATMENT,
  payload: { afterTreatment }
});

export const assignConsultationSuggestions = ({ suggestions }) => ({
  type: ASSIGN_CONSULTATION_SUGGESTIONS,
  payload: { suggestions }
});

export const assignConsultationProvider = ({ provider }) => ({
  type: ASSIGN_CONSULTATION_PROVIDER,
  payload: { provider }
});

export const assignConsultationInsuranceCompany = ({ insuranceCompany }) => ({
  type: ASSIGN_CONSULTATION_INSURANCE_COMPANY,
  payload: { insuranceCompany }
});

export const assignConsultationComplaintNotes = ({ complaintNotes }) => ({
  type: ASSIGN_CONSULTATION_COMPLAINT_NOTES,
  payload: { complaintNotes }
});

export const assignConsultationTreatmentPlanOthers = ({ treatmentPlanOthers }) => ({
  type: ASSIGN_CONSULTATION_TREATMENT_PLAN_OTHERS,
  payload: { treatmentPlanOthers }
});

export const assignConsultationProviderNumber = ({ providerNumber }) => ({
  type: ASSIGN_CONSULTATION_PROVIDER_NUMBER,
  payload: { providerNumber }
});

export const assignConsultationDuration = ({ duration }) => ({
  type: ASSIGN_CONSULTATION_DURATION,
  payload: { duration }
});

export const assignConsultationPractitioner = ({ practitioner }) => ({
  type: ASSIGN_CONSULTATION_PRACTITIONER,
  payload: { practitioner }
});

export const assignConsultationTimeFrom = ({ timeFrom }) => ({
  type: ASSIGN_CONSULTATION_TIME_FROM,
  payload: { timeFrom }
});

export const assignConsultationTimeTo = ({ timeTo }) => ({
  type: ASSIGN_CONSULTATION_TIME_TO,
  payload: { timeTo }
});

export const assignConsultationServicesAndGoods = ({ servicesAndGoods }) => ({
  type: ASSIGN_CONSULTATION_SERVICES_AND_GOODS,
  payload: { servicesAndGoods }
});

export const assignConsultationIsHicapsCopyGiven = ({ isHicapsCopyGiven }) => ({
  type: ASSIGN_CONSULTATION_IS_HICAPS_COPY_GIVEN,
  payload: { isHicapsCopyGiven }
});

export const assignConsultationHicapsCopyGivenNote = ({ hicapsCopyGivenNote }) => ({
  type: ASSIGN_CONSULTATION_HICAPS_COPY_GIVEN_NOTE,
  payload: { hicapsCopyGivenNote }
});

export const assignConsultationIsReceiptGiven = ({ isReceiptGiven }) => ({
  type: ASSIGN_CONSULTATION_IS_RECEIPT_GIVEN,
  payload: { isReceiptGiven }
});

export const assignConsultationReceiptNumber = ({ receiptNumber }) => ({
  type: ASSIGN_CONSULTATION_RECEIPT_NUMBER,
  payload: { receiptNumber }
});

export const linkConsultation = ({ consultation, practitionerReportItemId }) => ({
  type: LINK_CONSULTATION,
  payload: { consultation, practitionerReportItemId }
});

export const linkConsultationSuccess = ({ practitionerReportItemId, linkedConsultationId, unlinkedConsultationIds }) => ({
  type: LINK_CONSULTATION_SUCCESS,
  payload: { practitionerReportItemId, linkedConsultationId, unlinkedConsultationIds }
});

export const linkConsultationFailed = ({ error }) => ({
  type: LINK_CONSULTATION_FAILED,
  payload: { error }
});

export const listConsultationPatients = ({ query }) => ({
  type: LIST_CONSULTATION_PATIENTS,
  payload: { query }
});

export const listConsultationPatientsSuccess = ({}) => ({
  type: LIST_CONSULTATION_PATIENTS_SUCCESS,
  payload: {}
});

export const listConsultationPatientsFailed = ({ error }) => ({
  type: LIST_CONSULTATION_PATIENTS_FAILED,
  payload: { error }
});

export const assignConsultationComplaintArea = ({ complaintArea }) => ({
  type: ASSIGN_CONSULTATION_COMPLAINT_AREA,
  payload: { complaintArea }
});
export const assignConsultationComplaintTypeOfPain = ({ complaintTypeOfPain }) => ({
  type: ASSIGN_CONSULTATION_COMPLAINT_TYPE_OF_PAIN,
  payload: { complaintTypeOfPain }
});
export const assignConsultationComplaintPainLevel = ({ complaintPainLevel }) => ({
  type: ASSIGN_CONSULTATION_COMPLAINT_PAIN_LEVEL,
  payload: { complaintPainLevel }
});
export const assignConsultationComplaintFrequencyOfPain = ({ complaintFrequencyOfPain }) => ({
  type: ASSIGN_CONSULTATION_COMPLAINT_FREQUENCY_OF_PAIN,
  payload: { complaintFrequencyOfPain }
});
export const assignConsultationComplaintPainDiscomfortIsMadeWorseBy = ({ complaintPainDiscomfortIsMadeWorseBy }) => ({
  type: ASSIGN_CONSULTATION_COMPLAINT_PAIN_DISCOMFORT_IS_MADE_WORSE_BY,
  payload: { complaintPainDiscomfortIsMadeWorseBy }
});
export const assignConsultationComplaintFeelsBetterWith = ({ complaintFeelsBetterWith }) => ({
  type: ASSIGN_CONSULTATION_COMPLAINT_FEELS_BETTER_WITH,
  payload: { complaintFeelsBetterWith }
});

export const assignConsultationAssessmentPainAreaPalpationTension = ({ assessmentPainAreaPalpationTension }) => ({
  type: ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_PALPATION_TENSION,
  payload: { assessmentPainAreaPalpationTension }
});
export const assignConsultationAssessmentPainAreaPalpationTexture = ({ assessmentPainAreaPalpationTexture }) => ({
  type: ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_PALPATION_TEXTURE,
  payload: { assessmentPainAreaPalpationTexture }
});
export const assignConsultationAssessmentPainAreaPalpationTenderness = ({ assessmentPainAreaPalpationTenderness }) => ({
  type: ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_PALPATION_TENDERNESS,
  payload: { assessmentPainAreaPalpationTenderness }
});
export const assignConsultationAssessmentPainAreaPalpationTemperature = ({ assessmentPainAreaPalpationTemperature }) => ({
  type: ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_PALPATION_TEMPERATURE,
  payload: { assessmentPainAreaPalpationTemperature }
});

export const assignConsultationAssessmentPainAreaRomActive = ({ assessmentPainAreaRomActive }) => ({
  type: ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_ROM_ACTIVE,
  payload: { assessmentPainAreaRomActive }
});
export const assignConsultationAssessmentPainAreaRomPassive = ({ assessmentPainAreaRomPassive }) => ({
  type: ASSIGN_CONSULTATION_ASSESSMENT_PAIN_AREA_ROM_PASSIVE,
  payload: { assessmentPainAreaRomPassive }
});

export const assignConsultationAssessmentPostureShoulder = ({ assessmentPostureShoulder }) => ({
  type: ASSIGN_CONSULTATION_ASSESSMENT_POSTURE_SHOULDER,
  payload: { assessmentPostureShoulder }
});
export const assignConsultationAssessmentPostureSpine = ({ assessmentPostureSpine }) => ({
  type: ASSIGN_CONSULTATION_ASSESSMENT_POSTURE_SPINE,
  payload: { assessmentPostureSpine }
});
export const assignConsultationAssessmentPosturePelvis = ({ assessmentPosturePelvis }) => ({
  type: ASSIGN_CONSULTATION_ASSESSMENT_POSTURE_PELVIS,
  payload: { assessmentPosturePelvis }
});

export const assignConsultationDrapingTechnique = ({ drapingTechnique }) => ({
  type: ASSIGN_CONSULTATION_DRAPING_TECHNIQUE,
  payload: { drapingTechnique }
});

export const assignConsultationHeadAndNeckSuperficialMuscles = ({ headAndNeckSuperficialMuscles }) => ({
  type: ASSIGN_CONSULTATION_HEAD_AND_NECK_SUPERFICIAL_MUSCLES,
  payload: { headAndNeckSuperficialMuscles }
});
export const assignConsultationHeadAndNeckDeepMuscles = ({ headAndNeckDeepMuscles }) => ({
  type: ASSIGN_CONSULTATION_HEAD_AND_NECK_DEEP_MUSCLES,
  payload: { headAndNeckDeepMuscles }
});
export const assignConsultationHeadAndNeckWarmUp = ({ headAndNeckWarmUp }) => ({
  type: ASSIGN_CONSULTATION_HEAD_AND_NECK_WARM_UP,
  payload: { headAndNeckWarmUp }
});
export const assignConsultationHeadAndNeckDeepTissuesTechniques = ({ headAndNeckDeepTissuesTechniques }) => ({
  type: ASSIGN_CONSULTATION_HEAD_AND_NECK_DEEP_TISSUES_TECHNIQUES,
  payload: { headAndNeckDeepTissuesTechniques }
});
export const assignConsultationHeadAndNeckApplication = ({ headAndNeckApplication }) => ({
  type: ASSIGN_CONSULTATION_HEAD_AND_NECK_APPLICATION,
  payload: { headAndNeckApplication }
});

export const assignConsultationShouldersSuperficialMuscles = ({ shouldersSuperficialMuscles }) => ({
  type: ASSIGN_CONSULTATION_SHOULDERS_SUPERFICIAL_MUSCLES,
  payload: { shouldersSuperficialMuscles }
});
export const assignConsultationShouldersDeepMuscles = ({ shouldersDeepMuscles }) => ({
  type: ASSIGN_CONSULTATION_SHOULDERS_DEEP_MUSCLES,
  payload: { shouldersDeepMuscles }
});
export const assignConsultationShouldersWarmUp = ({ shouldersWarmUp }) => ({
  type: ASSIGN_CONSULTATION_SHOULDERS_WARM_UP,
  payload: { shouldersWarmUp }
});
export const assignConsultationShouldersDeepTissuesTechniques = ({ shouldersDeepTissuesTechniques }) => ({
  type: ASSIGN_CONSULTATION_SHOULDERS_DEEP_TISSUES_TECHNIQUES,
  payload: { shouldersDeepTissuesTechniques }
});
export const assignConsultationShouldersApplication = ({ shouldersApplication }) => ({
  type: ASSIGN_CONSULTATION_SHOULDERS_APPLICATION,
  payload: { shouldersApplication }
});

export const assignConsultationBackRegionSuperficialMuscles = ({ backRegionSuperficialMuscles }) => ({
  type: ASSIGN_CONSULTATION_BACK_REGION_SUPERFICIAL_MUSCLES,
  payload: { backRegionSuperficialMuscles }
});
export const assignConsultationBackRegionDeepMuscles = ({ backRegionDeepMuscles }) => ({
  type: ASSIGN_CONSULTATION_BACK_REGION_DEEP_MUSCLES,
  payload: { backRegionDeepMuscles }
});
export const assignConsultationBackRegionWarmUp = ({ backRegionWarmUp }) => ({
  type: ASSIGN_CONSULTATION_BACK_REGION_WARM_UP,
  payload: { backRegionWarmUp }
});
export const assignConsultationBackRegionDeepTissuesTechniques = ({ backRegionDeepTissuesTechniques }) => ({
  type: ASSIGN_CONSULTATION_BACK_REGION_DEEP_TISSUES_TECHNIQUES,
  payload: { backRegionDeepTissuesTechniques }
});
export const assignConsultationBackRegionApplication = ({ backRegionApplication }) => ({
  type: ASSIGN_CONSULTATION_BACK_REGION_APPLICATION,
  payload: { backRegionApplication }
});

export const assignConsultationLumbarRegionSuperficialMuscles = ({ lumbarRegionSuperficialMuscles }) => ({
  type: ASSIGN_CONSULTATION_LUMBAR_REGION_SUPERFICIAL_MUSCLES,
  payload: { lumbarRegionSuperficialMuscles }
});
export const assignConsultationLumbarRegionDeepMuscles = ({ lumbarRegionDeepMuscles }) => ({
  type: ASSIGN_CONSULTATION_LUMBAR_REGION_DEEP_MUSCLES,
  payload: { lumbarRegionDeepMuscles }
});
export const assignConsultationLumbarRegionWarmUp = ({ lumbarRegionWarmUp }) => ({
  type: ASSIGN_CONSULTATION_LUMBAR_REGION_WARM_UP,
  payload: { lumbarRegionWarmUp }
});
export const assignConsultationLumbarRegionDeepTissuesTechniques = ({ lumbarRegionDeepTissuesTechniques }) => ({
  type: ASSIGN_CONSULTATION_LUMBAR_REGION_DEEP_TISSUES_TECHNIQUES,
  payload: { lumbarRegionDeepTissuesTechniques }
});
export const assignConsultationLumbarRegionApplication = ({ lumbarRegionApplication }) => ({
  type: ASSIGN_CONSULTATION_LUMBAR_REGION_APPLICATION,
  payload: { lumbarRegionApplication }
});

export const assignConsultationHipsSuperficialMuscles = ({ hipsSuperficialMuscles }) => ({
  type: ASSIGN_CONSULTATION_HIPS_SUPERFICIAL_MUSCLES,
  payload: { hipsSuperficialMuscles }
});
export const assignConsultationHipsDeepMuscles = ({ hipsDeepMuscles }) => ({
  type: ASSIGN_CONSULTATION_HIPS_DEEP_MUSCLES,
  payload: { hipsDeepMuscles }
});
export const assignConsultationHipsWarmUp = ({ hipsWarmUp }) => ({
  type: ASSIGN_CONSULTATION_HIPS_WARM_UP,
  payload: { hipsWarmUp }
});
export const assignConsultationHipsDeepTissuesTechniques = ({ hipsDeepTissuesTechniques }) => ({
  type: ASSIGN_CONSULTATION_HIPS_DEEP_TISSUES_TECHNIQUES,
  payload: { hipsDeepTissuesTechniques }
});
export const assignConsultationHipsApplication = ({ hipsApplication }) => ({
  type: ASSIGN_CONSULTATION_HIPS_APPLICATION,
  payload: { hipsApplication }
});

export const assignConsultationArmRegionSuperficialMuscles = ({ armRegionSuperficialMuscles }) => ({
  type: ASSIGN_CONSULTATION_ARM_REGION_SUPERFICIAL_MUSCLES,
  payload: { armRegionSuperficialMuscles }
});
export const assignConsultationArmRegionDeepMuscles = ({ armRegionDeepMuscles }) => ({
  type: ASSIGN_CONSULTATION_ARM_REGION_DEEP_MUSCLES,
  payload: { armRegionDeepMuscles }
});
export const assignConsultationArmRegionWarmUp = ({ armRegionWarmUp }) => ({
  type: ASSIGN_CONSULTATION_ARM_REGION_WARM_UP,
  payload: { armRegionWarmUp }
});
export const assignConsultationArmRegionDeepTissuesTechniques = ({ armRegionDeepTissuesTechniques }) => ({
  type: ASSIGN_CONSULTATION_ARM_REGION_DEEP_TISSUES_TECHNIQUES,
  payload: { armRegionDeepTissuesTechniques }
});
export const assignConsultationArmRegionApplication = ({ armRegionApplication }) => ({
  type: ASSIGN_CONSULTATION_ARM_REGION_APPLICATION,
  payload: { armRegionApplication }
});

export const assignConsultationThighRegion = ({ thighRegion }) => ({
  type: ASSIGN_CONSULTATION_THIGH_REGION,
  payload: { thighRegion }
});
export const assignConsultationThighRegionDeepMuscles = ({ thighRegionDeepMuscles }) => ({
  type: ASSIGN_CONSULTATION_THIGH_REGION_DEEP_MUSCLES,
  payload: { thighRegionDeepMuscles }
});
export const assignConsultationThighRegionWarmUp = ({ thighRegionWarmUp }) => ({
  type: ASSIGN_CONSULTATION_THIGH_REGION_WARM_UP,
  payload: { thighRegionWarmUp }
});
export const assignConsultationThighRegionDeepTissuesTechniques = ({ thighRegionDeepTissuesTechniques }) => ({
  type: ASSIGN_CONSULTATION_THIGH_REGION_DEEP_TISSUES_TECHNIQUES,
  payload: { thighRegionDeepTissuesTechniques }
});
export const assignConsultationThighRegionApplication = ({ thighRegionApplication }) => ({
  type: ASSIGN_CONSULTATION_THIGH_REGION_APPLICATION,
  payload: { thighRegionApplication }
});

export const assignConsultationPlan = ({ plan }) => ({
  type: ASSIGN_CONSULTATION_PLAN,
  payload: { plan }
});
export const assignConsultationRepeatTimes = ({ repeatTimes }) => ({
  type: ASSIGN_CONSULTATION_REPEAT_TIMES,
  payload: { repeatTimes }
});
export const assignConsultationRepeatWeekOrMonth = ({ repeatWeekOrMonth }) => ({
  type: ASSIGN_CONSULTATION_REPEAT_WEEK_OR_MONTH,
  payload: { repeatWeekOrMonth }
});

export const assignConsultationAdviceToClient = ({ adviceToClient }) => ({
  type: ASSIGN_CONSULTATION_ADVICE_TO_CLIENT,
  payload: { adviceToClient }
});

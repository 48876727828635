import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import {
  generateErrorMessage,
  generateRoute,
  history,
  throwFrontError
} from "@tmatt-tech/allaroundmassage_frontend_common";
import {
  CREATE_ASSISTANT_REPORT,
  FETCH_ASSISTANT_REPORT,
  FETCH_TODAY_ASSISTANT_REPORT, LOCK_ASSISTANT_REPORT,
  UPDATE_ASSISTANT_REPORT
} from "redux/model/assistantReports/assistantReport/constants";
import {
  apiCreateAssistantReport,
  apiFetchAssistantReport,
  apiFetchTodayAssistantReport, apiLockAssistantReport,
  apiUpdateAssistantReport
} from "api/assistantReport/assistantReportApi";
import {
  createAssistantReportFailed,
  fetchAssistantReportFailed,
  fetchAssistantReportSuccess,
  initAssistantReport, lockAssistantReportFailed, lockAssistantReportSuccess,
  updateAssistantReportFailed,
  updateAssistantReportSuccess
} from "redux/model/assistantReports/assistantReport/assistantReportActions";
import {
  toastDefault,
  toastError,
  toastSaved
} from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import { SHOW_ASSISTANT_REPORT_PATH } from "routes/assistantReport/constants";
import { listConsultationsSuccess } from "redux/model/consultations/consultations/consultationsActions";
import { listGiftCardsSuccess } from "redux/model/giftCards/giftCards/giftCardsActions";
import _ from 'lodash';
import { INDEX_GIFT_CARD_PATH } from "routes/giftCard/constants";
import { OVERVIEW_DASHBOARD_PATH } from "routes/dashboard/constants";
import { closeSecondLayerModal } from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/modal/modalActions";

function* assistantReportSaga() {
  yield all([
    fork(watchFetchAssistantReport),
    fork(watchFetchTodayAssistantReport),
    fork(watchUpdateAssistantReport),
    fork(watchCreateAssistantReport),
    fork(watchLockAssistantReport),
  ]);
}

// Fetch AssistantReport
export function* watchFetchAssistantReport() {
  yield takeEvery(FETCH_ASSISTANT_REPORT, sagaFetchAssistantReport);
}

export function* sagaFetchAssistantReport({ payload }) {
  try {
    const response = yield call(apiFetchAssistantReport, payload);

    const { consultations, giftCards } = response.data;

    yield put(fetchAssistantReportSuccess({ current: response.data }));
    yield put(listConsultationsSuccess({ consultations }));
    yield put(listGiftCardsSuccess({ giftCards }));

  } catch (e) {
    yield put(fetchAssistantReportFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Fetch Today AssistantReport
export function* watchFetchTodayAssistantReport() {
  yield takeEvery(FETCH_TODAY_ASSISTANT_REPORT, sagaFetchTodayAssistantReport);
}

export function* sagaFetchTodayAssistantReport({ payload }) {
  try {
    const response = yield call(apiFetchTodayAssistantReport);

    const { giftCards } = response.data;

    yield put(fetchAssistantReportSuccess({ current: response.data }));
    yield put(listGiftCardsSuccess({ giftCards }));

    if (_.isEmpty(response.data.assistantReport)) {
      yield put(initAssistantReport());
    }

  } catch (e) {
    yield put(fetchAssistantReportFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create AssistantReport
export function* watchCreateAssistantReport() {
  yield takeLeading(CREATE_ASSISTANT_REPORT, sagaCreateAssistantReport);
}

export function* sagaCreateAssistantReport({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateAssistantReport, payload);

    yield put(toastSaved({ name: 'Report' }));
    history.push(generateRoute(SHOW_ASSISTANT_REPORT_PATH, response.data.id));
    // history.push(TODAY_ASSISTANT_REPORT_PATH);
    yield put(initAssistantReport());

  } catch (e) {
    yield put(createAssistantReportFailed(generateErrorMessage(e)));

    _.has(generateErrorMessage(e).error, 'assistantReport')
      ? yield put(toastError({ message: 'Today\'s report has been created.' }))
      : yield put(toastError({}));

    throwFrontError(e);
  }
}

// Update AssistantReport
export function* watchUpdateAssistantReport() {
  yield takeLeading(UPDATE_ASSISTANT_REPORT, sagaUpdateAssistantReport);
}

export function* sagaUpdateAssistantReport({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateAssistantReport, payload);

    yield put(updateAssistantReportSuccess({ assistantReport: response.data }));
    yield put(toastSaved({ name: 'Report' }));

  } catch (e) {
    yield put(updateAssistantReportFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// lock AssistantReport
export function* watchLockAssistantReport() {
  yield takeLeading(LOCK_ASSISTANT_REPORT, sagaLockAssistantReport);
}

export function* sagaLockAssistantReport({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    yield call(apiLockAssistantReport, payload);
    yield put(lockAssistantReportSuccess());
    yield put(toastDefault({ message: 'Report has been finalized' }));
    yield put(closeSecondLayerModal());
    history.push(generateRoute(OVERVIEW_DASHBOARD_PATH));

  } catch (e) {
    yield put(lockAssistantReportFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default assistantReportSaga;

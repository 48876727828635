export const INIT_CLINIC = 'INIT_CLINIC';

export const FETCH_CLINIC = 'FETCH_CLINIC';
export const FETCH_CLINIC_SUCCESS = 'FETCH_CLINIC_SUCCESS';
export const FETCH_CLINIC_FAILED = 'FETCH_CLINIC_FAILED';

export const FETCH_CURRENT_CLINIC = 'FETCH_CURRENT_CLINIC';

export const UPDATE_CLINIC = 'UPDATE_CLINIC';
export const UPDATE_CLINIC_SUCCESS = 'UPDATE_CLINIC_SUCCESS';
export const UPDATE_CLINIC_FAILED = 'UPDATE_CLINIC_FAILED';

export const ASSIGN_CLINIC_EMAIL = 'ASSIGN_CLINIC_EMAIL';
export const ASSIGN_CLINIC_USERNAME = 'ASSIGN_CLINIC_USERNAME';
export const ASSIGN_CLINIC_PASSWORD = 'ASSIGN_CLINIC_PASSWORD';
export const ASSIGN_CLINIC_NAME = 'ASSIGN_CLINIC_NAME';
export const ASSIGN_CLINIC_PHONE = 'ASSIGN_CLINIC_PHONE';
export const ASSIGN_CLINIC_MOBILE = 'ASSIGN_CLINIC_MOBILE';
export const ASSIGN_CLINIC_LOCATION = 'ASSIGN_CLINIC_LOCATION';
export const ASSIGN_CLINIC_BUSINESS_NAME = 'ASSIGN_CLINIC_BUSINESS_NAME';
export const ASSIGN_CLINIC_ABN = 'ASSIGN_CLINIC_ABN';
export const ASSIGN_CLINIC_OVERVIEW = 'ASSIGN_CLINIC_OVERVIEW';
export const ASSIGN_CLINIC_LOGO = 'ASSIGN_CLINIC_LOGO';


export const CLINIC_STAFF_CHECK_IN = "CLINIC_STAFF_CHECK_IN";
export const CLINIC_STAFF_CHECK_IN_FAILED = "CLINIC_STAFF_CHECK_IN_FAILED";
export const CLINIC_STAFF_CHECK_IN_SUCCESS = "CLINIC_STAFF_CHECK_IN_SUCCESS";

export const CLINIC_STAFF_CHECK_OUT = "CLINIC_STAFF_CHECK_OUT";
export const CLINIC_STAFF_CHECK_OUT_FAILED = "CLINIC_STAFF_CHECK_OUT_FAILED";
export const CLINIC_STAFF_CHECK_OUT_SUCCESS = "CLINIC_STAFF_CHECK_OUT_SUCCESS";
import React from "react";
import { PrivateRoute } from "@tmatt-tech/allaroundmassage_frontend_common";
import { BASE_NOTICE_PATH, INDEX_NOTICE_PATH } from "routes/notice/constants";

const AllNotice = React.lazy(() => import('pages/notices/index/Notices'));

export const noticeRoutes = {
  path: BASE_NOTICE_PATH,
  name: 'Notices',
  icon: 'dripicons-user-group',
  children: [
    {
      path: INDEX_NOTICE_PATH,
      name: 'All Notices',
      component: AllNotice,
      route: PrivateRoute,
    },
  ],
};

export const hiddenNoticeRoutes = {
  children: [
    {
      path: INDEX_NOTICE_PATH,
      name: 'All Notices',
      component: AllNotice,
      route: PrivateRoute,
    }
  ]
}

import {
  ASSIGN_PRACTITIONER_REPORT_ITEM_APPOINTMENT_TIME,
  ASSIGN_PRACTITIONER_REPORT_ITEM_CASH,
  ASSIGN_PRACTITIONER_REPORT_ITEM_DURATION,
  ASSIGN_PRACTITIONER_REPORT_ITEM_EFTPOS,
  ASSIGN_PRACTITIONER_REPORT_ITEM_HEALTH_FUND,
  ASSIGN_PRACTITIONER_REPORT_ITEM_HICAPS,
  ASSIGN_PRACTITIONER_REPORT_ITEM_INSURANCE_COMPANY,
  ASSIGN_PRACTITIONER_REPORT_ITEM_IS_REGULAR,
  ASSIGN_PRACTITIONER_REPORT_ITEM_IS_REMEDIAL,
  ASSIGN_PRACTITIONER_REPORT_ITEM_ITEM,
  ASSIGN_PRACTITIONER_REPORT_ITEM_MARGIN,
  ASSIGN_PRACTITIONER_REPORT_ITEM_OFF,
  ASSIGN_PRACTITIONER_REPORT_ITEM_OVERTIME,
  ASSIGN_PRACTITIONER_REPORT_ITEM_PHONE,
  ASSIGN_PRACTITIONER_REPORT_ITEM_POINTS_BALANCE,
  ASSIGN_PRACTITIONER_REPORT_ITEM_POINTS_BALANCE_QUERY,
  ASSIGN_PRACTITIONER_REPORT_ITEM_PROVIDER,
  ASSIGN_PRACTITIONER_REPORT_ITEM_PROVIDER_NUMBER,
  ASSIGN_PRACTITIONER_REPORT_ITEM_REDEEM_POINTS,
  ASSIGN_PRACTITIONER_REPORT_ITEM_TOTAL_SALE,
  ASSIGN_PRACTITIONER_REPORT_ITEM_VOUCHER,
  ASSIGN_PRACTITIONER_REPORT_ITEM_VOUCHER_BALANCE,
  ASSIGN_PRACTITIONER_REPORT_ITEM_VOUCHER_BALANCE_QUERY,
  ASSIGN_PRACTITIONER_REPORT_ITEM_VOUCHER_NUMBER,
  NEW_PRACTITIONER_REPORT_ITEM,
  PULL_PRACTITIONER_REPORT_ITEM
} from "redux/model/practitionerReportItems/practitionerReportItems/constants";

export const newPractitionerReportItem = ({ dummyId }) => ({
  type: NEW_PRACTITIONER_REPORT_ITEM,
  payload: { dummyId }
});

export const pullPractitionerReportItem = ({ practitionerReportItem }) => ({
  type: PULL_PRACTITIONER_REPORT_ITEM,
  payload: { practitionerReportItem }
});

export const assignPractitionerReportItemAppointmentTime = ({ practitionerReportItem, appointmentTime }) => ({
  type: ASSIGN_PRACTITIONER_REPORT_ITEM_APPOINTMENT_TIME,
  payload: { practitionerReportItem, appointmentTime }
});

export const assignPractitionerReportItemDuration = ({ practitionerReportItem, duration }) => ({
  type: ASSIGN_PRACTITIONER_REPORT_ITEM_DURATION,
  payload: { practitionerReportItem, duration }
});

export const assignPractitionerReportItemHealthFund = ({ practitionerReportItem, healthFund }) => ({
  type: ASSIGN_PRACTITIONER_REPORT_ITEM_HEALTH_FUND,
  payload: { practitionerReportItem, healthFund }
});

export const assignPractitionerReportItemTotalSale = ({ practitionerReportItem, totalSale }) => ({
  type: ASSIGN_PRACTITIONER_REPORT_ITEM_TOTAL_SALE,
  payload: { practitionerReportItem, totalSale }
});

export const assignPractitionerReportItemHicaps = ({ practitionerReportItem, hicaps }) => ({
  type: ASSIGN_PRACTITIONER_REPORT_ITEM_HICAPS,
  payload: { practitionerReportItem, hicaps }
});

export const assignPractitionerReportItemEftpos = ({ practitionerReportItem, eftpos }) => ({
  type: ASSIGN_PRACTITIONER_REPORT_ITEM_EFTPOS,
  payload: { practitionerReportItem, eftpos }
});

export const assignPractitionerReportItemCash = ({ practitionerReportItem, cash }) => ({
  type: ASSIGN_PRACTITIONER_REPORT_ITEM_CASH,
  payload: { practitionerReportItem, cash }
});

export const assignPractitionerReportItemVoucher = ({ practitionerReportItem, voucher }) => ({
  type: ASSIGN_PRACTITIONER_REPORT_ITEM_VOUCHER,
  payload: { practitionerReportItem, voucher }
});

export const assignPractitionerReportItemIsRegular = ({ practitionerReportItem, isRegular }) => ({
  type: ASSIGN_PRACTITIONER_REPORT_ITEM_IS_REGULAR,
  payload: { practitionerReportItem, isRegular }
});

export const assignPractitionerReportItemIsRemedial = ({ practitionerReportItem, isRemedial }) => ({
  type: ASSIGN_PRACTITIONER_REPORT_ITEM_IS_REMEDIAL,
  payload: { practitionerReportItem, isRemedial }
});

export const assignPractitionerReportItemOvertime = ({ practitionerReportItem, overtime }) => ({
  type: ASSIGN_PRACTITIONER_REPORT_ITEM_OVERTIME,
  payload: { practitionerReportItem, overtime }
});

export const assignPractitionerReportItemMargin = ({ practitionerReportItem, margin }) => ({
  type: ASSIGN_PRACTITIONER_REPORT_ITEM_MARGIN,
  payload: { practitionerReportItem, margin }
});

export const assignPractitionerReportItemOff = ({ practitionerReportItem, off }) => ({
  type: ASSIGN_PRACTITIONER_REPORT_ITEM_OFF,
  payload: { practitionerReportItem, off }
});

export const assignPractitionerReportItemProvider = ({ practitionerReportItem, provider }) => ({
  type: ASSIGN_PRACTITIONER_REPORT_ITEM_PROVIDER,
  payload: { practitionerReportItem, provider }
});

export const assignPractitionerReportItemVoucherNumber = ({ practitionerReportItem, voucherNumber }) => ({
  type: ASSIGN_PRACTITIONER_REPORT_ITEM_VOUCHER_NUMBER,
  payload: { practitionerReportItem, voucherNumber }
});

export const assignPractitionerReportItemPhone = ({ practitionerReportItem, phone }) => ({
  type: ASSIGN_PRACTITIONER_REPORT_ITEM_PHONE,
  payload: { practitionerReportItem, phone }
});

export const assignPractitionerReportItemRedeemPoints = ({ practitionerReportItem, redeemPoints }) => ({
  type: ASSIGN_PRACTITIONER_REPORT_ITEM_REDEEM_POINTS,
  payload: { practitionerReportItem, redeemPoints }
});

export const assignPractitionerReportItemItem = ({ practitionerReportItem, item }) => ({
  type: ASSIGN_PRACTITIONER_REPORT_ITEM_ITEM,
  payload: { practitionerReportItem, item }
});

export const assignPractitionerReportItemProviderNumber = ({ practitionerReportItem, providerNumber }) => ({
  type: ASSIGN_PRACTITIONER_REPORT_ITEM_PROVIDER_NUMBER,
  payload: { practitionerReportItem, providerNumber }
});

export const assignPractitionerReportItemInsuranceCompany = ({ practitionerReportItem, insuranceCompany }) => ({
  type: ASSIGN_PRACTITIONER_REPORT_ITEM_INSURANCE_COMPANY,
  payload: { practitionerReportItem, insuranceCompany }
});

export const assignPractitionerReportItemVoucherBalance = ({ practitionerReportItem, voucherBalance }) => ({
  type: ASSIGN_PRACTITIONER_REPORT_ITEM_VOUCHER_BALANCE,
  payload: { practitionerReportItem, voucherBalance }
});

export const assignPractitionerReportItemVoucherBalanceQuery = ({ practitionerReportItem, voucherBalanceQuery }) => ({
  type: ASSIGN_PRACTITIONER_REPORT_ITEM_VOUCHER_BALANCE_QUERY,
  payload: { practitionerReportItem, voucherBalanceQuery }
});

export const assignPractitionerReportItemPointsBalance = ({ practitionerReportItem, pointsBalance }) => ({
  type: ASSIGN_PRACTITIONER_REPORT_ITEM_POINTS_BALANCE,
  payload: { practitionerReportItem, pointsBalance }
});

export const assignPractitionerReportItemPointsBalanceQuery = ({ practitionerReportItem, pointsBalanceQuery }) => ({
  type: ASSIGN_PRACTITIONER_REPORT_ITEM_POINTS_BALANCE_QUERY,
  payload: { practitionerReportItem, pointsBalanceQuery }
});

import React from "react";
import { PrivateRoute } from "@tmatt-tech/allaroundmassage_frontend_common";
import {
  BASE_PROVIDER_PATH,
  INDEX_PROVIDER_PATH,
} from "routes/provider/constants";

const AllProvider = React.lazy(() => import('pages/providers/index/Providers'));

export const providerRoutes = {
  path: BASE_PROVIDER_PATH,
  name: 'Providers',
  icon: 'dripicons-user-group',
  children: [
    {
      path: INDEX_PROVIDER_PATH,
      name: 'All Providers',
      component: AllProvider,
      route: PrivateRoute,
    },
  ],
};

export const hiddenProviderRoutes = {
  children: []
}

import { all, call, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { LIST_GIFT_CARDS, SYNC_SHOPIFY_GIFT_CARDS } from "redux/model/giftCards/giftCards/constants";
import { apiListGiftCards, apiSyncShopifyGiftCards } from "api/giftCard/giftCardApi";
import { listGiftCardsFailed, listGiftCardsSuccess } from "redux/model/giftCards/giftCards/giftCardsActions";
import { generateErrorMessage, throwFrontError } from "helpers/errorUtils";
import { generateRoute, history } from "@tmatt-tech/allaroundmassage_frontend_common";
import { toastDefault } from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/toast/toastActions";
import { INDEX_GIFT_CARDS_PATH } from "routes/giftCard/constants";


function* giftCardsSaga() {
  yield all([
    fork(watchListGiftCards),
    fork(watchSyncShopifyGiftCards),
  ]);
}

// List GiftCards
export function* watchListGiftCards() {
  yield takeEvery(LIST_GIFT_CARDS, sagaListGiftCards);
}

export function* sagaListGiftCards({ payload }) {
  try {
    const response = yield call(apiListGiftCards, payload);

    const { giftCards, totalSize } = response.data;

    yield put(listGiftCardsSuccess({ giftCards, totalSize }));

  } catch (e) {
    yield put(listGiftCardsFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}

// Sync Shopify GiftCards
export function* watchSyncShopifyGiftCards() {
  yield takeLeading(SYNC_SHOPIFY_GIFT_CARDS, sagaSyncShopifyGiftCards);
}

export function* sagaSyncShopifyGiftCards() {
  try {
    const response = yield call(apiSyncShopifyGiftCards);

    yield put(toastDefault({ message: `Gift card list has been updated` }));
    history.push(generateRoute(INDEX_GIFT_CARDS_PATH));

  } catch (e) {
    throwFrontError(e);
  }
}


export default giftCardsSaga;

import {
  ASSIGN_CLINIC_ABN,
  ASSIGN_CLINIC_BUSINESS_NAME,
  ASSIGN_CLINIC_EMAIL,
  ASSIGN_CLINIC_LOCATION,
  ASSIGN_CLINIC_LOGO,
  ASSIGN_CLINIC_MOBILE,
  ASSIGN_CLINIC_NAME,
  ASSIGN_CLINIC_OVERVIEW,
  ASSIGN_CLINIC_PASSWORD,
  ASSIGN_CLINIC_PHONE,
  ASSIGN_CLINIC_USERNAME,
  FETCH_CLINIC,
  FETCH_CLINIC_FAILED,
  FETCH_CLINIC_SUCCESS,
  FETCH_CURRENT_CLINIC,
  INIT_CLINIC,
  UPDATE_CLINIC,
  UPDATE_CLINIC_FAILED,
  UPDATE_CLINIC_SUCCESS,
  CLINIC_STAFF_CHECK_IN,
  CLINIC_STAFF_CHECK_IN_FAILED,
  CLINIC_STAFF_CHECK_IN_SUCCESS,
  CLINIC_STAFF_CHECK_OUT,
  CLINIC_STAFF_CHECK_OUT_FAILED,
  CLINIC_STAFF_CHECK_OUT_SUCCESS,
} from "redux/model/clinics/clinic/constants";

export const initClinic = () => ({
  type: INIT_CLINIC,
  payload: {}
});

export const fetchClinic = ({ clinicId }) => ({
  type: FETCH_CLINIC,
  payload: { clinicId }
});

export const fetchCurrentClinic = () => ({
  type: FETCH_CURRENT_CLINIC,
  payload: {}
});

export const fetchClinicSuccess = ({ clinic }) => ({
  type: FETCH_CLINIC_SUCCESS,
  payload: { clinic }
});

export const fetchClinicFailed = ({ error }) => ({
  type: FETCH_CLINIC_FAILED,
  payload: { error }
});

export const updateClinic = ({ clinic }) => ({
  type: UPDATE_CLINIC,
  payload: { clinic }
});

export const updateClinicSuccess = ({ clinic }) => ({
  type: UPDATE_CLINIC_SUCCESS,
  payload: { clinic }
});

export const updateClinicFailed = ({ error }) => ({
  type: UPDATE_CLINIC_FAILED,
  payload: { error }
});

export const assignClinicEmail = ({ email }) => ({
  type: ASSIGN_CLINIC_EMAIL,
  payload: { email }
});

export const assignClinicUsername = ({ username }) => ({
  type: ASSIGN_CLINIC_USERNAME,
  payload: { username }
});

export const assignClinicPassword = ({ password }) => ({
  type: ASSIGN_CLINIC_PASSWORD,
  payload: { password }
});

export const assignClinicName = ({ name }) => ({
  type: ASSIGN_CLINIC_NAME,
  payload: { name }
});

export const assignClinicPhone = ({ phone }) => ({
  type: ASSIGN_CLINIC_PHONE,
  payload: { phone }
});

export const assignClinicMobile = ({ mobile }) => ({
  type: ASSIGN_CLINIC_MOBILE,
  payload: { mobile }
});

export const assignClinicLocation = ({ location }) => ({
  type: ASSIGN_CLINIC_LOCATION,
  payload: { location }
});

export const assignClinicBusinessName = ({ businessName }) => ({
  type: ASSIGN_CLINIC_BUSINESS_NAME,
  payload: { businessName }
});

export const assignClinicAbn = ({ abn }) => ({
  type: ASSIGN_CLINIC_ABN,
  payload: { abn }
});

export const assignClinicOverview = ({ overview }) => ({
  type: ASSIGN_CLINIC_OVERVIEW,
  payload: { overview }
});

export const assignClinicLogo = ({ logo }) => ({
  type: ASSIGN_CLINIC_LOGO,
  payload: { logo }
});

// Staff check in / out
export const checkStaffIn = ({ staff, amount, password }) => ({
  type: CLINIC_STAFF_CHECK_IN,
  payload: { staff, amount, password}
});

export const checkStaffInSuccess = ({ clinic }) => ({
  type: CLINIC_STAFF_CHECK_IN_SUCCESS,
  payload: { clinic }
});

export const checkStaffInFailed = ({ error }) => ({
  type: CLINIC_STAFF_CHECK_IN_FAILED,
  payload: { error }
});

export const checkStaffOut = ({ amount }) => ({
  type: CLINIC_STAFF_CHECK_OUT,
  payload: { amount }
});

export const checkStaffOutSuccess = ({ clinic }) => ({
  type: CLINIC_STAFF_CHECK_OUT_SUCCESS,
  payload: { clinic }
});

export const checkStaffOutFailed = ({ error }) => ({
  type: CLINIC_STAFF_CHECK_OUT_FAILED,
  payload: { error }
});

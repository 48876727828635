export const INIT_INVOICE = 'INIT_INVOICE';

export const CREATE_INVOICE = 'CREATE_INVOICE';
export const CREATE_INVOICE_SUCCESS = 'CREATE_INVOICE_SUCCESS';
export const CREATE_INVOICE_FAILED = 'CREATE_INVOICE_FAILED';

export const FETCH_INVOICE = 'FETCH_INVOICE';
export const FETCH_INVOICE_SUCCESS = 'FETCH_INVOICE_SUCCESS';
export const FETCH_INVOICE_FAILED = 'FETCH_INVOICE_FAILED';

export const UPDATE_INVOICE = 'UPDATE_INVOICE';
export const UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS';
export const UPDATE_INVOICE_FAILED = 'UPDATE_INVOICE_FAILED';

export const DELETE_INVOICE = 'DELETE_INVOICE';
export const DELETE_INVOICE_SUCCESS = 'DELETE_INVOICE_SUCCESS';
export const DELETE_INVOICE_FAILED = 'DELETE_INVOICE_FAILED';

export const FETCH_NEW_INVOICE = 'FETCH_NEW_INVOICE';

export const NOTIFY_PATIENT_INVOICE = 'NOTIFY_PATIENT_INVOICE';

export const ASSIGN_INVOICE_INVOICE_NUMBER = 'ASSIGN_INVOICE_INVOICE_NUMBER';
export const ASSIGN_INVOICE_DATE = 'ASSIGN_INVOICE_DATE';
export const ASSIGN_INVOICE_PATIENT = 'ASSIGN_INVOICE_PATIENT';
export const ASSIGN_INVOICE_SUM_OF = 'ASSIGN_INVOICE_SUM_OF';
export const ASSIGN_INVOICE_BEING_FOR = 'ASSIGN_INVOICE_BEING_FOR';
export const ASSIGN_INVOICE_PROVIDER_NO = 'ASSIGN_INVOICE_PROVIDER_NO';
export const ASSIGN_INVOICE_PROVIDER_NAME = 'ASSIGN_INVOICE_PROVIDER_NAME';
export const ASSIGN_INVOICE_PROVIDER_MEMBERSHIP_NUMBER = 'ASSIGN_INVOICE_PROVIDER_MEMBERSHIP_NUMBER';
export const ASSIGN_INVOICE_ITEM_NUMBER = 'ASSIGN_INVOICE_ITEM_NUMBER';
export const ASSIGN_INVOICE_AMOUNT = 'ASSIGN_INVOICE_AMOUNT';
export const ASSIGN_INVOICE_NOTE = 'ASSIGN_INVOICE_NOTE';
export const ASSIGN_INVOICE = 'ASSIGN_INVOICE';


export const LIST_INVOICE_PATIENTS = 'LIST_INVOICE_PATIENTS';
export const LIST_INVOICE_PATIENTS_SUCCESS = 'LIST_INVOICE_PATIENTS_SUCCESS';
export const LIST_INVOICE_PATIENTS_FAILED = 'LIST_INVOICE_PATIENTS_FAILED';
export const INIT_PRACTITIONER_REPORT = 'INIT_PRACTITIONER_REPORT';

export const CREATE_PRACTITIONER_REPORT = 'CREATE_PRACTITIONER_REPORT';
export const CREATE_PRACTITIONER_REPORT_SUCCESS = 'CREATE_PRACTITIONER_REPORT_SUCCESS';
export const CREATE_PRACTITIONER_REPORT_FAILED = 'CREATE_PRACTITIONER_REPORT_FAILED';

export const UPDATE_PRACTITIONER_REPORT = 'UPDATE_PRACTITIONER_REPORT';
export const UPDATE_PRACTITIONER_REPORT_SUCCESS = 'UPDATE_PRACTITIONER_REPORT_SUCCESS';
export const UPDATE_PRACTITIONER_REPORT_FAILED = 'UPDATE_PRACTITIONER_REPORT_FAILED';

export const NEW_PRACTITIONER_REPORT = 'NEW_PRACTITIONER_REPORT';

export const ASSIGN_PRACTITIONER_REPORT_PRACTITIONER = 'ASSIGN_PRACTITIONER_REPORT_PRACTITIONER';
export const ASSIGN_PRACTITIONER_REPORT_COMMISSION = 'ASSIGN_PRACTITIONER_REPORT_COMMISSION';
export const ASSIGN_PRACTITIONER_REPORT_WAGE = 'ASSIGN_PRACTITIONER_REPORT_WAGE';

export const LOAD_PRACTITIONER_REPORT = 'LOAD_PRACTITIONER_REPORT';

import React from "react";
import { PrivateRoute } from "@tmatt-tech/allaroundmassage_frontend_common";
import { EDIT_CLINIC_PATH } from "routes/clinic/constants";

const EditClinic = React.lazy(() => import('pages/clinics/edit/EditClinic'));

// export const giftCardRoutes = {
//   path: EDIT_CLINIC_PATH,
//   name: 'Gift Cards',
//   icon: 'uil uil-gift',
//   children: [
//     {
//       path: INDEX_GIFT_CARD_PATH,
//       name: 'All Gift Cards',
//       component: AllGiftCard,
//       route: PrivateRoute,
//     },
//     {
//       path: NEW_GIFT_CARD_PATH,
//       name: 'Add New',
//       component: NewGiftCard,
//       route: PrivateRoute,
//     },
//   ],
// };

export const hiddenClinicRoutes = {
  children: [
    {
      path: EDIT_CLINIC_PATH,
      name: 'Edit Clinic',
      component: EditClinic,
      route: PrivateRoute,
    }
  ]
}

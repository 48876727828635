import {
  ASSIGN_PRACTITIONER_REPORT_COMMISSION,
  ASSIGN_PRACTITIONER_REPORT_PRACTITIONER,
  ASSIGN_PRACTITIONER_REPORT_WAGE,
  CREATE_PRACTITIONER_REPORT,
  CREATE_PRACTITIONER_REPORT_FAILED,
  CREATE_PRACTITIONER_REPORT_SUCCESS,
  INIT_PRACTITIONER_REPORT, LOAD_PRACTITIONER_REPORT, NEW_PRACTITIONER_REPORT,
  UPDATE_PRACTITIONER_REPORT,
  UPDATE_PRACTITIONER_REPORT_FAILED,
  UPDATE_PRACTITIONER_REPORT_SUCCESS
} from "redux/model/practitionerReports/practitionerReport/constants";

export const initPractitionerReport = () => ({
  type: INIT_PRACTITIONER_REPORT,
  payload: {}
});

export const createPractitionerReport = ({ assistantReport, practitionerReport }) => ({
  type: CREATE_PRACTITIONER_REPORT,
  payload: { assistantReport, practitionerReport }
});

export const createPractitionerReportSuccess = ({ current }) => ({
  type: CREATE_PRACTITIONER_REPORT_SUCCESS,
  payload: { current }
});

export const createPractitionerReportFailed = ({ error }) => ({
  type: CREATE_PRACTITIONER_REPORT_FAILED,
  payload: { error }
});

export const updatePractitionerReport = ({ assistantReport, practitionerReport }) => ({
  type: UPDATE_PRACTITIONER_REPORT,
  payload: { assistantReport, practitionerReport }
});

export const updatePractitionerReportSuccess = ({ current }) => ({
  type: UPDATE_PRACTITIONER_REPORT_SUCCESS,
  payload: { current }
});

export const updatePractitionerReportFailed = ({ error }) => ({
  type: UPDATE_PRACTITIONER_REPORT_FAILED,
  payload: { error }
});

export const newPractitionerReport = ({ dummyId }) => ({
  type: NEW_PRACTITIONER_REPORT,
  payload: { dummyId }
});

export const assignPractitionerReportPractitioner = ({ practitioner }) => ({
  type: ASSIGN_PRACTITIONER_REPORT_PRACTITIONER,
  payload: { practitioner }
});

export const assignPractitionerReportCommission = ({ commission }) => ({
  type: ASSIGN_PRACTITIONER_REPORT_COMMISSION,
  payload: { commission }
});

export const assignPractitionerReportWage = ({ wage }) => ({
  type: ASSIGN_PRACTITIONER_REPORT_WAGE,
  payload: { wage }
});

export const loadPractitionerReport = ({ practitionerReport }) => ({
  type: LOAD_PRACTITIONER_REPORT,
  payload: { practitionerReport }
});

import {
  ASSIGN_PRACTITIONER_REPORT_COMMISSION,
  ASSIGN_PRACTITIONER_REPORT_WAGE,
  ASSIGN_PRACTITIONER_REPORT_PRACTITIONER,
  INIT_PRACTITIONER_REPORT, LOAD_PRACTITIONER_REPORT,
  NEW_PRACTITIONER_REPORT
} from "redux/model/practitionerReports/practitionerReport/constants";
import { NEW_PRACTITIONER_REPORT_ITEM } from "redux/model/practitionerReportItems/practitionerReportItems/constants";

const INIT_STATE = {
  practitionerReport: {},
  loading: true,
  error: null,
};

const practitionerReportReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_PRACTITIONER_REPORT:
      return INIT_STATE;

    case NEW_PRACTITIONER_REPORT:
      return {
        loading: false,
        error: null,
        practitionerReport: {
          id: action.payload.dummyId,
          practitionerReportItemIds: [],
        },
      };

    case ASSIGN_PRACTITIONER_REPORT_PRACTITIONER:
      return {
        ...state,
        practitionerReport: {
          ...state.practitionerReport,
          practitionerId: action.payload.practitioner.id,
        },
      };

    case ASSIGN_PRACTITIONER_REPORT_COMMISSION:
      return {
        ...state,
        practitionerReport: {
          ...state.practitionerReport,
          commission: action.payload.commission,
        },
      };

    case ASSIGN_PRACTITIONER_REPORT_WAGE:
      return {
        ...state,
        practitionerReport: {
          ...state.practitionerReport,
          wage: action.payload.wage,
        },
      };

    case NEW_PRACTITIONER_REPORT_ITEM:
      return {
        ...state,
        practitionerReport: {
          ...state.practitionerReport,
          practitionerReportItemIds: [
            ...state.practitionerReport.practitionerReportItemIds,
            action.payload.dummyId,
          ],
        },
      };

    case LOAD_PRACTITIONER_REPORT:
      return {
        loading: false,
        error: null,
        practitionerReport: action.payload.practitionerReport,
      }

    default:
      return { ...state };
  }
};

export default practitionerReportReducer;

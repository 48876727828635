import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_CONSULTATIONS } from "redux/model/consultations/consultations/constants";
import { apiListConsultations } from "api/consultation/consultationApi";
import {
  listConsultationsFailed,
  listConsultationsSuccess
} from "redux/model/consultations/consultations/consultationsActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/allaroundmassage_frontend_common";


function* consultationsSaga() {
  yield all([
    fork(watchListConsultations),
  ]);
}

// List Consultations
export function* watchListConsultations() {
  yield takeEvery(LIST_CONSULTATIONS, sagaListConsultations);
}

export function* sagaListConsultations({ payload }) {
  try {
    const response = yield call(apiListConsultations, payload);

    const {consultations, totalSize} = response.data;

    yield put(listConsultationsSuccess({ consultations, totalSize }));

  } catch (e) {
    yield put(listConsultationsFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default consultationsSaga;

import {
  LIST_CONSULTATIONS,
  LIST_CONSULTATIONS_FAILED,
  LIST_CONSULTATIONS_SUCCESS
} from "redux/model/consultations/consultations/constants";

export const listConsultations = ({ page, query }) => ({
  type: LIST_CONSULTATIONS,
  payload: { page, query }
});

export const listConsultationsSuccess = ({ consultations, totalSize }) => ({
  type: LIST_CONSULTATIONS_SUCCESS,
  payload: { consultations, totalSize }
});

export const listConsultationsFailed = ({ error }) => ({
  type: LIST_CONSULTATIONS_FAILED,
  payload: { error }
});

import {
  ASSIGN_PROVIDER_NUMBER_CODE,
  ASSIGN_PROVIDER_NUMBER_INSURANCE_COMPANY, LIST_PROVIDER_NUMBERS_SUCCESS,
  NEW_PROVIDER_NUMBER,
  PULL_PROVIDER_NUMBER
} from "redux/model/providerNumbers/providerNumbers/constants";
import _ from 'lodash';
import { INIT_PROVIDER, NEW_PROVIDER } from "redux/model/providers/provider/constants";

const INIT_STATE = {
  providerNumbers: {},
  loading: true,
  error: null,
};

const providerNumbersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case NEW_PROVIDER_NUMBER:
      return {
        ...state,
        loading: false,
        providerNumbers: {
          ...state.providerNumbers,
          [action.payload.dummyId]: {
            id: action.payload.dummyId,
          }
        }
      }

    case PULL_PROVIDER_NUMBER:
      return {
        ...state,
        providerNumbers: _.omit(state.providerNumbers, action.payload.providerNumber.id),
      };

    case ASSIGN_PROVIDER_NUMBER_INSURANCE_COMPANY:
      return {
        ...state,
        providerNumbers: {
          ...state.providerNumbers,
          [action.payload.providerNumber.id]: {
            ...state.providerNumbers[action.payload.providerNumber.id],
            insuranceCompany: action.payload.insuranceCompany,
          },
        },
      };

    case ASSIGN_PROVIDER_NUMBER_CODE:
      return {
        ...state,
        providerNumbers: {
          ...state.providerNumbers,
          [action.payload.providerNumber.id]: {
            ...state.providerNumbers[action.payload.providerNumber.id],
            code: action.payload.code,
          },
        },
      };

    case LIST_PROVIDER_NUMBERS_SUCCESS:
      return {
        ...state,
        providerNumbers: _.mapKeys(action.payload.providerNumbers, 'id'),
        loading: false,
        error: null,
      };

    case INIT_PROVIDER:
      return INIT_STATE;

    case NEW_PROVIDER:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default providerNumbersReducer;

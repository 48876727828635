import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import {
  generateErrorMessage,
  throwFrontError,
  history,
  generateRoute
} from "@tmatt-tech/allaroundmassage_frontend_common";
import { CREATE_PRACTITIONER, DELETE_PRACTITIONER, FETCH_PRACTITIONER, UPDATE_PRACTITIONER } from "redux/model/practitioners/practitioner/constants";
import { apiCreatePractitioner, apiDeletePractitioner, apiFetchPractitioner, apiUpdatePractitioner } from "api/practitioner/practitionerApi";
import {
  createPractitionerFailed,
  createPractitionerSuccess, deletePractitionerFailed, deletePractitionerSuccess,
  fetchPractitionerFailed,
  fetchPractitionerSuccess, initPractitioner,
  updatePractitionerFailed,
  updatePractitionerSuccess
} from "redux/model/practitioners/practitioner/practitionerActions";
import {
  toastDeleted,
  toastError,
  toastSaved
} from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeSecondLayerModal,
  startSecondLayerModalListening
} from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/modal/modalActions";
import { INDEX_PRACTITIONER_PATH } from "routes/practitioner/constants";



function* practitionerSaga() {
  yield all([
    fork(watchFetchPractitioner),
    fork(watchUpdatePractitioner),
    fork(watchCreatePractitioner),
    fork(watchDeletePractitioner),
  ]);
}

// Fetch Practitioner
export function* watchFetchPractitioner() {
  yield takeEvery(FETCH_PRACTITIONER, sagaFetchPractitioner);
}

export function* sagaFetchPractitioner({ payload }) {
  try {
    const response = yield call(apiFetchPractitioner, payload);

    yield put(fetchPractitionerSuccess({ practitioner: response.data }));

  } catch (e) {
    yield put(fetchPractitionerFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create Practitioner
export function* watchCreatePractitioner() {
  yield takeLeading(CREATE_PRACTITIONER, sagaCreatePractitioner);
}

export function* sagaCreatePractitioner({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreatePractitioner, payload);

    yield put(createPractitionerSuccess({ practitioner: response.data }));
    yield put(toastSaved({ name: response.data.mobile }));
    history.push(generateRoute(INDEX_PRACTITIONER_PATH));
    yield put(initPractitioner());


  } catch (e) {
    yield put(createPractitionerFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update Practitioner
export function* watchUpdatePractitioner() {
  yield takeLeading(UPDATE_PRACTITIONER, sagaUpdatePractitioner);
}

export function* sagaUpdatePractitioner({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdatePractitioner, payload);

    yield put(updatePractitionerSuccess({ practitioner: response.data }));
    yield put(toastSaved({ name: response.data.mobile }));
    history.push(generateRoute(INDEX_PRACTITIONER_PATH));
    yield put(initPractitioner());

  } catch (e) {
    yield put(updatePractitionerFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete Practitioner
export function* watchDeletePractitioner() {
  yield takeLeading(DELETE_PRACTITIONER, sagaDeletePractitioner);
}

export function* sagaDeletePractitioner({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeletePractitioner, payload);

    yield put(deletePractitionerSuccess({ practitioner: response.data }));
    yield put(toastDeleted({ name: response.data.mobile }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deletePractitionerFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default practitionerSaga;

import { axios, generateRoute, toApiDateString } from "@tmatt-tech/allaroundmassage_frontend_common";
import {
  CREATE_PROVIDER_API,
  DELETE_PROVIDER_API,
  FETCH_PROVIDER_API,
  LIST_PROVIDER_API,
  UPDATE_PROVIDER_API
} from "api/provider/constants";


export const apiListProviders = () => {
  const { url, method } = LIST_PROVIDER_API;

  return axios({
    method,
    url,
  });
};

export const apiCreateProvider = ({ provider }) => {
  const { url, method } = CREATE_PROVIDER_API;

  return axios({
    method,
    url,
    data: providerParams(provider),
  });
};

export const apiFetchProvider = ({ providerId }) => {
  const { url, method } = FETCH_PROVIDER_API;

  return axios({
    method,
    url: generateRoute(url, providerId),
  });
};

export const apiUpdateProvider = ({ provider }) => {
  const { url, method } = UPDATE_PROVIDER_API;

  return axios({
    method,
    url: generateRoute(url, provider.id),
    data: providerParams(provider),
  });
};

export const apiDeleteProvider = ({ provider }) => {
  const { url, method } = DELETE_PROVIDER_API;

  return axios({
    method,
    url: generateRoute(url, provider.id),
  });
};

const providerParams = (provider) => {
  const {
    name,
    mobile,
    membershipNumber,
    providerParams,
    providerNumbers,
    insuranceExpireDate,
    firstAidExpiryDate,
    cpdLodgedDate,
    address1,
    address2,
    address3
  } = provider;


  return {
    name,
    mobile,
    membershipNumber,
    providerParams,
    providerNumbers,
    insuranceExpireDate: toApiDateString(insuranceExpireDate),
    firstAidExpiryDate: toApiDateString(firstAidExpiryDate),
    cpdLodgedDate: toApiDateString(cpdLodgedDate),
    address1,
    address2,
    address3
  };
};

import { LIST_PRACTITIONERS, LIST_PRACTITIONERS_FAILED, LIST_PRACTITIONERS_SUCCESS } from "redux/model/practitioners/practitioners/constants";

export const listPractitioners = () => ({
  type: LIST_PRACTITIONERS,
  payload: {}
});

export const listPractitionersSuccess = ({ practitioners }) => ({
  type: LIST_PRACTITIONERS_SUCCESS,
  payload: { practitioners }
});

export const listPractitionersFailed = ({ error }) => ({
  type: LIST_PRACTITIONERS_FAILED,
  payload: { error }
});

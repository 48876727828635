import _ from 'lodash';
import {
  CREATE_INVOICE_SUCCESS,
  DELETE_INVOICE_SUCCESS,
  UPDATE_INVOICE_SUCCESS
} from "redux/model/invoices/invoice/constants";
import { LIST_INVOICES, LIST_INVOICES_FAILED, LIST_INVOICES_SUCCESS } from "redux/model/invoices/invoices/constants";

const INIT_STATE = {
  invoices: {},
  loading: true,
  error: null,
};

const invoicesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_INVOICES:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        invoices: _.mapKeys(action.payload.invoices, 'id'),
      };

    case LIST_INVOICES_FAILED:
      return {
        ...state,
        invoices: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_INVOICE_SUCCESS:
      return {
        ...state,
        invoices: {
          [action.payload.invoice.id]: action.payload.invoice,
          ...state.invoices,
        },
      };

    case UPDATE_INVOICE_SUCCESS:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          [action.payload.invoice.id]: action.payload.invoice,
        },
      };

    case DELETE_INVOICE_SUCCESS:
      return {
        ...state,
        invoices: _.omit(state.invoices, action.payload.invoice.id)
      };

    default:
      return { ...state };
  }
};

export default invoicesReducer;

import { LIST_INVOICES, LIST_INVOICES_FAILED, LIST_INVOICES_SUCCESS } from "redux/model/invoices/invoices/constants";

export const listInvoices = () => ({
  type: LIST_INVOICES,
  payload: {}
});

export const listInvoicesSuccess = ({ invoices }) => ({
  type: LIST_INVOICES_SUCCESS,
  payload: { invoices }
});

export const listInvoicesFailed = ({ error }) => ({
  type: LIST_INVOICES_FAILED,
  payload: { error }
});

import { ERROR_FORM_PROVIDER, LOADING_FORM_PROVIDER, RESET_FORM_PROVIDER } from "redux/ui/form/provider/constants";
import {
  CREATE_PROVIDER,
  CREATE_PROVIDER_FAILED,
  CREATE_PROVIDER_SUCCESS,
  UPDATE_PROVIDER, UPDATE_PROVIDER_FAILED, UPDATE_PROVIDER_SUCCESS
} from "redux/model/providers/provider/constants";


const INIT_STATE = {
  loading: false,
  error: null,
};

const formProviderReducer = (state = INIT_STATE, action) => {
  switch (action.type) {

    case LOADING_FORM_PROVIDER:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ERROR_FORM_PROVIDER:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case RESET_FORM_PROVIDER:
      return {
        ...state,
        loading: false,
        error: null
      };

    case CREATE_PROVIDER:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_PROVIDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_PROVIDER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_PROVIDER:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_PROVIDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case UPDATE_PROVIDER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    default:
      return { ...state };
  }
};

export default formProviderReducer;

export const INIT_GIFT_CARD = 'INIT_GIFT_CARD';

export const FETCH_GIFT_CARD = 'FETCH_GIFT_CARD';
export const FETCH_GIFT_CARD_SUCCESS = 'FETCH_GIFT_CARD_SUCCESS';
export const FETCH_GIFT_CARD_FAILED = 'FETCH_GIFT_CARD_FAILED';

export const CREATE_GIFT_CARD = 'CREATE_GIFT_CARD';
export const CREATE_GIFT_CARD_SUCCESS = 'CREATE_GIFT_CARD_SUCCESS';
export const CREATE_GIFT_CARD_FAILED = 'CREATE_GIFT_CARD_FAILED';

export const UPDATE_GIFT_CARD = 'UPDATE_GIFT_CARD';
export const UPDATE_GIFT_CARD_SUCCESS = 'UPDATE_GIFT_CARD_SUCCESS';
export const UPDATE_GIFT_CARD_FAILED = 'UPDATE_GIFT_CARD_FAILED';

export const DELETE_GIFT_CARD = 'DELETE_GIFT_CARD';
export const DELETE_GIFT_CARD_SUCCESS = 'DELETE_GIFT_CARD_SUCCESS';
export const DELETE_GIFT_CARD_FAILED = 'DELETE_GIFT_CARD_FAILED';

export const ASSIGN_GIFT_CARD = 'ASSIGN_GIFT_CARD';

export const QUERY_GIFT_CARD = 'QUERY_GIFT_CARD';

import React from "react";
import { PrivateRoute } from "@tmatt-tech/allaroundmassage_frontend_common";
import { STAFF_CHECK_IN_PATH, STAFF_CHECK_OUT_PATH } from "routes/staff/constants";

const StaffCheckIn = React.lazy(() => import('pages/staffs/checkIn/CheckIn'));
const StaffCheckOut = React.lazy(() => import('pages/staffs/checkOut/CheckOut'));

export const hiddenStaffRoutes = {
  children: [
    {
      path: STAFF_CHECK_IN_PATH,
      name: 'Check-in',
      component: StaffCheckIn,
      route: PrivateRoute,
    },
    {
      path: STAFF_CHECK_OUT_PATH,
      name: 'Check-out',
      component: StaffCheckOut,
      route: PrivateRoute,
    }
  ]
}

import {
  CREATE_REVIEW,
  CREATE_REVIEW_FAILED,
  CREATE_REVIEW_SUCCESS,
  DELETE_REVIEW,
  DELETE_REVIEW_FAILED,
  DELETE_REVIEW_SUCCESS, FETCH_NEW_REVIEW,
  FETCH_REVIEW,
  FETCH_REVIEW_FAILED,
  FETCH_REVIEW_SUCCESS,
  INIT_REVIEW,
  UPDATE_REVIEW,
  UPDATE_REVIEW_FAILED,
  UPDATE_REVIEW_SUCCESS
} from "redux/model/reviews/review/constants";

export const initReview = () => ({
  type: INIT_REVIEW,
  payload: {}
});

export const createReview = ({ review }) => ({
  type: CREATE_REVIEW,
  payload: { review }
});

export const createReviewSuccess = ({ review }) => ({
  type: CREATE_REVIEW_SUCCESS,
  payload: { review }
});

export const createReviewFailed = ({ error }) => ({
  type: CREATE_REVIEW_FAILED,
  payload: { error }
});

export const fetchReview = ({ reviewId }) => ({
  type: FETCH_REVIEW,
  payload: { reviewId }
});

export const fetchNewReview = ({ practitionerReportItemId }) => ({
  type: FETCH_NEW_REVIEW,
  payload: { practitionerReportItemId }
});

export const fetchReviewSuccess = ({ review }) => ({
  type: FETCH_REVIEW_SUCCESS,
  payload: { review }
});

export const fetchReviewFailed = ({ error }) => ({
  type: FETCH_REVIEW_FAILED,
  payload: { error }
});

export const updateReview = ({ review }) => ({
  type: UPDATE_REVIEW,
  payload: { review }
});

export const updateReviewSuccess = ({ review }) => ({
  type: UPDATE_REVIEW_SUCCESS,
  payload: { review }
});

export const updateReviewFailed = ({ error }) => ({
  type: UPDATE_REVIEW_FAILED,
  payload: { error }
});

export const deleteReview = ({ review }) => ({
  type: DELETE_REVIEW,
  payload: { review }
});

export const deleteReviewSuccess = ({ review }) => ({
  type: DELETE_REVIEW_SUCCESS,
  payload: { review }
});

export const deleteReviewFailed = ({ error }) => ({
  type: DELETE_REVIEW_FAILED,
  payload: { error }
});

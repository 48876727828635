import {
  axios,
  extractIdFrom,
  generateRoute, isNew,
  toApiDateString,
  toApiDateTimeString, toMomentFromTimeString
} from "@tmatt-tech/allaroundmassage_frontend_common";
import {
  CREATE_ASSISTANT_REPORT_API,
  LIST_ASSISTANT_REPORT_API,
  FETCH_ASSISTANT_REPORT_API,
  UPDATE_ASSISTANT_REPORT_API, FETCH_TODAY_ASSISTANT_REPORT_API, LOCK_ASSISTANT_REPORT_API
} from "api/assistantReport/constants";
import _ from 'lodash';


export const apiListAssistantReports = () => {
  const { url, method } = LIST_ASSISTANT_REPORT_API;

  return axios({
    method,
    url,
  });
};

export const apiCreateAssistantReport = ({ assistantReport }) => {
  const { url, method } = CREATE_ASSISTANT_REPORT_API;

  return axios({
    method,
    url,
    data: assistantReportParams(assistantReport),
  });
};

export const apiFetchAssistantReport = ({ assistantReportId }) => {
  const { url, method } = FETCH_ASSISTANT_REPORT_API;

  return axios({
    method,
    url: generateRoute(url, assistantReportId),
  });
};

export const apiFetchTodayAssistantReport = () => {
  const { url, method } = FETCH_TODAY_ASSISTANT_REPORT_API;

  return axios({
    method,
    url,
  });
};

export const apiUpdateAssistantReport = ({ assistantReport }) => {
  const { url, method } = UPDATE_ASSISTANT_REPORT_API;

  return axios({
    method,
    url: generateRoute(url, assistantReport.id),
    data: _.omit(assistantReportParams(assistantReport), 'practitionerReports'),
  });
};

export const apiLockAssistantReport = ({ assistantReport, currentStaff }) => {
  const { url, method } = LOCK_ASSISTANT_REPORT_API;

  return axios({
    method,
    url: generateRoute(url, assistantReport.id),
    data: {
      currentStaffId: extractIdFrom(currentStaff.id),
    }
  });
};

const assistantReportParams = (assistantReport) => {
  return {
    ...assistantReport,
    staffId: extractIdFrom(assistantReport.staffId),
    practitionerReports: _.map(assistantReport.practitionerReports, (report) => {
      return {
        ...report,
        practitionerId: extractIdFrom(report.practitionerId),
        commission: report.commission,
        wage: report.wage,
        practitionerReportItems: _.map(report.practitionerReportItems, (reportItem) => {
          return isNew(reportItem)
            ? _.omit({
              ...reportItem,
              appointmentTime: toApiDateTimeString(toMomentFromTimeString(reportItem.appointmentTime)),
            }, 'id')
            : {
              ...reportItem,
              id: extractIdFrom(reportItem.id),
              appointmentTime: toApiDateTimeString(toMomentFromTimeString(reportItem.appointmentTime)),
            };
        }),
      }
    }),
    date: toApiDateString(assistantReport.date),
  };
};

import { axios, generateRoute } from "@tmatt-tech/allaroundmassage_frontend_common";
import { LIST_TRANSACTIONS_API } from "api/transaction/constants";


export const apiListTransactions = ({ transactionableId }) => {
  const { url, method } = LIST_TRANSACTIONS_API;

  return axios({
    method,
    url: generateRoute(url, transactionableId),
  });
};

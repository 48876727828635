import _ from 'lodash';
import {
  CREATE_PROVIDER_SUCCESS,
  DELETE_PROVIDER_SUCCESS,
  UPDATE_PROVIDER_SUCCESS
} from "redux/model/providers/provider/constants";
import { LIST_PROVIDERS, LIST_PROVIDERS_FAILED, LIST_PROVIDERS_SUCCESS } from "redux/model/providers/providers/constants";

const INIT_STATE = {
  providers: {},
  loading: true,
  error: null,
};

const providersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_PROVIDERS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_PROVIDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        providers: _.mapKeys(action.payload.providers, 'id'),
      };

    case LIST_PROVIDERS_FAILED:
      return {
        ...state,
        providers: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_PROVIDER_SUCCESS:
      return {
        ...state,
        providers: {
          [action.payload.provider.id]: action.payload.provider,
          ...state.providers,
        },
      };

    case UPDATE_PROVIDER_SUCCESS:
      return {
        ...state,
        providers: {
          ...state.providers,
          [action.payload.provider.id]: action.payload.provider,
        },
      };

    case DELETE_PROVIDER_SUCCESS:
      return {
        ...state,
        providers: _.omit(state.providers, action.payload.provider.id)
      };

    default:
      return { ...state };
  }
};

export default providersReducer;
